import { FC, useEffect, useRef, useState } from 'react'
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import { SignUpUserInput } from '../../../../types/graphql';
import { ApolloClient, ApolloError, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { Container, TextField } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";
import LoadingSpinner from '../../../LoadingIndicator/LoadingIndicator';
import debounce from 'lodash/debounce';
import './index.scss';
import useQueryParams from '../../../../hooks/useQueryParams';
import buildOneOffApolloClient from '../../../../helpers/buildOneOffClient';

type CustomerSignUpUserFormProps = {
  testId?: string
}

const CustomerSignUpUserForm: FC<CustomerSignUpUserFormProps> = ({ testId }) => {
  const [options, setOptions] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const navigate = useRoleNavigate();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { params, hasParams } = useQueryParams();

  const handleSubmit = async (
    values: SignUpUserInput, 
  ) => {
    setSubmitting(true);
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as SignUpUserInput
    console.log({ nestedValues });

    // Strip all non-numeric characters from phone number
    nestedValues.phone = "+1" + nestedValues.phone.replace(/\D/g, "");

    // Create a one-off client to call the signUpUser mutation
    const oneOffClient = buildOneOffApolloClient();

    // Use the one-off client to call the signUpUser mutation
    oneOffClient.mutate({
      mutation: gql`mutation SignUpUser($data: SignUpUserInput!) {
        signUpUser(data: $data) {
          id
          email
        }
      }`,
      variables: { data: nestedValues },
    }).then((data) => {
      setSubmitting(false);
      console.log("Completed signUpUserMutation call");
      console.log(data);
      alert("User successfully created!");
      navigate("/login");
    }).catch((e: ApolloError) => {
      // setFieldError('password', `${e.message}`);
      setSubmitting(false);
      console.error("Failed signUpUserMutation call");
      console.error(e);
      alert(e);
    });
  }

  const initialValues = {
    email: params.email || options.email || "",
    firstName: params.firstName || options.firstName || "",
    lastName: params.lastName || options.lastName || "",
    phone: params.phone || options.phone || "",
    channelPartner: params.channelPartner || options.channelPartner || "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is required").email(),
    firstName: Yup.string().required("firstName is required"),
    lastName: Yup.string().required("lastName is required"),
    phone: Yup.string().required("phone is required").matches(/^(?:\D*\d){10}\D*$/, "Phone number must contain exactly 10 digits"),
    channelPartner: Yup.string(),
  });

  useEffect(() => {
    if (hasParams) {
      submitButtonRef.current?.click();
    } else {
      setSubmitting(false);
    }
  }, [hasParams]);

  if (submitting) return <div className="signUpLoadingOverlay"><LoadingSpinner light={true} size={10} /></div>;
  return (
    <div data-testid={testId} className='customerSignUpUserFormContainer'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">

                <div className={"inputWrapper emailFieldWrapper textInputWrapper"} key={"email0"} data-testid={testId}>
                  <Field
                    variant="standard"
                    className={"emailInput fieldInput textInput light"}
                    id={"email"}
                    label={"Email"}
                    name={"email"}
                    type={"text"}
                    autoComplete={"email"}
                    as={TextField}
                    error={submitCount > 0 && touched.email && !!errors.email}
                  />
                  <ErrorMessage name={"email"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName1"} data-testid={testId}>
                  <Field
                    variant="standard"
                    className={"firstNameInput fieldInput textInput light"}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName && !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName2"} data-testid={testId}>
                  <Field
                    variant="standard"
                    className={"lastNameInput fieldInput textInput light"}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName && !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper phoneFieldWrapper textInputWrapper"} key={"phone3"} data-testid={testId}>
                  <Field
                    variant="standard"
                    className={"phoneInput fieldInput textInput light"}
                    id={"phone"}
                    label={"Phone"}
                    name={"phone"}
                    type={"text"}
                    autoComplete={"phone"}
                    as={TextField}
                    error={submitCount > 0 && touched.phone && !!errors.phone}
                  />
                  <ErrorMessage name={"phone"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper channelPartnerFieldWrapper textInputWrapper"} key={"channelPartner4"} data-testid={testId}>
                  <Field
                    variant="standard"
                    className={"channelPartnerInput fieldInput textInput light"}
                    hidden={true}
                    required={false}
                    id={"channelPartner"}
                    label={"Channel Partner"}
                    name={"channelPartner"}
                    type={"text"}
                    autoComplete={"channelPartner"}
                    error={submitCount > 0 && touched.channelPartner && !!errors.channelPartner}
                  />
                  <ErrorMessage name={"channelPartner"} component="div" className="inputErrorMessage" />
                </div>
              </Container>
            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
                handleClick={() => {
                  navigate("/login");
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
                reference={submitButtonRef}
              >
                Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CustomerSignUpUserForm