import GridLoader from "react-spinners/GridLoader";

interface LoadingSpinnerProps {
  size?: number
  className?: string
  light?: boolean
}

const LoadingSpinner = ({
  size, className, light = false,
}:LoadingSpinnerProps) => {
  if (!size) size = 15;

  return (
    <GridLoader
      color={light ? "#E4DAC4" : "#000000"}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default LoadingSpinner;
