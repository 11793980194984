import { FC, KeyboardEvent, MouseEvent, useContext } from 'react';
import './LinkButton.scss';
import { Link } from "@mui/material";
import { useTrackClickMutation } from '../../../../types/graphql';
import { EstateContext } from '../../../../providers/EstateProvider';

interface ButtonProps {
  children: JSX.Element | JSX.Element[] | string
  handleClick?: (event:MouseEvent | KeyboardEvent) => void
  handleKeyDown?: (e: any) => void
  variant: 'outline' | 'solid' | 'borderless'
  ariaLabel: string
  className?: string
  backgroundColor?: string
  href?: string
  newTab?: boolean
  testId?: string
  tabIndex?: number
}

const LinkButton: FC<ButtonProps> = ({
  children,
  handleClick = () => { },
  handleKeyDown,
  variant,
  ariaLabel,
  href,
  newTab = false,
  tabIndex = 0,
  className,
  backgroundColor,
  testId,
}) => {
  const [trackClickMutation] = useTrackClickMutation();
  const { selectedEstateId, currentEstate } = useContext(EstateContext);

  const onClickHandler = (event:MouseEvent | KeyboardEvent) => {
    handleClick(event);
    
    ( async () => {
      try {
        const estateId = currentEstate?.getUserEstate?.id || selectedEstateId || '';
  
        if (estateId) {
          await trackClickMutation({
            variables: {
              element: ariaLabel,
              estateId,
            },
          });
  
          if (process.env.NODE_ENV === 'development') console.log(`Tracked click on ${ariaLabel} on ${window.location.pathname}`);
        }
  
      } catch (error) {
        console.log(error);
      }
    })()
  }

  return (
    <Link
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      className={`${variant} ${className}`}
      component="a"
      href={href} 
      target={newTab ? 'blank' : ''}
      onClick={onClickHandler}
      onKeyDown={handleKeyDown || onClickHandler}
      data-testid={testId}
      style={{ backgroundColor }}
    >
      {children}
    </Link>);
}

export default LinkButton;