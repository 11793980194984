import { FC, ReactNode } from 'react';
import { Container, useTheme } from '@mui/material';

// Import LoginPage Components
import welcomeImage from '../../../assets/images/m-welcome-image.png';
import { ReactComponent as AlixLogo } from '../../../assets/icons/logos/alix-logo.svg';
import './authViewLayout.scss';

interface AuthViewLayoutProps {
  children: ReactNode
}

const AuthViewLayout:FC<AuthViewLayoutProps> = ({children}) => {
  const theme = useTheme();
  return (
    <Container className="authLayoutContainer" maxWidth={false} data-testid="login-view">
      <div className="leftPanel">
      </div>

      <div className="rightPanel">

        <img className="welcomeImage" src={welcomeImage} alt="welcome image" />
        { children }
        <div className="brandingContainer" >
          <a aria-label='link to website' href="https://www.meetalix.com/" rel="noopener noreferrer">
            <AlixLogo className='alixLogoSvg'/>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default AuthViewLayout;