import { FC, useContext, useEffect, useRef, useState } from 'react'
import './DropZoneInput.scss'
import {  Typography } from '@mui/material'
import { DocumentContext } from '../../../providers/DocumentProvider';
import useFileUpload from '../../../hooks/useFileUpload';
import FilePickerInput from '../FilePickerInput/FilePickerInput';
import { ReactComponent as UploadOff } from '../../../assets/icons/fileTypes/upload-illustration-off.svg';
import { ReactComponent as UploadOn } from '../../../assets/icons/fileTypes/upload-illustration-on.svg';


interface UploadFileProps {
  testId?: string,
  destinationFolder: string,
  className?: string,
}

const UploadFile: FC<UploadFileProps> = ({
  testId, destinationFolder, className,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const {handleUploadSubmit} = useFileUpload();
  const [ dragOver, setDragOver ] = useState(false);

  // Add files to the files state from Select File button
  const selectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = [...event.target.files];
      handleUploadSubmit({
        files,
        destinationFolder,
      });
    }
  }

  // Add files to the files state from Drag and Drop
  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // Access the files from the event
    const files = [...event.dataTransfer.files];
    handleUploadSubmit({
      files,
      destinationFolder,
    });
    setDragOver(false);
  }
  
  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // This is necessary to allow us to drop.
    event.dataTransfer.dropEffect = 'move';
    setDragOver(true);
  }

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.contains(event.relatedTarget as Node)) {
      // We haven't left the drop zone
      // Keeps child elements from triggering dragLeave
      return;
    }
    // We have left the drop zone
    // Set DragOver to false
    setDragOver(false);
  }


  return (
    <div data-testid={testId} className={`uploadFileContainer ${className}`}>
      <div
        className="uploadFileDropZone"
        onDrop={(event) => dropHandler(event)}
        onDragOver={(event) => dragOverHandler(event)}
        onDragLeave={dragLeaveHandler}
      >
        {!dragOver && <UploadOff className='uploadFileDropZoneIcon'/>}
        {dragOver && <UploadOn className='uploadFileDropZoneIconOn'/>}
        <FilePickerInput 
          destinationFolder={destinationFolder} 
          altText={
            <>
              { dragOver ? 
                <Typography variant='body1'     className='dropZoneFilePickerText'>
                Drop Files Here
                </Typography>
                :
                <Typography variant='body1' className='dropZoneFilePickerText'>
                Drag and Drop Files or <span  className="uploadFileDropZoneSelectFile">Browse</span>
                </Typography>
              }
            </>
          }
        />
      </div>
    </div>
  )
}

export default UploadFile