import { FC, useContext } from "react";
import { EstateComponentGroup } from "./estateViewTypes";
import { pascalToNormalCase } from "../../helpers/functions";
import { Accordion, AccordionDetails, AccordionSummary, Card } from "@mui/material";
import EstateComponentCard from "./EstateComponentCard/EstateComponentCard";
import ComponentSubTypeIcon from "../../components/ComponentSubTypeIcon/ComponentSubTypeIcon";
import { ReactComponent as ChevronDownIcon} from "../../assets/icons/navigation/chevron-down.svg";
import { EstateContext } from "../../providers/EstateProvider";
import { useGetUserEstateIdQuery, useTrackSectionOpenedMutation } from "../../types/graphql";
import Typography from "../../components/DesignSystem/atoms/Typography/Typography";

import './EstateSubtypeCard.scss';

interface EstateSubtypeCardProps {
  subType: string
  componentGroup: EstateComponentGroup
  index: number
  testId?: string
}

const EstateSubtypeCard: FC<EstateSubtypeCardProps> = ({
  subType,
  componentGroup,
  index,
  testId,
}) => {
  const { components } = componentGroup;
  const componentHeader = pascalToNormalCase(subType);
  const { selectedEstateId } = useContext(EstateContext);
  const [trackSectionOpenedMutation] = useTrackSectionOpenedMutation();
  const { data:estateIdData } = useGetUserEstateIdQuery({ variables: { estateId: selectedEstateId } });

  const handleClick = async () => {
    const estateId = estateIdData?.getUserEstate?.id;
    const sectionLabel = `${componentHeader} click to view details`

    try {
      if (estateId) {
        await trackSectionOpenedMutation({
          variables: {
            estateId,
            section: sectionLabel,
          },
        });
  
        if (process.env.NODE_ENV === 'development') console.log(`Tracking "${sectionLabel}" Section Open on ${window.location.pathname}`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Card className='estateComponentDetails' key={`estateComponentDetails-${index}`} data-testid={testId || `estate-subtype-card-${index}`}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          className='estateComponentDetails__Header'
          expandIcon={<ChevronDownIcon className='expandIcon' />}
          onClick={handleClick}
        >
          <ComponentSubTypeIcon subType={subType} />
          <Typography variant='H6' font="UI1" className='estateComponentDetails__Header__Title'>{componentHeader}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {components.length > 0 && components.map((component, index) => (
            <EstateComponentCard
              component={component}
              index={index}
              key={index}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

export default EstateSubtypeCard
