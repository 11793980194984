import { FC, useContext } from 'react'
import './ManageDeceased.scss'
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../providers/EstateProvider';
import CreateOneDeceasedForm from '../../FormikForms/admin/CreateOneDeceasedForm/CreateOneDeceasedForm';
import UpdateOneDeceasedForm from '../../FormikForms/admin/UpdateOneDeceasedForm/UpdateOneDeceasedForm';
import useModal from '../../../hooks/useModal';

interface ManageDeceasedProps {
  testId?: string
}

const ManageDeceased: FC<ManageDeceasedProps> = ({testId}) => {
  const {deceased} = useContext(EstateContext)
  const { setModal } = useModal()
  
  return (
    !deceased ? 
      <ActionButton
        ariaLabel='Open Add Deceased Form'
        handleClick={() => setModal(<CreateOneDeceasedForm />)}
        className='addDeceasedButton'
      >
      Add Deceased to Estate
      </ActionButton>
      :
      <ActionButton
        ariaLabel='Open Update Deceased Form'
        handleClick={() => setModal(<UpdateOneDeceasedForm />)}
        className='addDeceasedButton'
      >
      Update Deceased
      </ActionButton>
  )
}

export default ManageDeceased
