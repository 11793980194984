import { FunctionComponent } from 'react';
import useScreenWidth from './useScreenWidth';

// Define a type for the hook parameters
type UseResponsiveComponentParams = {
  mobile: FunctionComponent;
  desktop: FunctionComponent;
};

// Define the breakpoint for switching between mobile and desktop
const TABLET_BREAKPOINT = 800;

const useResponsiveComponent = ({ mobile, desktop }: UseResponsiveComponentParams) => {
  const screenWidth = useScreenWidth();

  // Return the mobile or desktop component based on the window width
  return screenWidth <= TABLET_BREAKPOINT ? mobile : desktop;
};

export default useResponsiveComponent;