import { FC, useContext, useEffect, useState } from 'react'
import './DocumentsList.scss'
import { EstateDocument, ListDocumentsQuery, useListDocumentsQuery } from '../../../types/graphql'
import LoadingOverlay from '../../LoadingOverlay';
import { DocumentContext, IFile } from '../../../providers/DocumentProvider';
import { EstateContext } from '../../../providers/EstateProvider';
import { AccountContext } from '../../../providers/Authentication';
import CreateFolderForm from '../../../components/FormikForms/admin/CreateFolderForm/CreateFolderForm';
import { LayoutContext } from '../../../providers/LayoutProvider';
import FolderListItem from '../FolderListItem/FolderListItem';
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton';
import DeleteFolderListItem from '../DeleteFolderListItem/DeleteFolderListItem';
import MoveDocumentsForm from '../../../components/FormikForms/generated/MoveDocumentsForm/MoveDocumentsForm';
import List from '../../../components/DesignSystem/atoms/List/List';
import FileListItem from '../../../components/File/FileListItem/FileListItem';
// import documentData from '../documentsTestData.json' //TODO: remove this when we have real data

interface DocumentsListProps {
  testId?: string,
}

interface DocumentsListItemProps {
  data?: ListDocumentsQuery,
  handleClick: (id: string)=> void,
  selectedFile: string | undefined,
}

const DirectoryContents: FC<DocumentsListItemProps> = ( {
  data, handleClick, selectedFile,
} ) => {
  if(!data) return null
  const { listDocuments } = data;
  const { showAdminTools } = useContext(AccountContext);


  return (
    <>
      {Object.values(listDocuments).map((item: EstateDocument, index: number) => (
        <FolderListItem
          key={index}
          item={item}
          expanded={selectedFile === item.id}
          handleClick={handleClick}
          // handleClick={handleClick} 
        />
      ))}
      { showAdminTools &&
        <DeleteFolderListItem 
          id={'trashcanId'}
          name={'Trashcan'}
          handleClick={handleClick}
          expanded={selectedFile === 'trashcanId'}
        />
      }
    </>
  )
}

const DocumentsList: FC<DocumentsListProps> = ({testId}) => {
  const {
    filePath, setFilePath, setRefetchDocumentsList, directoryPath, folderRefetch, filesToMove, setFilesToMove,
  } = useContext(DocumentContext)
  const { selectedEstateId } = useContext(EstateContext)
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const [ directoryData, setDirectoryData ] = useState<ListDocumentsQuery>()
  const { showAdminTools } = useContext(AccountContext)

  const {
    data, error, refetch,
  } = useListDocumentsQuery({
    variables: {
      // "directory": "/",
      "estateId": selectedEstateId,
    },
  } )

  useEffect(() => {
    data && setDirectoryData(data)
    error && console.error("Error loading ListDocuments:", error)
  }, [error, data])

  useEffect(() => {
    setRefetchDocumentsList( () => refetch )
  }, [refetch])

  useEffect(() => {
    if (directoryPath) {
      folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]()
    }
  }, [directoryPath])

  useEffect(() => {
    setFilePath('')
    setFilesToMove(null)
  }, [])

  const handleClick = (file: any) => {
    const {
      id, signedUrl, isDir, 
    } = file;

    if (isDir) {
      const [, , ...rest] = id.split('/'); // remove the first two elements of the array
      const result = rest.join('/'); // join the rest of the array back into a string
      setFilePath(result) // set the new file path with the result witch is the relative path of the file
    }
    else {
      window.open(signedUrl, '_blank') // open the file in a new tab
    }
  }

  const handleAddFolderClick = () => {
    setModalContents(<CreateFolderForm/>)
    setDisplayModal(true)
  }

  const handleMoveFilesClick = () => {
    setDisplayModal(true)
    const documentIds: string[] = (filesToMove?.map(file => file.id) || []).filter((id): id is string => Boolean(id));
    setModalContents(<MoveDocumentsForm documentIds={documentIds || []} estateId={selectedEstateId} refetch={folderRefetch[directoryPath]} />)
  }

  const handleClearMoveFilesClick = () => {
    setFilesToMove(null)
  }

  const handleExpandFolderClick = (id: string) => {
    if(filePath === id){
      setFilePath('')
      return
    }
    setFilePath(id)
  }

  return (
    <div data-testid={testId} className='documentsListContainer'>
      <div className='documentOptionsContainer'>
        {showAdminTools &&
          <>
            {/* <div className='adminButtons'>
              <ActionButton
                ariaLabel='Add Folder'
                handleClick={handleAddFolderClick}
                variant='solid'
                className='addFolderButton dark'
              >
                Add Folder
              </ActionButton>
            </div> */}
            <div className='filesToMoveListContainer'>
              { filesToMove && filesToMove.length > 0 && 
              <>
                <div className='moveButtons'>
                  <ActionButton
                    ariaLabel='Move Files to Folder'
                    handleClick={handleMoveFilesClick}
                    variant='solid'
                    className='addFolderButton dark clearFilesButton'
                  >
                    Move Selected Files
                  </ActionButton>
                  <ActionButton
                    ariaLabel='Clear Move Files Folder List'
                    handleClick={handleClearMoveFilesClick}
                    variant='solid'
                    className='addFolderButton dark'
                  >
                    Clear Selected Files
                  </ActionButton>
                </div>
                
                <div className='filesToMoveList'>
                  <List 
                    data={filesToMove || []}
                    renderItem={(file: IFile, index) => (
                      <FileListItem tabIndex={1} key={`${file.name}${index}`} file={file || undefined} type={'download'} showCheckBox={false}/>
                    )}
                  />
                </div>
              </>
              }
            </div>
            
          </>
        }
      </div>
      { directoryData ? 
        <ul className='folderList'>
          <DirectoryContents data={directoryData} handleClick={handleExpandFolderClick} selectedFile={filePath}/> 
        </ul>
        : <LoadingOverlay/>
      }
    </div>
  );
}

export default DocumentsList