import { FC, useState } from 'react'
import styles from './EstateChart.module.scss'
import Typography from '../../atoms/Typography/Typography'
import EstatePieChart from '../../../Charts/EstatePieChart/EstatePieChart'
import LinkButton from '../../atoms/LinkButton/LinkButton'
import { ReactComponent as RightArrowSvgIcon } from "../../../../assets/icons/navigation/right-arrow.svg";
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";

interface EstateChartProps {
  testId?: string
  className?: string
}

const EstateChart: FC<EstateChartProps> = ({testId, className= ''}) => {
  const navigate = useRoleNavigate();
  
  return (
    <div className={`${styles.chartContainer} ${className}`}>
      <div className={styles.homeViewPieChart}>
        <EstatePieChart 
          sideLength={300} 
          innerRadius={120}
          defaultColorFill="#000" testId="estate-view-piechart"
        />
      </div>
      <div className={styles.homeViewEstateLink}>
        <LinkButton ariaLabel="estate overview link" handleClick={() => navigate("/estate")} variant={"outline"} className={styles.estateLinkButton}>
          <div className={styles.buttonContent}>
            <Typography font="UI1" variant="P3" fontWeight='semibold'>
              DETAILS
            </Typography>
            <RightArrowSvgIcon 
              className={styles.svgRightArrowSvgIcon} 
            />
          </div>
        </LinkButton>
      </div>
    </div>
  )
}

export default EstateChart
