import { FC, useContext, useEffect, useState } from 'react'
import './ContentCard.scss'
import Typography from '../../atoms/Typography/Typography'
import { ContentStatus, Maybe, RelatedElementType, RouteElementInput, RouteElementType, User } from '../../../../types/graphql'
import { ReactComponent as CompleteIcon } from '../../../../assets/icons/statusIcons/complete.svg'
import { ReactComponent as InProcessIcon } from '../../../../assets/icons/statusIcons/in-progress.svg'
import ManagementTools from '../../../ManagementTools/ManagementTools'
import UpdateOneContentForm from '../../../FormikForms/admin/UpdateOneContentForm/UpdateOneContentForm'
import DeleteOneContentForm from '../../../FormikForms/admin/DeleteOneContentForm/DeleteOneContentForm'
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider'
import useScrollToObserver from '../../../../hooks/useScrollToObserver'
import Card from '../../atoms/Card/Card'
import CreateNotificationByGroupForm from '../../../FormikForms/admin/CreateNotificationByGroupForm/CreateNotificationByGroupForm'

interface ComponentDetailCardProps {
  testId?: string
  contentId: string,
  status?: ContentStatus
  user?: Maybe<User> | undefined
  title?: string
  body?: string
  date?: string
  includeDate?: boolean
  parentRef?: any
  renderOpen?: boolean
}

const ComponentDetailCard: FC<ComponentDetailCardProps> = ({
  testId,
  contentId,
  status = ContentStatus.InProcess,
  user,
  title = 'Component Card Title',
  body = 'Component Card Description',
  date = 'TBD',
  includeDate = true,
  parentRef,
  renderOpen = false,
}) => {
  const {
    setSelectedComponentContentCardId, currentEstateComponent, selectedComponentContentCardId,
  } = useContext(EstateComponentContext)
  const [expanded, setExpanded] = useState<boolean>(renderOpen)
  const cardRef = useScrollToObserver(title, status, currentEstateComponent?.id || selectedComponentContentCardId || '', contentId, parentRef);
  const [routeElements, setRouteElements] = useState<RouteElementInput[]>([])

  useEffect(() => {
    if (currentEstateComponent?.id && contentId) {
      setRouteElements([
        {
          id: currentEstateComponent?.id,
          type: RouteElementType.EstateComponent,
          order: 0,
        },
        {
          id: contentId,
          type: RouteElementType.EstateContent,
          order: 1,
        },
      ])
    }
  }, [currentEstateComponent?.id, contentId])

  const handleIcon = () => {
    if (user?.avatar) {
      return <img src={user.avatar} />
    } else {
      return status === ContentStatus.Completed ? <CompleteIcon /> : <InProcessIcon />
    }
  }

  return (
    <div className={`componentDetailCardContainer id-${contentId} ${ !user && status}`}  ref={cardRef}>
      <div className='componentDetailDate'>
        { includeDate && 
        <Typography variant='P1' font='UI1'>
          {date}
        </Typography>}
      </div>
      <Card 
        data-testid={testId}
        className={`componentDetailCard ${status === ContentStatus.Completed && 'completed'}`}
        clickable={true}
        clickableAction={() => setExpanded((expanded: boolean) => !expanded)}
      >
        <ManagementTools 
          editForm={<UpdateOneContentForm />} 
          deleteForm={<DeleteOneContentForm />}
          createNotificationForm={<CreateNotificationByGroupForm routeElements={routeElements} relatedElementType={RelatedElementType.Content} linkedToName={`Component: ${currentEstateComponent?.name} - ${title}`}/>}
          setSelected={() => {
            setSelectedComponentContentCardId(contentId || '')
          }}
        />
        <div className='cardTitle'>
          {handleIcon()}
          <Typography variant='L1' font='UI1' className='wordWrap'>
            {title}
          </Typography>
        </div>
        <div className={`cardBody ${expanded && 'expanded'}`}>
          <Typography variant='P1' font='UI1' useDangerouslySetInnerHTML={body} />
        </div>
        <div className={`cardFooter ${user ? 'space-between' : 'flex-end'}`}>
          { user && <Typography variant='P2' font='UI1' className='userName'>
            {`From ${user?.firstName}`}
          </Typography>}
          <Typography variant='P2' font='UI1'>
            {expanded ? "See Less" : "See More"}
          </Typography>
        </div>
      </Card>
    </div>
  )
}

export default ComponentDetailCard
