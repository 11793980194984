import { FC, useContext } from 'react';
import './ActionButton.scss';
import { useTrackClickMutation } from '../../../../types/graphql';
import { EstateContext } from '../../../../providers/EstateProvider';
import { AccountContext } from '../../../../providers/Authentication';
import { classNames } from '../../../../helpers/functions';
import Tooltip from '@mui/material/Tooltip';

interface ButtonProps {
  ariaLabel: string;
  backgroundColor?: string;
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  disabled?: boolean;
  disabledMessage?: string;
  fullWidth?: boolean;
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  reference?: any;
  role?: string;
  tabIndex?: number;
  testId?: string;
  trackClick?: boolean;
  type?: 'button' | 'submit';
  variant?: 'outline' | 'solid' | 'noBackground';
}

const ActionButton: FC<ButtonProps> = ({
  ariaLabel,
  backgroundColor,
  children,
  className,
  disabled = false,
  disabledMessage = 'This button is disabled',
  handleClick = () => { },
  id,
  reference,
  role = "button",
  tabIndex = 0,
  testId,
  trackClick = true,
  type = 'button',
  variant = 'outline',
}) => {
  const [trackClickMutation] = useTrackClickMutation();
  const { selectedEstateId, currentEstate } = useContext(EstateContext);
  const { hasAdminAuthorization } = useContext(AccountContext);

  const onClickHandler = async () => {
    handleClick();

    if (!trackClick) return;

    try {
      let estateId;

      if (selectedEstateId) estateId = selectedEstateId;
      else {
        estateId = currentEstate?.getUserEstate?.id || selectedEstateId || '';
      }

      if (estateId) {
        await trackClickMutation({
          variables: {
            element: ariaLabel,
            estateId,
          },
        });

        if (process.env.NODE_ENV === 'development') console.log(`Tracked click on ${ariaLabel} on ${window.location.pathname}`);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const classes = disabled ? classNames('disabled', 'solid') : classNames(variant, className);

  const button = (
    <button
      id={id}
      aria-label={ariaLabel}
      type={type}
      className={classes}
      disabled={disabled}
      onClick={disabled ? undefined : onClickHandler}
      data-testid={testId}
      role={role}
      ref={reference}
      tabIndex={tabIndex}
      style={{backgroundColor: backgroundColor}}
    >
      {children}
    </button>
  );

  return disabled ? (
    <Tooltip title={disabledMessage} sx={{fontSize: '30px'}}>
      { button }
    </Tooltip>
  ) : button;
};

export default ActionButton;