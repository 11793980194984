import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import PolicyIcon from '@mui/icons-material/Policy';
import DescriptionIcon from '@mui/icons-material/Description';
import MouseIcon from '@mui/icons-material/Mouse';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ReactComponent as MenuIcon } from '../../assets/icons/navigation/menu.svg';
import { useApolloClient } from '@apollo/client';
import { AccountContext } from '../../providers/Authentication';
import { LayoutContext } from '../../providers/LayoutProvider';
import UpdateAdminUserForm from '../FormikForms/admin/UpdateAdminUserForm/UpdateAdminUserForm';
import { AGREEMENT_TERMS, PRIVACY_POLICY, TERMS_OF_SERVICE, TERMS_OF_USE } from '../../config/policy-links';

export default function MenuDropdown() {
  const {logout, hasAdminAuthorization} = useContext(AccountContext);
  const { setDisplayModal, setModalContents} = useContext(LayoutContext); 
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogoutClick = () => { 
    // Clear the apollo client cache
    client.clearStore();
    // Logout the user from aws
    logout();
    // Close the menu
    handleClose();
  }
  const openFileInTab = (url: string) => {
  // Open terms link in a new tab
    window.open(url, '_blank');
    // Close the menu
    handleClose();
  }
  const openModal = (modalContents: React.ReactNode) => {
    setModalContents(modalContents);
    handleClose();
    setDisplayModal(true);
  }

  return (
    <React.Fragment>
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center', 
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top', 
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom', 
        }}
      >
        { hasAdminAuthorization && <MenuItem onClick={() => openModal(<UpdateAdminUserForm />)}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Update Account Info
        </MenuItem>}
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider /> 
        <MenuItem onClick={() => openFileInTab(PRIVACY_POLICY)}>
          <ListItemIcon>
            <PolicyIcon fontSize="small" />
          </ListItemIcon>
          Privacy Policy
        </MenuItem>
        <MenuItem onClick={() => openFileInTab(AGREEMENT_TERMS)}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          License Agreement
        </MenuItem>
        <MenuItem onClick={() => openFileInTab(TERMS_OF_SERVICE)}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          Terms of Service
        </MenuItem>
        <MenuItem onClick={() => openFileInTab(TERMS_OF_USE)}>
          <ListItemIcon>
            <MouseIcon fontSize="small" />
          </ListItemIcon>
          Terms of Use
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}