import { FC } from 'react'
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import { DesktopLayout } from '../../components/Layouts'
import careteam from '../../assets/images/careteam.png';
import LinkButton from '../../components/DesignSystem/atoms/LinkButton/LinkButton';

import './Desktop.scss'

const Desktop: FC = () => {
  return (
    <DesktopLayout includeSubHeader={false}>
      <div id="careTeamDesktopContent">
        {/* Avatar Alyssa */}
        <img src={careteam} alt="Help Avatar" />

        {/* Flavor Text 1 */}
        <div className="flavorText1">
          <Typography font="UI1" variant="H4" className='howCanWeHelpText'>{"How can we help?"}</Typography>
          <Typography font="UI1" variant="P1" className='noRequestText'>
            {"No question or request is too small. Contact us anytime and we'll get back to you ASAP."}
          </Typography>
          <LinkButton href='mailto:careteam@meetalix.com' ariaLabel={"Send email to care team"} variant={"solid"} className='connectButton emailUsButton'>
        Email us
          </LinkButton>

        </div>

        {/* Flavor Text 2 */}
        <div className="flavorText2">
          <Typography font="UI1" variant="H6">We’re here to help with:</Typography>
          <ul>
            <li>Filing life insurance claims</li>
            <li>Benefits claims</li>
            <li>Closing bank accounts</li>
            <li>Canceling subscriptions & memberships</li>
            <li>Answering questions about the settlement process</li>
            <li>Connecting you with other professional services</li>
          </ul>

        </div>

      </div>
    </DesktopLayout>
  )
}

export default Desktop
