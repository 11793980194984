import { FC, useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './ActionCard.scss';
import { AccountContext } from '../../providers/Authentication';
import { LayoutContext } from '../../providers/LayoutProvider';
import { Action, ActionContext } from '../../providers/ActionProvider';
import { classNames, formatActionName } from "../../helpers/functions";
import StatusTag from '../StatusTag/StatusTag';
import UpdateOneActionCardForm from '../FormikForms/admin/UpdateOneActionCardForm/UpdateOneActionCardForm';
import DeleteOneActionCardForm from '../FormikForms/admin/DeleteOneActionCardForm/DeleteOneActionCardForm';
import { ActionCardStatus, RelatedElementType, RouteElementInput, RouteElementType } from '../../types/graphql';
import { ReactComponent as RightArrowSvgIcon} from '../../assets/icons/navigation/right-arrow.svg';
import ComponentSubTypeIcon from '../ComponentSubTypeIcon/ComponentSubTypeIcon';
import useStatus from '../../hooks/useStatus';
import { EstateContext } from '../../providers/EstateProvider';
import { UserContext } from '../../providers/UserProvider';
import LinkButton from '../DesignSystem/atoms/LinkButton/LinkButton';
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton';
import { componentSubTypeToTintColorMapping } from '../../helpers';
import { ComponentSubType } from '../../types/graphql';
import CreateNotificationByGroupForm from '../FormikForms/admin/CreateNotificationByGroupForm/CreateNotificationByGroupForm';


interface ActionCardProps {
  action: Action;
  buttonText: string;
  buttonVariant: 'outline' | 'solid',
  className?: string
  icon: JSX.Element;
  handleClick: () => void
  testId?: string
}

const ActionCard: FC<ActionCardProps> = ({
  action,
  className,
  handleClick,
  testId,
}) => {
  const {showAdminTools} = useContext(AccountContext);
  const {setDisplayModal, setModalContents} = useContext(LayoutContext)
  const {currentEstate} = useContext(EstateContext)
  const {currentUser} = useContext(UserContext)
  const {setCurrentAction} = useContext(ActionContext)
  const [ name, setName ] = useState('')
  const [ routeElements, setRouteElements ] = useState<RouteElementInput[]>([])

  // These are being used to dynamically set the action details page description variable
  // They appear to be unused, but they are being used in the eval statement below
  const [ deceasedFirstName, setDeceasedFirstName ] = useState('')
  const [ deceasedLastName, setDeceasedLastName ] = useState('')
  const [ customerFirstName, setCustomerFirstName ] = useState('')
  const [ customerLastName, setCustomerLastName ] = useState('')

  useEffect(() => {
    if(currentEstate?.getUserEstate?.deceased){
      setDeceasedFirstName(currentEstate?.getUserEstate?.deceased?.firstName)
      setDeceasedLastName(currentEstate?.getUserEstate?.deceased?.lastName)
    }
    if(currentUser?.getCurrentUser){
      setCustomerFirstName(currentUser.getCurrentUser.firstName || '')
      setCustomerLastName(currentUser.getCurrentUser.lastName || '')
    }

    // Set the action details page description and name
    try {
      setName(eval('`' + action?.name + '`'))
    } catch (error) {
      console.error('Error evaluating description:', error);
      // Handle the error gracefully, e.g., set a default value
      setName(action?.name || '')
    }

    // Set the route elements for the action card
    if (action.status !== ActionCardStatus.Draft) {
      setRouteElements([
        {
          id: action.id,
          type: RouteElementType.ActionCard,
          order: 0,
        },
      ])
    }

  }, [currentEstate, currentUser, action])

  const handleAdminUpdateClick = () => {
    setCurrentAction(action)
    setModalContents(< UpdateOneActionCardForm />)
    setDisplayModal(true)
  }

  const handleAdminDeleteClick = () => {
    setCurrentAction(action)
    setModalContents(< DeleteOneActionCardForm />)
    setDisplayModal(true)
  }

  const handleCreateNotificationClick = () => {
    setCurrentAction(action)
    setModalContents(<CreateNotificationByGroupForm routeElements={routeElements} relatedElementType={RelatedElementType.ActionCard} linkedToName={`Action Card: ${action.name}`}/>)
    setDisplayModal(true)
  }

  const {
    statusClass, statusMsgLong, statusIcon, 
  } = useStatus(action.status, action.completedAt)


  return (
    <Card sx={{minWidth: 275}} className={classNames('actionCardContainer', 'id-' + action.id, className)} data-testid={testId}>
      <CardContent className="actionCardHeader">
        <ComponentSubTypeIcon subType={action.component?.subType || 'Default'}/>
        <Typography variant="body1">
          { formatActionName(action.component)}
        </Typography>
      </CardContent>
      <CardContent className='actionCardBody'>
        <Typography variant="body1" className='karla-500'>
          {name}
        </Typography>
      </CardContent>
      <CardActions className='actionCardButtonContainer'>
        { (action.status === ActionCardStatus.New || showAdminTools) && 
          <LinkButton
            ariaLabel='link to Action Details View'
            handleClick={()=> handleClick()}
            variant={"solid"}
            className={`actionCardButton ${action.component?.subType || "Default"}`}
            backgroundColor={componentSubTypeToTintColorMapping[action.component?.subType as ComponentSubType]}
          >
            <>
              <Typography variant="body1">
                View Details
              </Typography>
              <RightArrowSvgIcon />
            </>
          </LinkButton>}
      </CardActions>
      { statusIcon && action.status !== ActionCardStatus.New && !showAdminTools && 
        <div className={`actionCardProgressContainer ${statusClass}`}>
          {statusIcon}
          <Typography variant='body1' className={`${statusClass}`}>
            {statusMsgLong}
          </Typography>
        </div>}
      { showAdminTools &&
          <div className='actionCardAdminContainer'>
            <Typography variant='body1' className='adminCardStatus'>
              <StatusTag admin={true} status={action.status}/>
            </Typography>
            
            <div className='adminActionButtons'>
              <ActionButton
                ariaLabel={''} className='adminActionButton'
                variant='solid'
                disabled={action.status === ActionCardStatus.Draft}
                disabledMessage={'Disabled when card is in draft mode'}
                handleClick={handleCreateNotificationClick}
              >
                  Create Notification
              </ActionButton>

              <ActionButton 
                ariaLabel={'admin update action card'} className='adminActionButton'
                variant='solid'
                handleClick={handleAdminUpdateClick}
              >
                Update
              </ActionButton>

              <ActionButton 
                ariaLabel={'admin delete action card'} className='adminActionButton'
                variant='outline'
                handleClick={handleAdminDeleteClick}
              >
                Delete
              </ActionButton>
            </div>
          </div>
      }
    </Card>
  );
}

export default ActionCard