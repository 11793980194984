import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useCreateOneDeceasedMutation, DeceasedCreateInput } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DatePicker } from '@mui/x-date-pickers';

  type CreateOneDeceasedFormProps = {
    testId?: string
  }

const CreateOneDeceasedForm: FC<CreateOneDeceasedFormProps> = ({testId}) => {
    
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const {
    selectedEstateId,
    refetchCurrentEstate,
  } = useContext(EstateContext)
  const [ createOneDeceasedMutation ] = useCreateOneDeceasedMutation();
  const [ options, setOptions] = useState<any>({
    "estate": [
      {
        "id": selectedEstateId,
        "displayValue": "Current Selected Estate",
      },
    ],
  })

  const handleSubmit = async (
    values: DeceasedCreateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as DeceasedCreateInput

    createOneDeceasedMutation({
      variables: {data: nestedValues},
      onCompleted: () => {
        setDisplayModal(false);
        refetchCurrentEstate()
        resetForm();

      }, 
      onError: (e: ApolloError) => {
        console.error("Failed createOneDeceasedMutation call");
        console.error(e.message);
        setDisplayModal(false);
        resetForm();
        alert(e.message);
      },
    });
    
    customSubmitAction()
  }

  const initialValues = {
    estate: options.estate[0].id,
    referringPartner: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    dateOfDeath: null,
    podState: "",
    podAddress: "",
    podCity: "",
    podCountry: "",
    podCounty: "",
    address: "",
    city: "",
    state: "",
    county: "",
    country: "",
    maritalStatus: "",
    citizenship: "",
    nameOfHospital: "",
    veteranStatus: false,
  };

  const validationSchema = Yup.object().shape({
    estate: Yup.string().required("estate is required"),
    referringPartner: Yup.string(),
    firstName: Yup.string().required("firstName is required"),
    lastName: Yup.string().required("lastName is required"),
    dateOfBirth: Yup.date().nullable(),
    dateOfDeath: Yup.date().nullable(),
    podState: Yup.string().required("podState is required"),
    podAddress: Yup.string(),
    podCity: Yup.string(),
    podCountry: Yup.string(),
    podCounty: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    county: Yup.string(),
    country: Yup.string(),
    maritalStatus: Yup.string(),
    citizenship: Yup.string(),
    nameOfHospital: Yup.string(),
    veteranStatus: Yup.boolean(), 
  });

  const customSubmitAction = () => {
    // Optional: add additional actions to be executed on submit.
  }

  return (
    <div data-testid={testId} className='createOneDeceasedFormContainer'>
      <Typography variant='h4' className='createOneDeceasedFormHeader'>
        Create One Deceased Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, 
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Typography variant="h5">Relationships</Typography>
              <Container className="formSection">

                <div className={"inputWrapper estateFieldWrapper dropdownInputWrapper"} key={"estate0"} data-testid={testId}>
                  <Autocomplete
                    options={options.estate || []}
                    getOptionLabel={(option: {id: number, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"estateInput fieldInput dropdownInput"}
                        required={true}
                        id={"estate"}
                        label={"Estate"}
                        name={"estate"}
                        type={"text"}
                        autoComplete={"estate"}
                        as={TextField}
                        disabled
                        error={submitCount > 0 && touched.estate&& !!errors.estate}                        
                      />
                    }
                    defaultValue={options.estate[0]}
                    onChange={(_, value) => setFieldValue("estate", value?.id)}
                  />
                  <ErrorMessage name={"estate"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper referringPartnerFieldWrapper textInputWrapper"} key={"referringPartner1"} data-testid={testId}>
                  <Field
                    className={"referringPartnerInput fieldInput textInput"}
                    required={false}
                    id={"referringPartner"}
                    label={"Referring Partner"}
                    name={"referringPartner"}
                    type={"text"}
                    autoComplete={"referringPartner"}
                    as={TextField}
                    error={submitCount > 0 && touched.referringPartner&& !!errors.referringPartner}
                  />
                  <ErrorMessage name={"referringPartner"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Required Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName2"} data-testid={testId}>
                  <Field
                    className={"firstNameInput fieldInput textInput"}
                    required={true}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName&& !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName3"} data-testid={testId}>
                  <Field
                    className={"lastNameInput fieldInput textInput"}
                    required={true}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName&& !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper dateOfBirthFieldWrapper textInputWrapper"} key={"dateOfBirth4"} data-testid={testId}>
                  <Field
                    className={"dateOfBirthInput fieldInput textInput"}
                    required={true}
                    id={"dateOfBirth"}
                    label={"Date Of Birth"}
                    name={"dateOfBirth"}
                    type={"date"}
                    autoComplete={"dateOfBirth"}
                    as={DatePicker}
                    value={initialValues.dateOfBirth}
                    error={submitCount > 0 && touched.dateOfBirth&& !!errors.dateOfBirth}
                    onChange={(date: any) => setFieldValue("dateOfBirth", date)}
                  />
                  <ErrorMessage name={"dateOfBirth"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper dateOfDeathFieldWrapper textInputWrapper"} key={"dateOfDeath5"} data-testid={testId}>
                  <Field
                    className={"dateOfDeathInput fieldInput textInput"}
                    required={true}
                    id={"dateOfDeath"}
                    label={"Date Of Death"}
                    name={"dateOfDeath"}
                    type={"date"}
                    autoComplete={"dateOfDeath"}
                    as={DatePicker}
                    value={initialValues.dateOfDeath}
                    error={submitCount > 0 && touched.dateOfDeath&& !!errors.dateOfDeath}
                    onChange={(date: any) => setFieldValue("dateOfDeath", date)}
                  />
                  <ErrorMessage name={"dateOfDeath"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podStateFieldWrapper textInputWrapper"} key={"podState6"} data-testid={testId}>
                  <Field
                    className={"podStateInput fieldInput textInput"}
                    required={true}
                    id={"podState"}
                    label={"Pod State"}
                    name={"podState"}
                    type={"text"}
                    autoComplete={"podState"}
                    as={TextField}
                    error={submitCount > 0 && touched.podState&& !!errors.podState}
                  />
                  <ErrorMessage name={"podState"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Optional Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper podAddressFieldWrapper textInputWrapper"} key={"podAddress7"} data-testid={testId}>
                  <Field
                    className={"podAddressInput fieldInput textInput"}
                    required={false}
                    id={"podAddress"}
                    label={"Pod Address"}
                    name={"podAddress"}
                    type={"text"}
                    autoComplete={"podAddress"}
                    as={TextField}
                    error={submitCount > 0 && touched.podAddress&& !!errors.podAddress}
                  />
                  <ErrorMessage name={"podAddress"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCityFieldWrapper textInputWrapper"} key={"podCity8"} data-testid={testId}>
                  <Field
                    className={"podCityInput fieldInput textInput"}
                    required={false}
                    id={"podCity"}
                    label={"Pod City"}
                    name={"podCity"}
                    type={"text"}
                    autoComplete={"podCity"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCity&& !!errors.podCity}
                  />
                  <ErrorMessage name={"podCity"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCountryFieldWrapper textInputWrapper"} key={"podCountry9"} data-testid={testId}>
                  <Field
                    className={"podCountryInput fieldInput textInput"}
                    required={false}
                    id={"podCountry"}
                    label={"Pod Country"}
                    name={"podCountry"}
                    type={"text"}
                    autoComplete={"podCountry"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCountry&& !!errors.podCountry}
                  />
                  <ErrorMessage name={"podCountry"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCountyFieldWrapper textInputWrapper"} key={"podCounty10"} data-testid={testId}>
                  <Field
                    className={"podCountyInput fieldInput textInput"}
                    required={false}
                    id={"podCounty"}
                    label={"Pod County"}
                    name={"podCounty"}
                    type={"text"}
                    autoComplete={"podCounty"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCounty&& !!errors.podCounty}
                  />
                  <ErrorMessage name={"podCounty"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper addressFieldWrapper textInputWrapper"} key={"address11"} data-testid={testId}>
                  <Field
                    className={"addressInput fieldInput textInput"}
                    required={false}
                    id={"address"}
                    label={"Address"}
                    name={"address"}
                    type={"text"}
                    autoComplete={"address"}
                    as={TextField}
                    error={submitCount > 0 && touched.address&& !!errors.address}
                  />
                  <ErrorMessage name={"address"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper cityFieldWrapper textInputWrapper"} key={"city12"} data-testid={testId}>
                  <Field
                    className={"cityInput fieldInput textInput"}
                    required={false}
                    id={"city"}
                    label={"City"}
                    name={"city"}
                    type={"text"}
                    autoComplete={"city"}
                    as={TextField}
                    error={submitCount > 0 && touched.city&& !!errors.city}
                  />
                  <ErrorMessage name={"city"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper stateFieldWrapper textInputWrapper"} key={"state13"} data-testid={testId}>
                  <Field
                    className={"stateInput fieldInput textInput"}
                    required={false}
                    id={"state"}
                    label={"State"}
                    name={"state"}
                    type={"text"}
                    autoComplete={"state"}
                    as={TextField}
                    error={submitCount > 0 && touched.state&& !!errors.state}
                  />
                  <ErrorMessage name={"state"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper countyFieldWrapper textInputWrapper"} key={"county14"} data-testid={testId}>
                  <Field
                    className={"countyInput fieldInput textInput"}
                    required={false}
                    id={"county"}
                    label={"County"}
                    name={"county"}
                    type={"text"}
                    autoComplete={"county"}
                    as={TextField}
                    error={submitCount > 0 && touched.county&& !!errors.county}
                  />
                  <ErrorMessage name={"county"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper countryFieldWrapper textInputWrapper"} key={"country15"} data-testid={testId}>
                  <Field
                    className={"countryInput fieldInput textInput"}
                    required={false}
                    id={"country"}
                    label={"Country"}
                    name={"country"}
                    type={"text"}
                    autoComplete={"country"}
                    as={TextField}
                    error={submitCount > 0 && touched.country&& !!errors.country}
                  />
                  <ErrorMessage name={"country"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper maritalStatusFieldWrapper textInputWrapper"} key={"maritalStatus16"} data-testid={testId}>
                  <Field
                    className={"maritalStatusInput fieldInput textInput"}
                    required={false}
                    id={"maritalStatus"}
                    label={"Marital Status"}
                    name={"maritalStatus"}
                    type={"text"}
                    autoComplete={"maritalStatus"}
                    as={TextField}
                    error={submitCount > 0 && touched.maritalStatus&& !!errors.maritalStatus}
                  />
                  <ErrorMessage name={"maritalStatus"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper citizenshipFieldWrapper textInputWrapper"} key={"citizenship17"} data-testid={testId}>
                  <Field
                    className={"citizenshipInput fieldInput textInput"}
                    required={false}
                    id={"citizenship"}
                    label={"Citizenship"}
                    name={"citizenship"}
                    type={"text"}
                    autoComplete={"citizenship"}
                    as={TextField}
                    error={submitCount > 0 && touched.citizenship&& !!errors.citizenship}
                  />
                  <ErrorMessage name={"citizenship"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper nameOfHospitalFieldWrapper textInputWrapper"} key={"nameOfHospital18"} data-testid={testId}>
                  <Field
                    className={"nameOfHospitalInput fieldInput textInput"}
                    required={false}
                    id={"nameOfHospital"}
                    label={"Name Of Hospital"}
                    name={"nameOfHospital"}
                    type={"text"}
                    autoComplete={"nameOfHospital"}
                    as={TextField}
                    error={submitCount > 0 && touched.nameOfHospital&& !!errors.nameOfHospital}
                  />
                  <ErrorMessage name={"nameOfHospital"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper veteranStatusFieldWrapper booleanInputWrapper"} key={"veteranStatus19"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">Veteran Status</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field 
                    className={"veteranStatusInput fieldInput booleanInput"}
                    required={false}
                    id={"veteranStatus"}
                    name={"veteranStatus"}
                    type={"checkbox"} 
                    as={Switch} 
                    error={submitCount > 0 && touched.veteranStatus&& !!errors.veteranStatus}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"veteranStatus"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOneDeceasedForm