import React, { useState, createContext, FC, ReactNode } from 'react';
import { ComponentStatus, ComponentSubType, ComponentType, GetUserEstateQuery } from '../types/graphql';

// export type Estate = { __typename?: 'Estate', id: number, hasProbate?: boolean | null, hasTrust?: boolean | null, hasWill?: boolean | null, deceased?: { __typename?: 'Deceased', firstName: string, lastName: string, podState: string } | null, components: Array<{ __typename?: 'Component', id: number, name: string, status: ComponentStatus, type: ComponentType, subType: ComponentSubType, estimatedValue: any, finalValue?: any | null, createdByUser: { __typename?: 'User', email: string, firstName?: string | null, lastName?: string | null, id: number } }>, componentBreakDown?: Array<{ __typename?: 'ComponentBreakDown', type: string, subType: string, sum: any, percentage: any, count: any, total: any }> | null }

export type SimpleEstate = { __typename?: 'Estate', id: string, deceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string } | null, users: Array<{ __typename?: 'UsersOnEstates', user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null }}>}

export type Deceased = { __typename?: 'Deceased', id: string, firstName: string, lastName: string, state?: string | null | undefined, podState?: string | null | undefined, dateOfBirth?: any | null, dateOfDeath?: any | null }

export type ActionComponent = { __typename?: 'Component', id: string, name: string, subType: ComponentSubType, type: ComponentType, accountNumber?: string | null, descriptor: string }

interface IEstateContext {
  currentEstate: GetUserEstateQuery | null;
  setCurrentEstate: React.Dispatch<React.SetStateAction<GetUserEstateQuery | null>>;
  selectedEstateId: string | undefined;
  setSelectedEstateId: React.Dispatch<React.SetStateAction<string | undefined>>;
  deceased: Deceased | null,
  setDeceased: React.Dispatch<React.SetStateAction<Deceased | null>>
  refetchCurrentEstate: () => Promise<any> | undefined;
  setRefetchCurrentEstate: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  refetchEstatePieChart: () => Promise<any> | undefined;
  setRefetchEstatePieChart: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  refetchEstatesList: () => Promise<any> | undefined;
  setRefetchEstatesList: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  refetchUsersOnEstate: () => Promise<any> | undefined;
  setRefetchUsersOnEstate: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
}

const defaultState: IEstateContext = {
  currentEstate: null,
  setCurrentEstate: () => {},
  selectedEstateId: undefined,
  setSelectedEstateId: () => {},
  deceased: null,
  setDeceased: () => {},
  refetchEstatePieChart: () => undefined,
  setRefetchEstatePieChart: () => () => {},
  refetchCurrentEstate: () => undefined,
  setRefetchCurrentEstate: () => () => {},
  refetchEstatesList: () => undefined,
  setRefetchEstatesList: () => () => {},
  refetchUsersOnEstate: () => undefined,
  setRefetchUsersOnEstate: () => () => {},
};

export const EstateContext = createContext(defaultState);

export const EstateProvider:FC<{ children: ReactNode }> = ({children}) => {
  const [selectedEstateId, setSelectedEstateId] = useState<IEstateContext['selectedEstateId']>(defaultState.selectedEstateId);
  const [currentEstate, setCurrentEstate] = useState<IEstateContext['currentEstate']>(defaultState.currentEstate);
  const [deceased, setDeceased] = useState<IEstateContext['deceased']>(defaultState.deceased);
  const [refetchEstatePieChart, setRefetchEstatePieChart] = useState<IEstateContext['refetchEstatePieChart']>(defaultState.refetchEstatePieChart);
  const [refetchEstatesList, setRefetchEstatesList ] = useState<IEstateContext['refetchEstatesList']>(defaultState.refetchEstatesList);
  const [refetchCurrentEstate, setRefetchCurrentEstate] = useState<IEstateContext['refetchCurrentEstate']>(defaultState.refetchCurrentEstate);
  const [refetchUsersOnEstate, setRefetchUsersOnEstate] = useState<IEstateContext['refetchUsersOnEstate']>(defaultState.refetchUsersOnEstate);
  return (
    <EstateContext.Provider
      value={{
        currentEstate,
        setCurrentEstate,
        selectedEstateId,
        setSelectedEstateId,
        deceased,
        setDeceased,
        refetchEstatePieChart,
        setRefetchEstatePieChart,
        refetchCurrentEstate,
        setRefetchCurrentEstate,
        refetchEstatesList,
        setRefetchEstatesList,
        refetchUsersOnEstate,
        setRefetchUsersOnEstate,
      }}
    >
      {children}
    </EstateContext.Provider>
  );
};
