const colors = {
  baseColors: {
    base1: '#F6F3EB', // pearl
    base2: '#E4DAC4', // sand
    base3: '#AB826F', // chocolate
    base4: '#312B2B', // charcoal
  },
  baseTints: {
    base140: '#FBFAF7', // pearl40
    base160: '#FAF8F3', // pearl60
    base180: '#F8F5EF', // pearl80
    base240: '#F4F0E7', // sand40
    base260: '#EFE9DC', // sand60
    base280: '#E9E1D0', // sand80
    base340: '#DDCDC5', // chocolate40
    base360: '#CDB4A9', // chocolate60
    base380: '#BC9B8C', // chocolate80
    base440: '#ADAAAA', // charcoal40
    base460: '#838080', // charcoal60
    base480: '#5A5555', // charcoal80
  },
  accentColors: {
    accent1: '#A39B0C', // olive
    accent2: '#FFB5A8', // salmon
    accent3: '#FF7759', // coral
    accent4: '#E2B444', // mustard
    accent5: '#D08FE3', // lavender
    accent6: '#7495FF', // cornflower
    accent7: '#326E5E', // pine
    accent8: '#C3EBB5', // mint
  },
  accentTints: {
    accent140: '#DAD79E', // olive40
    accent160: '#C8C36D', // olive60
    accent180: '#B5AF3D', // olive80
    accent240: '#FFE1DC', // salmon40
    accent260: '#FFD3CB', // salmon60
    accent280: '#FFC4B9', // salmon80
    accent340: '#FFC9BD', // coral40
    accent360: '#FFAD9B', // coral60
    accent380: '#FF927A', // coral80
    accent440: '#F3E1B4', // mustard40
    accent460: '#EED28F', // mustard60
    accent480: '#E8C369', // mustard80
    accent540: '#ECD2F4', // lavender40
    accent560: '#E3BCEE', // lavender60
    accent580: '#D9A5E9', // lavender80
    accent640: '#C7D4FF', // cornflower40
    accent660: '#ABBFFF', // cornflower60
    accent680: '#90AAFF', // cornflower80
    accent740: '#ADC5BF', // pine40
    accent760: '#84A89E', // pine60
    accent780: '#5B8B7E', // pine80
    accent840: '#E7F7E1', // mint40
    accent860: '#DBF3D3', // mint60
    accent880: '#CFEFC4', // mint80
  },
  successColors: {
    success1: '#237F00', // completed_success_light
    success2: '#53A829', // completed_success_dark
  },
  alertColors: {
    alert1: '#C10700', // error_attention_light
    alert2: '#FF5C2F', // error_attention_dark
  },
};

export default colors;