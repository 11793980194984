import { FC, useContext } from "react";
import { Typography } from "@mui/material";

import './index.scss';
import LinkButton from "../../../components/DesignSystem/atoms/LinkButton/LinkButton";
import { ActionContext } from "../../../providers/ActionProvider";
import { EstateContext } from "../../../providers/EstateProvider";

const DocumentSign: FC<{testId?: string, currentAction: any}> = ({testId, currentAction}) => {

  return (
    <div className="actionDetailsButtonContainer">
      <LinkButton 
        variant="solid"
        ariaLabel="sign document link"
        className="actionDetailsButton dark"
        handleClick={() => console.log('link pressed')}
        href={currentAction?.documentSignUrl || ''}
      >
        <div className="signDocumentButtonText">
          <Typography variant="body1" className="light">
            Sign Now
          </Typography>
        </div>
      </LinkButton>
    </div>
  );
};

export default DocumentSign;
