import { FC, useContext, useState } from 'react'
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CreateUserInput, useCreateUserCompletelyMutation, UserEstateRole, UserType } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography } from '@mui/material';
import { USStatesAndTerritoriesAbbreviations, handleNestedValues, stripPhoneInput } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';

import './createOneUserForm.scss';
import { EstateContext } from '../../../../providers/EstateProvider';
import SelectUser from '../../../SelectUser/SelectUser';

type CreateOneUserFormProps = {
  testId?: string,
}

const CreateOneUserForm: FC<CreateOneUserFormProps> = ({ testId }) => {

  const { setModalContents } = useContext(LayoutContext);
  const {
    selectedEstateId, refetchEstatesList, refetchUsersOnEstate, 
  } = useContext(EstateContext);
  const [createUserCompletelyMutation] = useCreateUserCompletelyMutation();
  const [options, setOptions] = useState<any>({
    "type": [
      {
        "submitValue": "CUSTOMER",
        "displayValue": "Customer",
      },
      {
        "submitValue": "CARETEAM",
        "displayValue": "Careteam",
      },
      {
        "submitValue": "ADMIN",
        "displayValue": "Admin",
      },
      {
        "submitValue": "OPS",
        "displayValue": "Ops",
      },
    ],
    userRole: Object.values(UserEstateRole).map((value:string) => ({ 
      submitValue: value, 
      displayValue: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(), 
    })),
    //   {
    //     "submitValue": UserEstateRole.Beneficiery,
    //     "displayValue": "Beneficiery",
    //   },
    //   {
    //     "submitValue": UserEstateRole.Executor,
    //     "displayValue": "Executor",
    //   },
    // ],
  });

  const handleSubmit = async (
    values: CreateUserInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as CreateUserInput;

    const submitValues = {
      email: nestedValues.email,
      firstName: nestedValues.firstName,
      lastName: nestedValues.lastName,
      phone: stripPhoneInput(nestedValues.phone as string),
      type: nestedValues.type,
      estates: {
        create: [
          {
            userRole: nestedValues.userRole,
            estate: { connect: { id: selectedEstateId } },
          },
        ],
      },
    };

    createUserCompletelyMutation({
      variables: { data: submitValues },
      onCompleted: (_data) => {
        refetchUsersOnEstate();
        refetchEstatesList();
        setModalContents(<SelectUser />);
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed createUserCompletelyMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const handleCancelClick = () => {
    setModalContents(<SelectUser />);
  };

  const initialValues = {
    type: UserType.Customer,
    userRole: UserEstateRole.Executor,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("type is required"),
    userRole: Yup.string()
      .required("User role is required")
      .oneOf(
        Object.values(UserEstateRole as any) as string[],
        "The selected user role must be one of the following: " + (Object.values(UserEstateRole) as string[]).map((str: string) => str.toLocaleLowerCase()).join(", "),
      ),
    email: Yup.string().required("email is required"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().required("phone is required").matches(/^(?:\D*\d){10}\D*$/, "Phone number must contain exactly 10 digits"),
  });

  return (
    <div data-testid={testId} className='createOneUserFormContainer'>
      <Typography variant='h4' className='createOneUserFormHeader'>
        Create One User Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue,
        }) => (
          <Form noValidate>
            <Container className="formContainer">

              <Typography variant="h5">Required Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper typeFieldWrapper dropdownInputWrapper"} key={"type0"} data-testid={testId}>
                  <Autocomplete
                    options={options.type || []}
                    getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"typeInput fieldInput dropdownInput"}
                        required={true}
                        id={"type"}
                        label={"Type"}
                        name={"type"}
                        type={"text"}
                        autoComplete={"type"}
                        as={TextField}
                        error={submitCount > 0 && touched.type && !!errors.type}
                      />
                    }
                    defaultValue={options.type.find((option: any) => option.submitValue === initialValues.type)}
                    onChange={(_, value) => setFieldValue("type", value?.submitValue)}
                  />
                  <ErrorMessage name={"type"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper userRoleFieldWrapper dropdownInputWrapper"} key={"userRole1"} data-testid={testId}>
                  <Autocomplete
                    options={options.userRole || []}
                    getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"userRoleInput fieldInput dropdownInput"}
                        required={false}
                        id={"userRole"}
                        label={"User Role"}
                        name={"userRole"}
                        type={"text"}
                        autoComplete={"userRole"}
                        as={TextField}
                        error={submitCount > 0 && touched.userRole && !!errors.userRole}
                      />
                    }
                    defaultValue={options.userRole.find((option: any) => option.submitValue === initialValues.userRole)}
                    onChange={(_, value) => setFieldValue("userRole", value?.submitValue)}
                  />
                  <ErrorMessage name={"userRole"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper emailFieldWrapper textInputWrapper"} key={"email2"} data-testid={testId}>
                  <Field
                    className={"emailInput fieldInput textInput"}
                    required={true}
                    id={"email"}
                    label={"Email"}
                    name={"email"}
                    type={"text"}
                    autoComplete={"email"}
                    as={TextField}
                    error={submitCount > 0 && touched.email && !!errors.email}
                  />
                  <ErrorMessage name={"email"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName3"} data-testid={testId}>
                  <Field
                    className={"firstNameInput fieldInput textInput"}
                    required={false}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName && !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName4"} data-testid={testId}>
                  <Field
                    className={"lastNameInput fieldInput textInput"}
                    required={false}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName && !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage" />
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Optional Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper phoneFieldWrapper textInputWrapper"} key={"phone5"} data-testid={testId}>
                  <Field
                    className={"phoneInput fieldInput textInput"}
                    required={false}
                    id={"phone"}
                    label={"Phone"}
                    name={"phone"}
                    type={"text"}
                    autoComplete={"phone"}
                    as={TextField}
                    error={submitCount > 0 && touched.phone && !!errors.phone}
                  />
                  <ErrorMessage name={"phone"} component="div" className="inputErrorMessage" />
                </div>
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                Submit
              </ActionButton>
              <ActionButton ariaLabel={'cancel'} handleClick={handleCancelClick}>
                Cancel
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOneUserForm