import { FC, useContext, useState } from 'react'
import './DeletedFileListItem.scss'
import { CircularProgress } from '@mui/material'
import Typography from '../../DesignSystem/atoms/Typography/Typography'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider'
import FileTypeIcon from '../FileTypeIcon/FileTypeIcon'
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton'
import { useRestoreDocumentMutation } from '../../../types/graphql'

interface FileListItemProps {
  deletedFile: IFile,
  tabIndex?: number,
} 

const DeletedFileListItem: FC<FileListItemProps> = ({deletedFile, tabIndex=0}) => {
  const [deleting, setDeleting] = useState(false);
  const {folderRefetch, directoryPath} = useContext(DocumentContext);

  const [restoreDocumentMutation] = useRestoreDocumentMutation({
    variables: {
      deleteMarkerId: deletedFile?.deleteMarkerId|| '',
      restoreDocumentId: deletedFile?.id || '',
    },
    onCompleted: () => {
      folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]()
    },
    onError: (error) => {
      console.error(`Error deleting document: ${deletedFile.name}`, error)
      alert(`Error deleting ${deletedFile.name}: ${error}`)
      setDeleting(false)
    },
  });

  const buildDisplayFileName = (fileName: string) => {
    // remove urlEncoding from fileName
    fileName = decodeURIComponent(fileName);

    return fileName;
  }

  return (
    <li 
      tabIndex={tabIndex} 
      className='fileListItem'
    >
      <FileTypeIcon fileType={''}/>
      <Typography variant='P1' font='UI1'>{buildDisplayFileName(deletedFile.name || '')}</Typography>

      <div className="endCap">
        <div className={`fileProgressCircle fileProgressBackground ${ deletedFile && deletedFile?.uploadProgress > 99 && 'hide'}`}>
          <CircularProgress variant="determinate" value={deletedFile?.uploadProgress} size={22}/>
        </div>

        {deleting?
          <div className={`fileProgressCircle fileProgressBackground`}>
            <CircularProgress size={22}/>
          </div>
          :
          <ActionButton 
            ariaLabel='Delete File'
            variant='outline'
            handleClick={() => {
              setDeleting(true)
              restoreDocumentMutation()
            }}
            tabIndex={tabIndex}
          >
            Restore
          </ActionButton>
        }
      </div>
    </li>
  )
}

export default DeletedFileListItem