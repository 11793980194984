import { FC, useContext, useEffect, useState } from 'react'
import './index.scss'
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography, Button, Switch, FormControlLabel } from '@mui/material'
import { UserEstateRole, useGetSimpleEstateListQuery } from '../../../types/graphql';
import { EstateContext } from '../../../providers/EstateProvider';
import { useRoleNavigate } from "../../../hooks/useRoleNavigate";
import { formatEstateSelect } from '../../../helpers/functions';

interface SelectAnEstateProps {
  testId?: string
}

type User = { id: number, firstName?: string | null, lastName?: string | null }
type UserArray = Array<{ __typename?: 'UsersOnEstates', user: User }>

const SelectAnEstate: FC<SelectAnEstateProps> = ({testId}) => {
  const {
    selectedEstateId, setSelectedEstateId, setRefetchEstatesList,
  } = useContext(EstateContext);
  const {
    data: estatesData, loading, refetch: refetchEstates, 
  } = useGetSimpleEstateListQuery({variables: {where: {userRole: {equals: UserEstateRole.Executor}}}});
  const navigate = useRoleNavigate()

  const [textInput, setTextInput] = useState('')
  const [useTextInput, setUseTextInput] = useState(false)

  useEffect(() => {
    if (!selectedEstateId) navigate('/adminLanding')
  }, [])

  useEffect(() => {
    setRefetchEstatesList(() => refetchEstates)
  }, [refetchEstates])

  const handleChange = (e: any) => {
    if (e.target.value !== '') {
      setSelectedEstateId(`${e.target.value}`)
      if (window.location.pathname.includes('adminLanding')) navigate('/home')
    } else {
      navigate('/adminLanding')
    }
  }

  const handleTextChange = (e: any) => {
    setTextInput(e.target.value.trim())
  }

  const onTextSubmit = () => {
    if (textInput !== '') {
      setSelectedEstateId(textInput)
      if (window.location.pathname.includes('adminLanding')) navigate('/home')
    } else {
      navigate('/adminLanding')
    }
  }

  const handleToggleChange = () => {
    setUseTextInput(!useTextInput)
  }

  if (loading) return (<Typography>loading...</Typography>)
  return (
    <div data-testid={testId} className='selectAnEstateContainer'>
      <FormControlLabel
        control={<Switch checked={useTextInput} onChange={handleToggleChange} />}
        label="Use Text Input"
      />
      {useTextInput ? (
        <FormControl fullWidth className='selectEstateFormControl'>
          <TextField
            id="outlined-basic"
            label="Enter Estate ID"
            variant="outlined"
            fullWidth
            value={textInput}
            onChange={(e) => handleTextChange(e)}
          />
          <Button variant="contained" color="secondary" onClick={onTextSubmit}>
            Submit
          </Button>
        </FormControl>
      ) : (
        <FormControl fullWidth className='selectEstateFormControl'>
          <InputLabel id="demo-simple-select-label">Estate to Manage</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedEstateId || ''}
            label="Estate to Manage"
            name="selectEstate"
            displayEmpty
            onChange={(e) => handleChange(e)}
          >
            {estatesData && estatesData?.getSimpleEstateList?.length > 0 && estatesData?.getSimpleEstateList?.map((estate) => {
              const displayValue = formatEstateSelect(estate)
              return (
                <MenuItem
                  key={`selectedEstate-${estate.id}`}
                  value={estate.id}
                >
                  {displayValue}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default SelectAnEstate