import { FC, useContext, useEffect, useRef, useState } from "react";

import { Formik, Field, Form, ErrorMessage, FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useApolloClient } from "@apollo/client";
import { AccountContext } from "../../../providers/Authentication";
import AuthViewLayout from "../AuthViewLayout";
import { Container, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import ActionButton from "../../../components/DesignSystem/atoms/ActionButton/ActionButton";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import './login.scss';
import useQueryParams from "../../../hooks/useQueryParams";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { useCaptureLoginFailureMutation, useCaptureLoginMutation } from "../../../types/graphql";
import buildOneOffApolloClient from "../../../helpers/buildOneOffClient";

const Login: FC = () => {
  const apolloClient = useApolloClient();
  const { authenticate } = useContext(AccountContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  // Pull username and password from query params in url
  const { params, hasParams } = useQueryParams();
  const [captureLoginMutation] = useCaptureLoginMutation();
  const [captureLoginFailureMutation] = useCaptureLoginFailureMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  type FormValues = {
    email: string;
    password: string;
  };

  const initialValues: FormValues = {
    email: params?.email || '',
    password: params?.password || '',
  }

  const handleSubmit = async (values: { email: string, password: string }, { setFieldError }: FormikHelpers<FormValues>) => {
    setIsLoading(true);

    // lowercase all characters in the email field
    values.email = values.email.toLowerCase().trim();
    values.password = values.password.trim();

    apolloClient.resetStore();
    try {
      await authenticate(values.email, values.password);
      await captureLoginMutation().then(() => (console.log("Successful login captured")));
    } catch (error: any) {
      const oneOffClient = buildOneOffApolloClient();

      oneOffClient.mutate({
        mutation: gql`mutation CaptureLoginFailure($email: String!) { captureLoginFailure(email: $email) }`,
        variables: { 
          email: values.email,
          reason: error.message,
        },
      }).then(() => (console.log("Failed login captured")));

      if (error.message === 'Incorrect username or password.') error.message = "Incorrect Email or Password"
      setFieldError('email', ' ');
      setFieldError('password', `${error.message}`);
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (hasParams) {
      submitButtonRef.current?.click();
    }
  }, [hasParams]);

  return (
    <AuthViewLayout>
      <div className="loginFormContainer">
        <div className="welcomeHeaderContainer">
          <Typography variant="h1" className="welcomeTitle light">
            Hello
          </Typography>

          <Typography variant="body1" className="welcomeMessage light">
            {"Let's get you signed in."}
          </Typography>
        </div>

        {/* Show the following loading indicator if the login action is running */}
        <div className={`loadingContainer ${isLoading ? '' : 'hide'}`}>
          <LoadingIndicator light={true} />
        </div>

        {/* Hide the following form when the login action is running */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors, touched, submitCount,
          }) => (
            <Form noValidate className={isLoading ? 'hide' : ''}>
              <div className={`fieldWithMargin`}>
                <Field
                  className={`passwordInput light ${touched.email && errors.email && 'error'}`}
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  variant="standard"
                  as={TextField}
                  error={submitCount > 0 && touched.email && !!errors.email}
                />
                <ErrorMessage name="email" component="div" className="inputErrorMessage" />
              </div>

              <div className="fieldWithMargin">
                <Field
                  className={`passwordInput light ${touched.password && errors.password && 'error'}`}
                  id="password"
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  variant="standard"
                  as={TextField}
                  error={submitCount > 0 && touched.password && !!errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="password" component="div" className="inputErrorMessage" />
                <ErrorMessage name="form" component="div" className="inputErrorMessage" />
              </div>

              <Container className="buttonsContainer">
                <ActionButton
                  ariaLabel="Sign In"
                  className="signInButton"
                  trackClick={false}
                  type="submit"
                  variant="solid"
                  reference={submitButtonRef}
                >
                  Sign in
                </ActionButton>
                <Link
                  href="/iforgot"
                  variant="body1"
                  className="forgotPasswordLink"
                >
                  <Typography variant="body1">
                    I forgot my password
                  </Typography>
                </Link>
              </Container>
            </Form>
          )}
        </Formik>


      </div>
    </AuthViewLayout>
  );
};

export default Login;