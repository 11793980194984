import { FC } from 'react'
import './ManageUser.scss'
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton';
import useModal from '../../../hooks/useModal';
import SelectUser from '../../SelectUser/SelectUser';

interface ManageUserProps {
  testId?: string
}

const ManageUser: FC<ManageUserProps> = ({testId}) => {
  const { setModal } = useModal()

  return (
    <ActionButton
      ariaLabel='Open Select User Form'
      handleClick={() => setModal(<SelectUser />)}
      className='updateCustomerButton'
    >
    Modify Customers/Users on Estate
    </ActionButton>
  )
}

export default ManageUser
