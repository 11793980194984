import { FC, useContext, useState } from 'react'
import styles from './EstateChartSmall.module.scss'
import Typography from '../../atoms/Typography/Typography'
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";
import LinkButton from '../../atoms/LinkButton/LinkButton'
import EstatePieChart from '../../../Charts/EstatePieChart/EstatePieChart'
import { ReactComponent as RightArrowSvgIcon } from "../../../../assets/icons/navigation/right-arrow.svg";
import { useGetEstatePieDataQuery } from '../../../../types/graphql'
import { EstateContext } from '../../../../providers/EstateProvider'
import { formatDollarAmount } from '../../../../helpers'

interface EstateChartSmallProps {
  testId?: string
}

const EstateChartSmall: FC<EstateChartSmallProps> = ({testId}) => {
  const navigate = useRoleNavigate();
  const { selectedEstateId } = useContext(EstateContext);
  const [activeComponentValue, setActiveComponentValue] = useState<string>();
  const [ total, setTotal ] = useState("");

  useGetEstatePieDataQuery({
    variables: {estateId: selectedEstateId},
    onCompleted: (data) => {
      if( data?.getUserEstate?.componentBreakDown && 
      data.getUserEstate?.componentBreakDown[0]  ) {
        const total = formatDollarAmount(data.getUserEstate.componentBreakDown[0].total)
        setTotal( total )
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const updateDisplayDollarValue = (value: string | undefined) => {
    if (!value) {
      setActiveComponentValue(undefined)
      return
    }
    const dollarValue = formatDollarAmount(parseInt(value))
    console.log('dollarValue', dollarValue)
    setActiveComponentValue(dollarValue)
  }

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartSummary}>
        <Typography
          font="UI1"
          fontWeight="medium"
          variant='P3'
          className={styles.estateValueDescriptor}
        >{"Estate Value (estimated)"}</Typography>
        <Typography 
          font="UI1"
          variant='H3'
          fontWeight="regular"
          className={styles.estateDollarValueText}
        >{activeComponentValue || total}</Typography>
        <LinkButton
          ariaLabel="estate overview link" 
          className={styles.estateLinkButton}
          handleClick={() => navigate("/estate")} 
          variant={"outline"} 
        >
          <div className={styles.buttonContent}>
            <Typography font="UI1" variant="P1">
            VIEW DETAILS
            </Typography>
            <RightArrowSvgIcon className={styles.svgRightArrowSvgIcon} />
          </div>
        </LinkButton>
      </div>
      <div className={styles.pieChart}>
        <EstatePieChart 
          sideLength={120} 
          innerRadius={35}
          defaultColorFill="#000" testId="estate-view-piechart"
          onActiveChange={(value: string | undefined) => updateDisplayDollarValue(value)}
        />
      </div>
    </div>
  )
}

export default EstateChartSmall
