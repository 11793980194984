import { FC } from 'react'
import './CustomerRegister.scss'
import { Typography } from '@mui/material'
import AuthViewLayout from '../AuthViewLayout'
import CustomerSignUpUserForm from '../../../components/FormikForms/customer/CustomerSignUpUserForm/CustomerSignUpUserForm'

interface CustomerRegisterProps {
  testId?: string
}

const CustomerRegister: FC<CustomerRegisterProps> = ({testId}) => {
  return (
    <AuthViewLayout>
      <div data-testid={testId} className='customerRegisterContainer'>
        <div className="customerHeaderContainer">
          <Typography variant="h1" className="welcomeTitle light">
            Register
          </Typography>
        </div>
        <CustomerSignUpUserForm />
      </div>
    </AuthViewLayout>
  )
}

export default CustomerRegister