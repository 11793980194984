import {FC, useContext, useState } from 'react';
import LinkButton from '../../atoms/LinkButton/LinkButton';
import NotificationBell from '../../atoms/NotificationBell/NotificationBell';

import styles from './NotificationBellAsLink.module.scss';
import { EstateContext } from '../../../../providers/EstateProvider';
import { AccountContext } from '../../../../providers/Authentication';
import { useGetUnseenNotificationsCountQuery } from '../../../../types/graphql';
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";

// interface NotificationBellAsLinkProps {
//   bellIconClassName?: string;
//   className?: string;
//   clickHandler?: () => void;
//   hasUnseen?: boolean;
//   testId?: string;
// }


interface NotificationBellAsLinkProps {
  testId?: string;
  className?: string;
  bellIconClassName?: string;
}

const NotificationBellAsLink: FC<NotificationBellAsLinkProps> = ({
  testId, className, bellIconClassName,
}) => {
  const navigate = useRoleNavigate();
  const { currentEstate } = useContext(EstateContext);
  const { userId } = useContext(AccountContext);
  const [hasUnseenNotifications, setHasUnseenNotifications] = useState(false);


  const {refetch} = useGetUnseenNotificationsCountQuery({
    variables: {
      estateId: currentEstate?.getUserEstate?.id,
      userId: userId,
    },
    onCompleted: (data) => {
      setHasUnseenNotifications(data.getUnseenNotificationsCount > 0);
    },
  });

  const refetchNotificationsCount = async () => {
    const response = await refetch()
    setHasUnseenNotifications(response.data.getUnseenNotificationsCount > 0);
  }

  const clickHandler = () => {
    navigate("/notifications")
    refetchNotificationsCount()
  }

  const containerClassName = `${styles.notificationBellAsLinkContainer} ${className || ''}`.trim();

  return (
    <div className={containerClassName}> 
      {hasUnseenNotifications && <div className={styles.unseenNotificationsMarker}></div>}
      <LinkButton
        ariaLabel="Notifications"
        tabIndex={0}
        variant="borderless"
        className={containerClassName}
        testId={testId}
        handleClick={clickHandler}
      >
        <NotificationBell className={bellIconClassName}/>
      </LinkButton>
    </div>
  );
};

export default NotificationBellAsLink;