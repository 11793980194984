import { FC, useContext, useEffect, useState } from 'react';
import { useRoleNavigate } from "../../hooks/useRoleNavigate";

import { Header, SubHeader } from '../Headers/Headers';
import { LayoutContext } from '../../providers/LayoutProvider';
import './userLayout.scss';
import { AccountContext } from '../../providers/Authentication';
import { EstateContext } from '../../providers/EstateProvider';
import CareTeamCard from '../CareTeamCard/CareTeamCard';
import Footer from '../Footer/Footer';
import { useScreenWidth } from '../../helpers';
import Modal from '../Modal/Modal';
import LoadingOverlay from '../../views/LoadingOverlay';
import { useDecision } from '@optimizely/react-sdk';
import { AdminToolBar } from '../Admin';
import CustomerNav from '../PageNav/CustomerNav';
import { useNoDecedantInfoRedirect } from '../../hooks/useNoDecedantInfoRedirect';

interface UserLayoutProps {
  children: JSX.Element | JSX.Element[];
  includeSubHeader?: boolean;
  includeHeader?: boolean;
  includeSideNav?: boolean;
  includeFooter?: boolean;
  includeCareTeamCard?: boolean;
  subHeaderText?: string;
  altTitleText?: string;
  testId?: string;
  className?: string;
  adminDeceasedTools?: boolean;
  adminDocumentTools?: boolean;
  adminCustomerTools?: boolean;
  fixedHeader?: boolean;
}

const UserLayout: FC<UserLayoutProps> = ({
  children,
  includeSubHeader=true,
  includeHeader=true,
  includeSideNav=true,
  includeCareTeamCard=true,
  subHeaderText,
  altTitleText,
  includeFooter=true,
  className,
  testId,
  adminDeceasedTools=false,
  adminDocumentTools=false,
  adminCustomerTools=false,
  fixedHeader=false,
}) => {
  useNoDecedantInfoRedirect();

  const {hasAdminAuthorization} = useContext(AccountContext);
  const {selectedEstateId, currentEstate} = useContext(EstateContext);
  const {isLoading} = useContext(LayoutContext);
  const screenWidth = useScreenWidth()
  const [decedentInfoFlag] = useDecision('deceased_information_form', {autoUpdate: true});

  // Hide Content if there is no deceased information and not admin user
  if ( decedentInfoFlag && !currentEstate?.getUserEstate?.deceased && !hasAdminAuthorization) return <LoadingOverlay />;
  return (
    <div className={`pageLayout ${className || ''}`} data-testid={testId}>
      { hasAdminAuthorization && <AdminToolBar /> }
      {includeHeader && <Header fixed={fixedHeader}/>}
      <div className={`pageContent`}>
        {includeSubHeader && (!hasAdminAuthorization || (hasAdminAuthorization && selectedEstateId) ) && (
          <SubHeader 
            text={subHeaderText} 
            altTitleText={altTitleText}
            adminDeceasedTools={adminDeceasedTools}
            adminDocumentTools={adminDocumentTools}
            adminCustomerTools={adminCustomerTools}
          />
        )}
        {children}
        <Modal />
      </div>
      {screenWidth < 800 && includeCareTeamCard && <CareTeamCard />}
      {screenWidth < 800 && includeFooter && <Footer />}
      {includeSideNav && <CustomerNav />}
    </div>
  )
};

export default UserLayout;