import { useContext } from "react"
import { LayoutContext } from "../providers/LayoutProvider"
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"



const useModal = () => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)

  const setModal = (formComponent: ReactJSXElement) => {
    setModalContents(formComponent)
    setDisplayModal(true)
  }

  return {setModal}
}

export default useModal;