import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {DeceasedCreateWithoutEstateInput, useCustomerAddDeceasedToEstateMutation, ValidatedEstateUpdateInput } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { handleNestedValues, USStatesAndTerritoriesAbbreviations } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";
import { EstateContext } from '../../../../providers/EstateProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

  type CustomerAddDeceasedToEstateFormProps = {
    testId?: string
  }

const CustomerAddDeceasedToEstateForm: FC<CustomerAddDeceasedToEstateFormProps> = ({testId}) => {
  const navigate = useRoleNavigate();
    
  // const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const {setDeceased} = useContext(EstateContext);
  const [ customerAddDeceasedToEstateMutation ] = useCustomerAddDeceasedToEstateMutation();

  const handleSubmit = async (
    values: DeceasedCreateWithoutEstateInput,
  ) => {
    // create nested values object
    const nestedValues = {
      deceased: {
        create: {
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: values.dateOfBirth,
          dateOfDeath: values.dateOfDeath,
        },
      },
    }

    customerAddDeceasedToEstateMutation({
      variables: {data: nestedValues},
      onCompleted: (data) => {
        setDeceased(data.updateUserEstate?.deceased || null);
        navigate("/home");
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed customerAddDeceasedToEstateMutation call");
        console.error(e.message);
        alert(e.message);
      },
    });
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    // dateOfBirth: entity.dateOfBirth ? dayjs.utc(entity.dateOfBirth) : null,
    // dateOfDeath: entity.dateOfDeath ? dayjs.utc(entity.dateOfDeath) : null,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    // dateOfBirth: Yup.date().nullable(),
    // dateOfDeath: Yup.date().nullable(), 
  });

  return (
    <div data-testid={testId} className='customerAddDeceasedToEstateFormContainer'>
      {/* <Typography variant='h4' className='customerAddDeceasedToEstateFormHeader'>
        Customer Add Deceased To Estate Form
      </Typography> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, 
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName6"} data-testid={testId}>
                  <Field
                    className={"deceasedFirstNameInput fieldInput textInput light"}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    variant="standard"
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName && !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName7"} data-testid={testId}>
                  <Field
                    className={"lastNameInput fieldInput textInput light"}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    variant="standard"
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName && !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage"/>
                </div>
                {/*       
                <div className={"inputWrapper deceasedDateOfBirthFieldWrapper textInputWrapper"} key={"deceasedDateOfBirth9"} data-testid={testId}>
                  <Field
                    className={"deceasedDateOfBirthInput fieldInput textInput"}
                    required={false}
                    id={"deceasedDateOfBirth"}
                    label={"Date Of Birth"}
                    name={"deceasedDateOfBirth"}
                    type={"date"}
                    variant="standard"
                    autoComplete={"deceasedDateOfBirth"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.dateOfBirth && !!errors.dateOfBirth}
                    onChange={(date: any) => setFieldValue("deceasedDateOfBirth", date)}
                  />
                  <ErrorMessage name={"deceasedDateOfBirth"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper deceasedDateOfDeathFieldWrapper textInputWrapper"} key={"deceasedDateOfDeath10"} data-testid={testId}>
                  <Field
                    className={"deceasedDateOfDeathInput fieldInput textInput"}
                    required={false}
                    id={"deceasedDateOfDeath"}
                    label={"Date Of Death"}
                    name={"deceasedDateOfDeath"}
                    type={"date"}
                    variant="standard"
                    autoComplete={"deceasedDateOfDeath"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.dateOfDeath && !!errors.dateOfDeath}
                    onChange={(date: any) => setFieldValue("deceasedDateOfDeath", date)}
                  />
                  <ErrorMessage name={"deceasedDateOfDeath"} component="div" className="inputErrorMessage"/>
                </div> */}
              </Container>
            </Container>

            <Container className="buttonsContainer">
              {/* <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton> */}

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                 Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CustomerAddDeceasedToEstateForm