import { FC, useContext } from 'react'
import './index.scss'
import { Typography } from '@mui/material'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../../providers/LayoutProvider'
import { SortOrder, useDeleteOneActionCardMutation } from '../../../../types/graphql'
import { ActionContext } from '../../../../providers/ActionProvider'
import { EstateContext } from '../../../../providers/EstateProvider'

interface DeleteOneActionCardFormProps {
  testId?: string
}

const DeleteOneActionCardForm: FC<DeleteOneActionCardFormProps> = ({testId}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const {refetchActionList} = useContext(ActionContext)
  const {currentAction} = useContext( ActionContext ) // add context for item id to be deleted
  // const { selectedEstateId } = useContext( EstateContext )
  
  const [ deleteOneActionCardMutation ] = useDeleteOneActionCardMutation({
    variables: {where: {id: currentAction?.id}},
    onCompleted: (data) => {
      // handle any actions needed on complete
      refetchActionList()
    },
    onError: (error) => {
      // handle errors as desired
      alert(error)
    },
  })

  const handleDeleteClick = () => {
    //delete mutation here
    deleteOneActionCardMutation()
    setDisplayModal(false)
    setModalContents(<></>)
  }

  const handleCancelClick = () => {
    setDisplayModal(false)
    setModalContents(<></>)
  }

  return (
    <div data-testid={testId} className='deleteOneActionCardFormContainer'>
      <Typography variant='h2'>
        Are you sure you would like to delete this item? Deletion is permanent and cannot be undone. 
      </Typography>
      <div className='actionButtonContainer'>
        <ActionButton ariaLabel={'delete the selected action card'} variant='solid' handleClick={handleDeleteClick}>
          Delete
        </ActionButton>
        <ActionButton ariaLabel={'cancel delete'} handleClick={handleCancelClick}>
          Cancel
        </ActionButton>
      </div>
    </div>
  )
}

export default DeleteOneActionCardForm