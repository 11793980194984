import { FC, useContext, useEffect, useState } from 'react'
import './FilesList.scss'
import { CircularProgress } from '@mui/material'
import { DocumentContext } from '../../../providers/DocumentProvider';
import FileListItem from '../FileListItem/FileListItem';
import { ListDocumentsQuery, useListDocumentsQuery } from '../../../types/graphql';
import { EstateContext } from '../../../providers/EstateProvider';
import useFileUpload from '../../../hooks/useFileUpload';

interface FilesListProps {
  testId?: string,
  className?: string,
  type: 'upload' | 'download' | 'menu' | 'options',
  directory?: string
  tabIndex?: number,
  expanded?: boolean,
}

const FilesList: FC<FilesListProps> = ({
  testId, className, type, directory, tabIndex = 0, expanded = true,
}) => {
  const [componentIsLoading, setComponentIsLoading] = useState(false)
  const { selectedEstateId } = useContext(EstateContext)
  const {
    folderFiles, setFolderFiles, setFolderRefetch,
  } = useContext(DocumentContext);
  const { formatFilesQueryDataToFileUploads } = useFileUpload()

  const {
    data, loading, error, refetch,
  } = useListDocumentsQuery({
    variables: {
      "directory": `${directory}/`,
      "estateId": selectedEstateId,
    },
  })

  const formatFiles = async (data: ListDocumentsQuery) => {
    const formatted = await formatFilesQueryDataToFileUploads(data)
    setFolderFiles((files) => ({
      ...files,
      [directory || '']: formatted,
    }))
  }

  useEffect(() => {
    if (data) {
      setComponentIsLoading(true)
      console.log('useEffect data:', data)
      formatFiles(data).then(() => setComponentIsLoading(false))
    }

    error && console.error("Error loading ListDocuments:", error)
  }, [data, error])

  useEffect(() => {
    directory && refetch && setFolderRefetch(folders => {
      return {
        ...folders,
        [directory || '']: refetch,
      }
    })
  }, [refetch])

  return (
    <>
      {
        expanded ? (
          <div data-testid={testId} className={`filesListContainer ${className}`} tabIndex={tabIndex}>
            {componentIsLoading || loading ? (
              <div className='loadingContainer'>
                <CircularProgress />
              </div>
            ) : (
              <ul className='fileList'>
                {folderFiles && directory && folderFiles[directory] &&
                  Object.entries(folderFiles[directory])
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([_key, file], index) => (
                      <FileListItem tabIndex={tabIndex} key={`${file.name}${index}`} file={file} type={type} />
                    ))
                }
              </ul>
            )}
          </div>
        ) : null
      }
    </>
  )
}

export default FilesList

