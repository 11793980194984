import { FC, useContext, useEffect, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useUpdateOneDeceasedMutation, DeceasedUpdateInput, UpdateOneDeceasedMutation } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { USStatesAndTerritoriesAbbreviations, handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { convertTimestampToHumanReadable3, formatEstateSelect } from '../../../../helpers/functions';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

  type UpdateOneDeceasedFormProps = {
    testId?: string
  }

const UpdateOneDeceasedForm: FC<UpdateOneDeceasedFormProps> = ({testId}) => {
  const {
    setDisplayModal, 
    setModalContents,
  } = useContext(LayoutContext)
  const {
    currentEstate,
    deceased,
    refetchCurrentEstate,
    setDeceased,
  } = useContext(EstateContext)
  // provide the update entity as this variable
  const [entity, setEntity] = useState<any>(deceased)
  // const entity:any = deceased

  useEffect(() => {
    setEntity(deceased)
  }, [ ])
      
  const [ updateOneDeceasedMutation ] = useUpdateOneDeceasedMutation();
  const [ options, setOptions] = useState<any>({
    "estate": [
      {
        "id": currentEstate?.getUserEstate?.id,
        "displayValue": formatEstateSelect(currentEstate?.getUserEstate),
      },
    ],
  })

  const handleSubmit = async (
    values: DeceasedUpdateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as DeceasedUpdateInput

    updateOneDeceasedMutation({
      variables: {
        data: nestedValues,
        where: {id: entity?.id},
      },
      onCompleted: (data:UpdateOneDeceasedMutation) => {
        refetchCurrentEstate();
        setDeceased(data.updateOneDeceased || null);
        setDisplayModal(false);
        setModalContents(null);
        resetForm();
      }, 
      onError: (e: any) => {
        console.error("Failed updateOneDeceasedMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
    
    customSubmitAction()
  }

  const initialValues = {
    estate: options.estate[0].id || '',
    referringPartner: entity.referringPartner || '',
    firstName: entity.firstName || '',
    lastName: entity.lastName || '',
    dateOfBirth: entity.dateOfBirth ? dayjs.utc(entity.dateOfBirth) : null,
    dateOfDeath: entity.dateOfDeath ? dayjs.utc(entity.dateOfDeath) : null,
    podState: entity.podState || '',
    maritalStatus: entity.maritalStatus || '',
    veteranStatus: entity.veteranStatus || false,
    nameOfHospital: entity.nameOfHospital || '',
    address: entity.address || '',
    citizenship: entity.citizenship || '',
    city: entity.city || '',
    county: entity.county || '',
    state: entity.state || '',
    country: entity.country || '',
    podAddress: entity.podAddress || '',
    podCity: entity.podCity || '',
    podCounty: entity.podCounty || '',
    podCountry: entity.podCountry || '',
  };

  const validationSchema = Yup.object().shape({
    estate: Yup.string(),
    referringPartner: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    dateOfBirth: Yup.date().nullable(),
    dateOfDeath: Yup.date().nullable(),
    podState: Yup.string().matches(/^[A-Z]{2}$/, "State must be a two letter abbreviation")
      .oneOf(USStatesAndTerritoriesAbbreviations, "Invalid state/territory abbreviation"),
    maritalStatus: Yup.string(),
    veteranStatus: Yup.boolean(),
    nameOfHospital: Yup.string(),
    address: Yup.string(),
    citizenship: Yup.string(),
    city: Yup.string(),
    county: Yup.string(),
    state: Yup.string().required('State is required').matches(/^[A-Z]{2}$/, "State must be a two letter abbreviation")
      .oneOf(USStatesAndTerritoriesAbbreviations, "Invalid state/territory abbreviation"),
    country: Yup.string(),
    podAddress: Yup.string(),
    podCity: Yup.string(),
    podCounty: Yup.string(),
    podCountry: Yup.string(), 
  });

  const customSubmitAction = () => {
    // Optional: add additional actions to be executed on submit.
  }

  return (
    <div data-testid={testId} className='updateOneDeceasedFormContainer'>
      <Typography variant='h4' className='updateOneDeceasedFormHeader'>
        Update One Deceased Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Typography variant="h5">Relationships</Typography>
              <Container className="formSection">

                <div className={"inputWrapper estateFieldWrapper dropdownInputWrapper"} key={"estate0"} data-testid={testId}>
                  <Autocomplete
                    options={options.estate || []}
                    getOptionLabel={(option: {id: number, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"estateInput fieldInput dropdownInput"}
                        required={false}
                        id={"estate"}
                        label={"Estate"}
                        name={"estate"}
                        type={"text"}
                        autoComplete={"estate"}
                        as={TextField}
                        disable
                        error={submitCount > 0 && touched.estate&& !!errors.estate}                        
                      />
                    }
                    defaultValue={options.estate[0]}
                    onChange={(_, value) => setFieldValue("estate", value?.id)}
                  />
                  <ErrorMessage name={"estate"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper referringPartnerFieldWrapper textInputWrapper"} key={"referringPartner1"} data-testid={testId}>
                  <Field
                    className={"referringPartnerInput fieldInput textInput"}
                    required={false}
                    id={"referringPartner"}
                    label={"Referring Partner"}
                    name={"referringPartner"}
                    type={"text"}
                    autoComplete={"referringPartner"}
                    as={TextField}
                    error={submitCount > 0 && touched.referringPartner&& !!errors.referringPartner}
                  />
                  <ErrorMessage name={"referringPartner"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Required Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName2"} data-testid={testId}>
                  <Field
                    className={"firstNameInput fieldInput textInput"}
                    required={false}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName&& !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName3"} data-testid={testId}>
                  <Field
                    className={"lastNameInput fieldInput textInput"}
                    required={false}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName&& !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper dateOfBirthFieldWrapper textInputWrapper"} key={"dateOfBirth4"} data-testid={testId}>
                  <Field
                    className={"dateOfBirthInput fieldInput textInput"}
                    required={false}
                    id={"dateOfBirth"}
                    label={"Date Of Birth"}
                    name={"dateOfBirth"}
                    type={"date"}
                    autoComplete={"dateOfBirth"}
                    as={DatePicker}
                    value={values.dateOfBirth}
                    error={submitCount > 0 && touched.dateOfBirth&& !!errors.dateOfBirth}
                    onChange={(date: any) => setFieldValue("dateOfBirth", date)}
                  />
                  <ErrorMessage name={"dateOfBirth"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper dateOfDeathFieldWrapper textInputWrapper"} key={"dateOfDeath5"} data-testid={testId}>
                  <Field
                    className={"dateOfDeathInput fieldInput textInput"}
                    required={false}
                    id={"dateOfDeath"}
                    label={"Date Of Death"}
                    name={"dateOfDeath"}
                    type={"date"}
                    autoComplete={"dateOfDeath"}
                    as={DatePicker}
                    value={values.dateOfDeath}
                    error={submitCount > 0 && touched.dateOfDeath&& !!errors.dateOfDeath}
                    onChange={(date: any) => setFieldValue("dateOfDeath", date)}
                  />
                  <ErrorMessage name={"dateOfDeath"} component="div" className="inputErrorMessage"/>
                </div>
                {/*       
                <div className={"inputWrapper podStateFieldWrapper textInputWrapper"} key={"podState6"} data-testid={testId}>
                  <Field
                    className={"podStateInput fieldInput textInput"}
                    required={false}
                    id={"podState"}
                    label={"Pod State"}
                    name={"podState"}
                    type={"text"}
                    autoComplete={"podState"}
                    as={TextField}
                    error={submitCount > 0 && touched.podState&& !!errors.podState}
                  />
                  <ErrorMessage name={"podState"} component="div" className="inputErrorMessage"/>
                </div> */}

                      
                <div className={"inputWrapper podStateFieldWrapper textInputWrapper"} key={"podState6"} data-testid={testId}>
                  <Autocomplete
                    id="podState"
                    className={"podStateInput fieldInput textInput"}
                    options={USStatesAndTerritoriesAbbreviations.map((abbreviation: string) => abbreviation)}
                    getOptionLabel={(option: string) => option}
                    onChange={(_e, value) => {
                      setFieldValue(
                        "podState",
                        initialValues.podState,
                      );
                    }}
                    renderInput={params => (
                      <Field
                        {...params}
                        className={"podStateInput fieldInput textInput"}
                        required={false}
                        id={"podState"}
                        label={"Pod State"}
                        name={"podState"}
                        type={"text"}
                        autoComplete={"podState"}
                        as={TextField}
                        error={submitCount > 0 && touched.podState&& !!errors.podState}
                      />
                    )}
                  />
                  <ErrorMessage name={`podState`} component="div" className="inputErrorMessage" />
                </div>

                <div className={"inputWrapper stateFieldWrapper textInputWrapper"} key={"state14"} data-testid={testId}>
                  <Autocomplete
                    id="state"
                    className={"decedantStateInput fieldInput textInput"}
                    options={USStatesAndTerritoriesAbbreviations.map((abbreviation: string) => abbreviation)}
                    getOptionLabel={(option: string) => option}
                    onChange={(_e, value) => {
                      setFieldValue(
                        "state",
                        value !== null ? value : initialValues.state,
                      );
                    }}
                    renderInput={params => (
                      <Field
                        {...params}
                        className={"stateInput fieldInput textInput"}
                        required={true}
                        id={"state"}
                        label={"Decedent state of residence"}
                        name={"state"}
                        type={"text"}
                        autoComplete={"state"}
                        as={TextField}
                        error={submitCount > 0 && touched.state && !!errors.state}
                      />
                    )}
                  />
                </div>
                <ErrorMessage name={`state`} component="div" className="inputErrorMessage" />
              </Container>

              <hr />

              <Typography variant="h5">Optional Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper maritalStatusFieldWrapper textInputWrapper"} key={"maritalStatus7"} data-testid={testId}>
                  <Field
                    className={"maritalStatusInput fieldInput textInput"}
                    required={false}
                    id={"maritalStatus"}
                    label={"Marital Status"}
                    name={"maritalStatus"}
                    type={"text"}
                    autoComplete={"maritalStatus"}
                    as={TextField}
                    error={submitCount > 0 && touched.maritalStatus&& !!errors.maritalStatus}
                  />
                  <ErrorMessage name={"maritalStatus"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper veteranStatusFieldWrapper booleanInputWrapper"} key={"veteranStatus8"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">Veteran Status</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field 
                    className={"veteranStatusInput fieldInput booleanInput"}
                    required={false}
                    id={"veteranStatus"}
                    name={"veteranStatus"}
                    type={"checkbox"} 
                    as={Switch} 
                    error={submitCount > 0 && touched.veteranStatus&& !!errors.veteranStatus}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"veteranStatus"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper nameOfHospitalFieldWrapper textInputWrapper"} key={"nameOfHospital9"} data-testid={testId}>
                  <Field
                    className={"nameOfHospitalInput fieldInput textInput"}
                    required={false}
                    id={"nameOfHospital"}
                    label={"Name Of Hospital"}
                    name={"nameOfHospital"}
                    type={"text"}
                    autoComplete={"nameOfHospital"}
                    as={TextField}
                    error={submitCount > 0 && touched.nameOfHospital&& !!errors.nameOfHospital}
                  />
                  <ErrorMessage name={"nameOfHospital"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper addressFieldWrapper textInputWrapper"} key={"address10"} data-testid={testId}>
                  <Field
                    className={"addressInput fieldInput textInput"}
                    required={false}
                    id={"address"}
                    label={"Address"}
                    name={"address"}
                    type={"text"}
                    autoComplete={"address"}
                    as={TextField}
                    error={submitCount > 0 && touched.address&& !!errors.address}
                  />
                  <ErrorMessage name={"address"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper citizenshipFieldWrapper textInputWrapper"} key={"citizenship11"} data-testid={testId}>
                  <Field
                    className={"citizenshipInput fieldInput textInput"}
                    required={false}
                    id={"citizenship"}
                    label={"Citizenship"}
                    name={"citizenship"}
                    type={"text"}
                    autoComplete={"citizenship"}
                    as={TextField}
                    error={submitCount > 0 && touched.citizenship&& !!errors.citizenship}
                  />
                  <ErrorMessage name={"citizenship"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper cityFieldWrapper textInputWrapper"} key={"city12"} data-testid={testId}>
                  <Field
                    className={"cityInput fieldInput textInput"}
                    required={false}
                    id={"city"}
                    label={"City"}
                    name={"city"}
                    type={"text"}
                    autoComplete={"city"}
                    as={TextField}
                    error={submitCount > 0 && touched.city&& !!errors.city}
                  />
                  <ErrorMessage name={"city"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper countyFieldWrapper textInputWrapper"} key={"county13"} data-testid={testId}>
                  <Field
                    className={"countyInput fieldInput textInput"}
                    required={false}
                    id={"county"}
                    label={"County"}
                    name={"county"}
                    type={"text"}
                    autoComplete={"county"}
                    as={TextField}
                    error={submitCount > 0 && touched.county&& !!errors.county}
                  />
                  <ErrorMessage name={"county"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper countryFieldWrapper textInputWrapper"} key={"country15"} data-testid={testId}>
                  <Field
                    className={"countryInput fieldInput textInput"}
                    required={false}
                    id={"country"}
                    label={"Country"}
                    name={"country"}
                    type={"text"}
                    autoComplete={"country"}
                    as={TextField}
                    error={submitCount > 0 && touched.country&& !!errors.country}
                  />
                  <ErrorMessage name={"country"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podAddressFieldWrapper textInputWrapper"} key={"podAddress16"} data-testid={testId}>
                  <Field
                    className={"podAddressInput fieldInput textInput"}
                    required={false}
                    id={"podAddress"}
                    label={"Pod Address"}
                    name={"podAddress"}
                    type={"text"}
                    autoComplete={"podAddress"}
                    as={TextField}
                    error={submitCount > 0 && touched.podAddress&& !!errors.podAddress}
                  />
                  <ErrorMessage name={"podAddress"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCityFieldWrapper textInputWrapper"} key={"podCity17"} data-testid={testId}>
                  <Field
                    className={"podCityInput fieldInput textInput"}
                    required={false}
                    id={"podCity"}
                    label={"Pod City"}
                    name={"podCity"}
                    type={"text"}
                    autoComplete={"podCity"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCity&& !!errors.podCity}
                  />
                  <ErrorMessage name={"podCity"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCountyFieldWrapper textInputWrapper"} key={"podCounty18"} data-testid={testId}>
                  <Field
                    className={"podCountyInput fieldInput textInput"}
                    required={false}
                    id={"podCounty"}
                    label={"Pod County"}
                    name={"podCounty"}
                    type={"text"}
                    autoComplete={"podCounty"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCounty&& !!errors.podCounty}
                  />
                  <ErrorMessage name={"podCounty"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper podCountryFieldWrapper textInputWrapper"} key={"podCountry19"} data-testid={testId}>
                  <Field
                    className={"podCountryInput fieldInput textInput"}
                    required={false}
                    id={"podCountry"}
                    label={"Pod Country"}
                    name={"podCountry"}
                    type={"text"}
                    autoComplete={"podCountry"}
                    as={TextField}
                    error={submitCount > 0 && touched.podCountry&& !!errors.podCountry}
                  />
                  <ErrorMessage name={"podCountry"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                      Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                      Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateOneDeceasedForm