import { FC } from 'react'
import useResponsiveComponent from '../../hooks/useResponsiveComponent'

import './estateView.scss'

import DesktopView from './Desktop'
import MobileView from './Mobile'

const EstateView: FC = () => {
  const ResponsiveComponent = useResponsiveComponent({
    mobile: MobileView,
    desktop: DesktopView,
  })

  return <ResponsiveComponent />
};

export default EstateView