import { formatWholeDollarAmount } from "../../helpers";
import InfoIcon from "../DesignSystem/atoms/InfoIcon/InfoIcon";
import Typography from "../DesignSystem/atoms/Typography/Typography";
import './EstimatedDistributionCard.scss'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from "@mui/material";
import { AlixFeeDisplay } from "../../types/graphql";

export interface BodyMessage {
  message?: string
  title?: string
}

export interface DistributionCardProps {
  totalValue: string
  debts: string
  alixFee?: AlixFeeDisplay
  estimatedDistribution: string
  distributionInfo?: BodyMessage | null | undefined
  feeCalculationInfo?: BodyMessage | null | undefined
}

export default function EstimatedDistributionCard({
  totalValue, debts, alixFee, estimatedDistribution, distributionInfo, feeCalculationInfo,
}: DistributionCardProps) {

  const date = new Date(alixFee?.updatedAt || '');

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  
  const formattedDate = `${month}/${day}/${year}`;

  const status = {
    ESTIMATED: "estimated",
    INVOICED: "invoiced",
    UPDATED:"updated",
  }

  console.log(alixFee)
  const renderLabel = () => {
    
    if (alixFee?.status) {
      return `Alix fee (${status[alixFee?.status as keyof typeof status ]}${alixFee?.status === "UPDATED" ? " on " + formattedDate : ""})`;
    }
    return "Alix fee";
  };


  return (
    <>
      <div className="estimateCardContainer">
        <div className="internalContainer">
          <Typography className="estimatedValue" variant="P3" font="UI1" >
            <div className="colorEstimatedName">Estimated distribution</div></Typography>
          {distributionInfo &&          <Tooltip enterTouchDelay={0} title={
            <>
              <h4 className="fontWEstimateTitle">{distributionInfo?.title}</h4>
              <p>{distributionInfo?.message}</p>
            </>
          } placement="top-start">
            <IconButton
              size="small"
              aria-haspopup="true"
            >
              <InfoIcon width={18} height={18} />
            </IconButton>

          </Tooltip>

          }

        </div>

        <Typography variant="H3" font="UI1" fontWeight="semibold">{formatWholeDollarAmount(parseFloat(estimatedDistribution))}</Typography>
        <hr />

        <div className="divider">
          <div className="container">
            <Typography variant="L3" font="UI1" >Total asset value</Typography>
            <div className="dividerInternalGap">
              <Typography variant="L3" font="UI1" >{renderLabel()}</Typography>
              {feeCalculationInfo &&
              <Tooltip enterTouchDelay={0} title={
                <>
                  <h4 className="fontWEstimateTitle">{feeCalculationInfo?.title}</h4>
                  <p>{feeCalculationInfo?.message}</p>
                </>
              } placement="top-start">
                <IconButton
                  size="small"
                  aria-haspopup="true"
                >
                  <InfoIcon width={18} height={18} />
                </IconButton>
              </Tooltip>}
            </div>
            <Typography variant="L3" font="UI1" >Debts</Typography>
            <Typography variant="L3" font="UI1" fontWeight="semibold">Estimated distribution</Typography>
          </div>
          <div className="container">
            <Typography variant="L3" font="UI1" >{formatWholeDollarAmount(parseFloat(totalValue ?? 0))}</Typography>
            <Typography variant="L3" font="UI1" >{`$(${formatWholeDollarAmount(parseFloat(alixFee?.amount ?? 0)).replace("$", "")})`}</Typography>
            <Typography variant="L3" font="UI1" >{`$(${formatWholeDollarAmount(parseFloat(debts ?? 0)).replace("$", "")})`}</Typography>
            <Typography variant="L3" font="UI1" fontWeight="semibold">{formatWholeDollarAmount(parseFloat(estimatedDistribution))}</Typography>
          </div>
        </div>
        <hr />
      </div>
    </>
  )
}