import { useContext, useState, useEffect } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

// Icons
import { formatWholeDollarAmount, pascalToNormalCase } from '../../../helpers/functions';

import './EstatePieChart.scss';
import { ComponentSubType, ComponentType, GetEstatePieDataQuery, useGetEstatePieDataQuery } from '../../../types/graphql';
import { EstateContext } from '../../../providers/EstateProvider';
import useScreenWidth from '../../../hooks/useScreenWidth';
import { componentSubTypeToPrimaryColorMapping } from '../../../helpers';
import ComponentSubTypeIcon from '../../ComponentSubTypeIcon/ComponentSubTypeIcon';
import colors from '../../../helpers/colors';

export type PieChartComponentGroupType = {
  id: string
  colorFill: string
  componentType: string
  value: number
}

export interface ResponsivePieChartProps {
  defaultColorFill: string
  innerIcon?: boolean,
  innerRadius?: number,
  onActiveChange?: (value: string | undefined) => void,
  sideLength: number,
  testId?: string,
}

export type PieData = {
  id: string;
  colorFill: string;
  componentType: string;
  value: number;
  displayValue: number;
  index: number;
}[]

const formatPieData = (data: GetEstatePieDataQuery | undefined) => {
  const pieData: PieData = []
  const filteredData=data?.getUserEstate?.componentBreakDown?.filter(data => {
    // TODO: filter by component type if our Optimizely toggle is enabled (i.e. data.type!==ComponentType.Debt)
    return data;
  })
  const totalSum = filteredData && filteredData.length > 0 ? parseFloat(filteredData[0].total) : 0;

  // Update Pie Chart and associated variables from the componentBreakDown
  filteredData?.forEach((
    obj: {
      type: string;
      subType: string;
      sum: string;
    },
    index: number,
  ) => {
    // TODO: NEED A DIFFERENT WAY TO GET THE COMPONENT COLOR AND PUSH IT INTO THE PIE DATA
    const componentColor = componentSubTypeToPrimaryColorMapping[obj.subType as ComponentSubType] || colors.baseColors.base2;

    pieData.push({
      id: `${obj.type}-${obj.subType}-${index}`,
      colorFill: componentColor,
      componentType: obj.subType,
      value: parseFloat(obj.sum),
      displayValue: Math.max(parseFloat(obj.sum), totalSum * 0.05),
      index: index,
    });
  });

  return pieData
}

export default function EstatePieChart({
  defaultColorFill,
  innerIcon = true,
  innerRadius,
  onActiveChange,
  sideLength,
  testId,
}: ResponsivePieChartProps) {
  const [active, setActive] = useState<any>(false);
  const [pieData, setPieData] = useState<PieData>([]);
  const [total, setTotal] = useState("");
  const screenWidth = useScreenWidth();
  const halfLength = sideLength / 2;
  const inner = innerRadius || halfLength;

  const { selectedEstateId, setRefetchEstatePieChart } = useContext(EstateContext)

  useEffect(() => {
    onActiveChange && onActiveChange( active ? active.value : undefined);
  }, [active])

  const handleCenterClick = () => {
    // Handle when there are no pieData objects
    if (pieData.length === 0) return;
    if (window.location.pathname === "/home" && screenWidth < 700) return;

    const nextIndex = active ? (pieData.findIndex(({ id }) => id === active.id) + 1) % pieData.length : 0;

    setActive(pieData[nextIndex]);
    if (onActiveChange) onActiveChange(formatWholeDollarAmount(pieData[nextIndex].value));
  };

  // Get data needed for Pie Chart
  const { refetch: refetchEstatePieChart } = useGetEstatePieDataQuery({
    onCompleted: (data) => {
      setPieData(formatPieData(data))
      const componentBreakDown = data.getUserEstate?.componentBreakDown
      if (componentBreakDown) {
        const eTotal = componentBreakDown[0].total
        setTotal(formatWholeDollarAmount(eTotal))
      }
    },
    variables: { estateId: selectedEstateId },
  });

  useEffect(() => {
    setRefetchEstatePieChart(() => refetchEstatePieChart);
  }, [refetchEstatePieChart])

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      const chartElement = document.querySelector('.EstateViewPieChart');
      if (chartElement && !chartElement.contains(e.target)) {
        if (active) {
          setActive(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [active]);

  return (
    <svg
      width={sideLength}
      height={sideLength}
      className="EstateViewPieChart"
      data-testid={testId}
    >
      <Group top={halfLength} left={halfLength}>
        <circle
          r={inner}
          fill="transparent"
          onClick={handleCenterClick}
        />
        <Pie
          data={pieData}
          pieValue={({ displayValue }) => displayValue}
          outerRadius={halfLength}
          innerRadius={inner}
          padAngle={0.02}
        >
          {pie => (
            pie.arcs.map(arc => (
              <g key={arc.data.id}
                onClick={(e) => {
                  e.preventDefault();
                  // if (window.location.pathname === "/home" && screenWidth < 600) return;
                  if (!active || (active && arc.data.id !== active.id)) {
                    setActive(arc.data);
                    // if (onActiveChange) onActiveChange(formatDollarAmount(arc.data.value));
                  } else if (active) {
                    setActive(false);
                    // if (onActiveChange) onActiveChange(undefined);
                  }
                }}
              >
                <path
                  d={pie.path(arc) || undefined}
                  fill={active ? (arc.data.id === active.id ? arc.data.colorFill : "#D8D5CE") : arc.data.colorFill}
                />
              </g>
            ))
          )}
        </Pie>
        <g onClick={handleCenterClick}>
          {
            <>
              {(screenWidth > 800 && (window.location.pathname === "/home" || window.location.pathname === "/manage/home" )) &&
                innerIcon && (
                <>
                  {/* try making this is real svg React component then it might have the right attributes */}
                  {active && <ComponentSubTypeIcon subType={active.subType} bordered={true}/>}
                  {!active && <Text textAnchor="middle" fill={defaultColorFill} dy={-30} className='preText'>
                    {"Estate Value"}
                  </Text>}
                  <Text textAnchor='middle' fill={defaultColorFill} dy={10} className='dollarAmount'>
                    {active ? formatWholeDollarAmount(active.value) : total}
                  </Text>
                  <Text textAnchor="middle" fill={defaultColorFill} dy={40} className={active || 'subText'}>
                    {active ? pascalToNormalCase(active.componentType) : "(estimated)"}
                  </Text>
                </>
              )}

              {(window.location.pathname === "/estate" || window.location.pathname === "/manage/estate") && (
                <>                  
                  { active ? 
                    <ComponentSubTypeIcon subType={active.subType} bordered={true} /> :
                    <Text textAnchor="middle" fill={defaultColorFill} dy={-40}>Estate Value</Text>
                  }
                  <Text textAnchor='middle' fill={defaultColorFill} dy={10} className='dollarAmountEstateView'>
                    {active ? formatWholeDollarAmount(active.value) : total}
                  </Text>
                  <Text textAnchor="middle" fill={defaultColorFill} dy={40} className={active ? '' : 'subText'}>
                    {!active ? "(estimated)" : pascalToNormalCase(active.componentType)}
                  </Text>
                </>
              )}

            </>
          }
        </g>
      </Group>
    </svg>
  )
}
