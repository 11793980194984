import { Autocomplete, Container, TextField, Typography } from '@mui/material'
import { FC, useContext, useState } from 'react'

import { ApolloError } from '@apollo/client';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UserEstateRole, UserType, useGetUsersListQuery, useUpdateOneUserMutation } from '../../../../types/graphql';
import { EstateContext } from '../../../../providers/EstateProvider';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { deceasedFullName } from '../../../../helpers/functions';

import './addExistingUserToEstateForm.scss';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import SelectUser from '../../../SelectUser/SelectUser';

interface AddExistingUserToEstateFormProps {
  testId?: string,
}

const AddExistingUserToEstateForm: FC<AddExistingUserToEstateFormProps> = ({ testId }) => {
  const { setDisplayModal, setModalContents } = useContext(LayoutContext);
  const {
    selectedEstateId, deceased, refetchEstatesList, refetchUsersOnEstate,
  } = useContext(EstateContext);
  const [options, setOptions] = useState<any>({
    "userRole": Object.values(UserEstateRole).map((role: string) => ({
      "submitValue": role,
      "displayValue": `${role[0]}${role.slice(1).toLowerCase()}`, 
    })),
    "userId": [],
  });

  // Optional: queries for dropdown inputs
  const { loading } = useGetUsersListQuery({
    variables: {where: { type: { equals: UserType.Customer } }},
    onCompleted: (data) => {
      setOptions((prevFormOptions: any) => ({
        ...prevFormOptions,
        "userId": data.users.map((user: any) => ({
          "submitValue": user.id,
          "displayValue": [ toFullName(user), user.email, user.address ].filter(val => !!val).join(", "),
        })),
      }));
    },
  });

  const toFullName = (user: any) => [user.firstName, user.lastName].join(" ");

  const [updateOneUserMutation] = useUpdateOneUserMutation();

  const handleSubmit = (
    values: any,
    { resetForm }: { resetForm: any },
  ) => {
    console.log("values: ", values);

    updateOneUserMutation({
      variables: {
        data: {
          estates: {
            create: [
              {
                "userRole": values.userRole,
                "estate": { "connect": { "id": selectedEstateId } },
              },
            ],
          },
        },
        where: { id: values.userId },
      },
      onCompleted: () => {
        refetchEstatesList();
        refetchUsersOnEstate();
        setModalContents(<SelectUser />);
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed updateOneUserMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  };

  const handleCancelClick = () => {
    setModalContents(<SelectUser />);
  };

  const initialValues = {
    userRole: UserEstateRole.Executor,
    userId: '',
  }

  const validationSchema = Yup.object().shape({
    userRole: Yup.string()
      .oneOf(Object.values(UserEstateRole), 'Invalid role')
      .required('User role is required'),
    userId: Yup.string().required('Please select a user'),
  });

  if (loading) return <div>Loading...</div>;

  return (
    <div data-testid={testId} className='addExistingUserToEstateFormContainer'>
      <Typography variant='h4' className='addExistingUserToEstateFormHeader'>
        {`Select a user to connect to ${deceasedFullName(deceased)}'s estate`}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue,
        }) => (
          <Form noValidate>
            <Container className="formContainer">

              {/* Select a User Role */}
              <div className={"inputWrapper userRoleFieldWrapper dropdownInputWrapper"} key={"type0"} data-testid={testId}>
                <Autocomplete
                  options={options.userRole || []}
                  getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                  renderInput={(params: any) =>
                    <Field
                      {...params}
                      className={"userRoleInput fieldInput dropdownInput"}
                      required={false}
                      id={"userRole"}
                      label={"User Role"}
                      name={"userRole"}
                      type={"text"}
                      autoComplete={"userRole"}
                      as={TextField}
                      error={submitCount > 0 && touched.userRole && !!errors.userRole}
                    />
                  }
                  defaultValue={options.userRole.find((option: any) => option.submitValue === initialValues.userRole)}
                  onChange={(_, value) => setFieldValue("userRole", value?.submitValue)}
                />
                <ErrorMessage name={"userRole"} component="div" className="inputErrorMessage" />
              </div>

              {/* Select a user to add to the estate */}
              <div className={"inputWrapper userRoleFieldWrapper dropdownInputWrapper"} key={"id1"} data-testid={testId}>
                <Autocomplete
                  options={options.userId || []}
                  getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                  renderInput={(params: any) =>
                    <Field
                      {...params}
                      className={"idInput fieldInput dropdownInput"}
                      required={false}
                      id={"userId"}
                      label={"User"}
                      name={"userId"}
                      type={"text"}
                      autoComplete={"userId"}
                      as={TextField}
                      error={submitCount > 0 && touched.userRole && !!errors.userRole}
                    />
                  }
                  onChange={(_, value) => setFieldValue("userId", value?.submitValue)}
                />
                <ErrorMessage name={"userId"} component="div" className="inputErrorMessage" />
              </div>
            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                Submit
              </ActionButton>
              <ActionButton ariaLabel={'cancel'} handleClick={handleCancelClick}>
                Cancel
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddExistingUserToEstateForm