import ContentExplorer from 'box-ui-elements/es/elements/content-explorer';
import 'box-ui-elements/dist/explorer.css';
import { useAuthenticateBoxUserMutation } from '../../../types/graphql';
import { useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingIndicator/LoadingIndicator';

import { IntlProvider } from "react-intl";
import { EstateContext } from '../../../providers/EstateProvider';


const BoxContentExplorer = () => {
  const { currentEstate } = useContext(EstateContext);
  const [accessToken, setAccessToken] = useState<string>();
  const [boxUserFolderId, setBoxUserFolderID] = useState<string>();

  const [authenticateBoxUser, { loading }] = useAuthenticateBoxUserMutation({
    variables: { estateId: currentEstate?.getUserEstate?.id || '' },
    onCompleted: (data) => {
      console.log("authenticateBoxUser", data);
      setAccessToken(data.authenticateBoxUser.accessToken);
      setBoxUserFolderID(data.authenticateBoxUser.rootFolderId);
    },
    onError: (error) => {
      console.error("authenticateBoxUser", error);
    },
  });

  useEffect(() => {
    console.log("BoxContentExplorer useEffect");
    authenticateBoxUser();
  }, []);

  const boxExplorerConfig = {
    contentPreviewProps: {
      contentSidebarProps: {
        detailsSidebarProps: {
          hasProperties: false,
          hasNotices: false,
          hasAccessStats: false,
          hasClassification: false,
          hasRetentionPolicy: false,
        },
        hasActivityFeed: false,
        hasMetadata: false,
        hasSkills: false,
        hasVersions: false,
      },
    },
    rootFolderId: boxUserFolderId,
    token: accessToken,
    logoUrl: '',
    canShare: false,
  }

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f3f3f3",
    }}>
      <IntlProvider locale="en">
        {!accessToken || loading ? <LoadingSpinner /> : <ContentExplorer {...boxExplorerConfig} />}
      </IntlProvider>
    </div>
  )
}

export default BoxContentExplorer