import { FC, useContext } from 'react'

import { MobileLayout } from '../../../components/Layouts'
import DocumentsList from '../DocumentsList.tsx/DocumentsList'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'
import styles from './Mobile.module.scss'
import { useDecision } from '@optimizely/react-sdk'
import BoxContentExplorer from './BoxContentExplorer'

const Mobile: FC = () => {
  const [useContentExplorer] = useDecision('use-content-explorer');

  return (
    <MobileLayout
      includeSubHeader={false}
      altTitleText='Documents'
      includeCareTeamCard={false}
      includeFooter={false}
    >
      {useContentExplorer.enabled ? (
        <BoxContentExplorer />
      ) : (
        <>
          <div className={styles.documentsHeaderContainer}>
            <Typography variant='H3' font="UI2" className={styles.documentsHeader}>Document Vault</Typography>
          </div><DocumentsList />
        </>
      )}
    </MobileLayout>
  )
}

export default Mobile