import { FC, useEffect, useState } from 'react'
import './index.scss'
import { Typography } from '@mui/material'
import { ActionCardStatus, ComponentStatus, TaskStatus } from '../../types/graphql'
import useStatus from '../../hooks/useStatus'

interface StatusTagProps {
  testId?: string,
  status: TaskStatus | ActionCardStatus | ComponentStatus | undefined | null,
  admin?: boolean
}

const StatusTag: FC<StatusTagProps> = ({
  testId,
  status,
  admin=false,
}) => {
  const { statusClass, statusMsgShort } = useStatus(status)
  

  return (
    <div data-testid={testId} className={`statusTagContainer ${statusClass}`}>
      <Typography variant='body1' className={`statusMsgShort ${statusClass}`}>
        {statusMsgShort}
      </Typography>
    </div>
  )
}

export default StatusTag