import { FC, useContext } from 'react';

import Footer from '../Footer/Footer';
import { useNoDecedantInfoRedirect } from '../../hooks/useNoDecedantInfoRedirect';
import { LayoutContext } from '../../providers/LayoutProvider';
import Modal from '../Modal/Modal';
import CareTeamCard from '../CareTeamCard/CareTeamCard';
import { BackNavHeader, Header, SubHeader } from '../Headers';
import { CustomerNav } from '../PageNav';

import './MobileLayout.scss'
import { AccountContext } from '../../providers/Authentication';
import { EstateContext } from '../../providers/EstateProvider';
import { useMarkNotificationsSeenMutation } from '../../types/graphql';

interface MobileLayoutProps {
  altTitleText?: string;
  children: JSX.Element | JSX.Element[];
  includeCareTeamCard?: boolean;
  includeFooter?: boolean;
  includeHeader?: boolean;
  includeBackNavHeader?: boolean;
  includeSideNav?: boolean;
  includeSubHeader?: boolean;
  includeMainNav?: boolean;
  fixedHeader?: boolean;
  subHeaderText?: string;
  headerText?: string;
}

const MobileLayout: FC<MobileLayoutProps> = ({
  altTitleText,
  children,
  includeCareTeamCard=true,
  includeFooter=true,
  includeHeader=true,
  includeBackNavHeader=false,
  includeSideNav=true,
  includeSubHeader=true,
  includeMainNav=true,
  fixedHeader=false,
  subHeaderText,
  headerText,
}) => {
  useNoDecedantInfoRedirect();

  const { hasAdminAuthorization, userId } = useContext(AccountContext);
  const { selectedEstateId } = useContext(EstateContext);
  const { isLoading, notificationIds } = useContext(LayoutContext);
  // pull the current url from the window object
  const currentUrl = window.location.href;
  const [markNotificationAsSeen]  = useMarkNotificationsSeenMutation(
    {
      variables: {
        notificationIds: notificationIds,
        userId: userId,
      },
      // onCompleted: (data) => {
      //   refetch()
      // },
      onError: (error) => {
        console.error('mark as read mutation error', error)
      },
    },
  )

  return (
    <div id="mobilePageLayout">
      {includeHeader && !includeBackNavHeader && <Header fixed={fixedHeader}/>}
      {includeHeader && includeBackNavHeader && <BackNavHeader fixed={fixedHeader} headerText={headerText}/>}
      <div className="mobileContent">
        {includeSubHeader && (!hasAdminAuthorization || (hasAdminAuthorization && selectedEstateId) ) && (
          <SubHeader 
            text={subHeaderText} 
            altTitleText={altTitleText}
          />
        )}
        {children}
        <Modal />
      </div>
      {includeCareTeamCard && <CareTeamCard />}
      {includeFooter && <Footer />}
      {includeMainNav && <CustomerNav />}
    </div>
  )
}

export default MobileLayout;