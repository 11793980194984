import { FC, useContext, useEffect } from 'react'
import './EstateComponentDetailsManagement.scss'
import ManagementLayout from '../../../components/ManagementLayout/ManagementLayout'
import { UserLayout } from '../../../components/Layouts'
import ComponentDetailCardList from '../../../components/DesignSystem/molecules/ComponentDetailCardList/ComponentDetailCardList'
import { useParams } from 'react-router-dom-v5-compat'
import CreateOneContentForm from '../../../components/FormikForms/admin/CreateOneContentForm/CreateOneContentForm'
import { AccountContext } from '../../../providers/Authentication'
import EstateComponentSummary from '../../../components/DesignSystem/molecules/EstateComponentSummary/EstateComponentSummary'
import { useRoleNavigate } from '../../../hooks/useRoleNavigate'

interface EstateComponentDetailsManagementProps {
  testId?: string
}

const EstateComponentDetailsManagement: FC<EstateComponentDetailsManagementProps> = ({testId}) => {
  const {estateComponentId} = useParams();
  const { showAdminTools } = useContext(AccountContext)
  const navigate = useRoleNavigate()

  useEffect(() => {
    if(!showAdminTools) {
      navigate(`/estateComponentDetails/${estateComponentId}`)
    }

  }, [showAdminTools])

  return (
    <UserLayout 
      data-testid={testId} 
      className='estateComponentDetailsManagementContainer'
      includeSubHeader={false}
    >
      <ManagementLayout
        customerView={
          <div className='estateComponentDetailsCustomerView'>
            <EstateComponentSummary estateComponentId={estateComponentId || ''} />
            <ComponentDetailCardList estateComponentId={estateComponentId || ''} />
          </div>
        }
        createForm={<CreateOneContentForm />}
        buttonText='Add Detail Card'
      />
    </UserLayout>
  )
}

export default EstateComponentDetailsManagement
