import { FC } from 'react';
import './Design.scss';
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import ContentCard from '../../components/DesignSystem/molecules/ContentCard/ContentCard';
import { ComponentSubType, ContentCompleteByType, ContentStatus } from '../../types/graphql'

// Icons
import ComponentSubTypeIcon from '../../components/ComponentSubTypeIcon/ComponentSubTypeIcon';
import RecentActivityCard from '../../components/DesignSystem/molecules/RecentActivityCard/RecentActivityCard';
import MessageCard from '../../components/DesignSystem/molecules/MessageCard/MessageCard';
import KeyDateCard from '../../components/DesignSystem/molecules/KeyDateCard/KeyDateCard';
import NotificationManagementCard from '../../components/DesignSystem/molecules/NotificationManagementCard/NotificationManagementCard';
import NotificationItem from '../../components/DesignSystem/molecules/NotificationItem/NotificationItem';

const DesignView: FC = () => {
  return (
    <div className='designSystemContainer'>
      {/* Include all the Header UI1 Components that we have developed */}
      <div className='typography'>
        <Typography variant="H2" font="UI2">Headers - UI1</Typography>
        <Typography variant="H1" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H2" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H3" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H4" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H5" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H6" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="L1" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="L2" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="L3" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="P1" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="P2" font="UI1">The spectacle before us was indeed sublime</Typography>
        <Typography variant="P3" font="UI1">The spectacle before us was indeed sublime</Typography>
        <hr />

        {/* Include all the Header UI2 Components that we have developed */}
        <Typography variant="H2" font="UI2">Headers - UI2</Typography>
        <Typography variant="H1" font="UI2">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H2" font="UI2">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H3" font="UI2">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H4" font="UI2">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H5" font="UI2">The spectacle before us was indeed sublime</Typography>
        <Typography variant="H6" font="UI2">The spectacle before us was indeed sublime</Typography>
      </div>
      <hr />

      <Typography variant="H1" font="UI2">Component Subtype Icons</Typography>
      <ComponentSubTypeIcon subType={ComponentSubType.BankAccount} />
      <ComponentSubTypeIcon subType={ComponentSubType.BillToCancel} />
      <ComponentSubTypeIcon subType={ComponentSubType.Debt} />
      <ComponentSubTypeIcon subType={ComponentSubType.Discovery} />
      <ComponentSubTypeIcon subType={ComponentSubType.Distribution} />
      <ComponentSubTypeIcon subType={ComponentSubType.Investment} />
      <ComponentSubTypeIcon subType={ComponentSubType.LifeInsurance} />
      <ComponentSubTypeIcon subType={ComponentSubType.PersonalProperty} />
      <ComponentSubTypeIcon subType={ComponentSubType.Probate} />
      <ComponentSubTypeIcon subType={ComponentSubType.RealEstate} />
      <ComponentSubTypeIcon subType={ComponentSubType.Retirement} />
      <ComponentSubTypeIcon subType={ComponentSubType.Tax} />
      <ComponentSubTypeIcon subType={ComponentSubType.UnclaimedProperty} />
      <ComponentSubTypeIcon subType={ComponentSubType.Vehicle} />

      {/* Include all the Paragraph UI1 Components that we have developed */}

      {/* Card Components */}
      <div className='cardsContainer'>
        <Typography variant="H2" font="UI2">Cards</Typography>

        <div className='cards'> 
          <Typography variant="H5" font="UI1">Content Card</Typography>
          <ContentCard 
            contentId='1'
            status={ContentStatus.InProcess}
            title='Card title. The title should describe the card briefly'
            body='Card description. This description is the expanded view and will be multiple lines. This can be one line or it can be several lines'
            date='May 3, 2023'
          />

          <Typography variant="H5" font="UI1">Recent Activity Card</Typography>
          <RecentActivityCard 
            testId='recentActivityCard' 
            contentId='testing1234'
            componentId='testing1234'
            title='Recent Activity Card Title'
            body='Recent Activity Card Description. This description is the expanded view and will be multiple lines. This can be one line or it can be several lines'
          />

          <Typography variant="H5" font="UI1">Key Date Card</Typography>
          <KeyDateCard 
            testId='keyDateCard' 
            contentId='testing1234'
            componentId='testing1234'
            displayDate='Mid 2024' 
            title="Longer Key Date Title to test word wrap on two lines."
            completeByType={ContentCompleteByType.Fuzzy}
          />

          <Typography variant="H5" font="UI1">Message Card</Typography>
          <MessageCard 
            message='No data available'
          />

          <Typography variant="H5" font="UI1">Notification Management Card</Typography>
          <NotificationManagementCard 
            testId='notificationManagementCard'
            title='Notification Message'
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            route={null}
            routingError={null}
            estateId='1'
            estateNotificationId='1'
            users={['User 1', 'User 2', 'User 3']}
            createdAt='May 3, 2023'
            refetchNotifications={async () => console.log('refetch')}
          />

          <Typography variant="H5" font="UI1">Notification Item</Typography>
          <NotificationItem 
            testId='notificationItem' 
            id='1'
            title='Notification Message' 
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." 
            unread={true}
            createdAt='2021-09-01T12:00:00Z'
            refetch={async () => console.log('refetch')}
            route=''
            routingError={null}
          />
        </div>
      </div>
    </div>
  );
}

export default DesignView;