import { FC } from 'react'
import './FileTypeIcon.scss'
import { ReactComponent as DefaultFileIcon } from '../../../assets/icons/fileTypes/default-file.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/fileTypes/pdf.svg';
import { ReactComponent as WordIcon } from '../../../assets/icons/fileTypes/word.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/fileTypes/image.svg';
import { ReactComponent as MovieIcon } from '../../../assets/icons/fileTypes/movie.svg';

interface FileTypeIconProps {
  testId?: string
  fileType: string
}

const getFileTypeIcon = (fileType: string) => {
  const subtype = fileType.split('/')[1];

  switch (subtype) {
  case 'pdf':
    return <PdfIcon />;
  case 'msword':
  case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
    return <WordIcon />;
  case 'png':
  case 'jpg':
  case 'jpeg':
  case 'gif':
    return <ImageIcon />;
  case 'mp4':
  case 'avi':
  case 'mov':
  case 'quicktime':
    return <MovieIcon />;
  default:
    return <DefaultFileIcon />;
  }
};

const FileTypeIcon: FC<FileTypeIconProps> = ({
  testId,
  fileType,
}) => {

  return (
    <div data-testid={testId} className='fileTypeIconContainer'>
      {getFileTypeIcon(fileType)}
    </div>
  )
}

export default FileTypeIcon