import { FC, useContext } from "react";
import { AccountContext } from "../../../providers/Authentication";
import ActionButton from "../../../components/DesignSystem/atoms/ActionButton/ActionButton";
import { EstateComponentContext } from "../../../providers/EstateComponentProvider";
import { LayoutContext } from "../../../providers/LayoutProvider";
import UpdateOneComponentForm from "../../../components/FormikForms/admin/UpdateOneComponentForm/UpdateOneComponentForm";
import './estateComponentCard.scss';
import DeleteOneComponentForm from "../../../components/FormikForms/admin/DeleteOneComponentForm/DeleteOneComponentForm";
import { EstateComponent } from "..";
import Typography from "../../../components/DesignSystem/atoms/Typography/Typography";
import { formatDollarAmount } from "../../../helpers/functions";
import StatusIcon from "../../../components/StatusIcon/StatusIcon";
import useStatus from "../../../hooks/useStatus";
import { useDecision } from "@optimizely/react-sdk";
import LinkButton from "../../../components/DesignSystem/atoms/LinkButton/LinkButton";
import { ReactComponent as ArrowRight } from "../../../assets/icons/navigation/chevron-right.svg";
import ComponentCardTitle from "./ComponentCardTitle/ComponentCardTitle";
import { useRoleNavigate } from "../../../hooks/useRoleNavigate";
import { SortOrder, useGetEstateComponentQuery } from "../../../types/graphql";

interface EstateComponentCardProps {
  component: EstateComponent
  index: number
  testId?: string
}

const EstateComponentCard: FC<EstateComponentCardProps> = ({
  component,
  index,
  testId,
}) => {
  const {
    name, id, status, estimatedValue, finalValue, 
  } = component
  const navigation = useRoleNavigate();
  const { hasAdminAuthorization, showAdminTools } = useContext(AccountContext);
  const { setCurrentEstateComponent } = useContext(EstateComponentContext);
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const [ estateComponentDetailsFlag ] = useDecision('estate_component_details_page', {autoUpdate: true}); 

  const {data: estateData} = useGetEstateComponentQuery({
    variables: {
      componentId: component.id,
      orderBy: [
        {completeBy: SortOrder.Asc},
      ],
    },
    onError: (error) => {
      console.error(error)
      alert(error)
    },
  });

  const hasContent = estateData?.getEstateComponent && estateData?.getEstateComponent?.contents?.length > 0;

  const { statusMsgLong, statusClass } = useStatus(status)

  const handleDeleteClick = () => {
    setCurrentEstateComponent(component);
    setModalContents(<DeleteOneComponentForm />);
    setDisplayModal(true);
  }
  const handleUpdateClick = () => {
    setCurrentEstateComponent(component);
    setModalContents(<UpdateOneComponentForm />);
    setDisplayModal(true);
  }
  const handleNavigateClick = () => {
    if((!hasContent && !hasAdminAuthorization && !showAdminTools)|| !estateComponentDetailsFlag.enabled ) return;
    setCurrentEstateComponent(component);

    const route = `/estateComponentDetails/${component.id}`;
    navigation(route);
  }

  return (
    <div className="estateComponentCardContainer" key={`${name}-${id}-${index}`} data-testid={testId || `${name}-${id}-${index}`}>
      <div 
        className="estateComponentCard" 
      >
        {status !== "Pending" && (<StatusIcon status={status} className="componentCardStatusIcon"/>) }
        <LinkButton 
          className={`componentCardLink transparent ${status}`} 
          variant={"solid"} 
          ariaLabel={"Navigate to Estate Component Details Page"}
          handleClick={handleNavigateClick}
        >
          <div className="estateComponentCardContent">
            {/* TODO: Replace these with customer typography components */}
            <ComponentCardTitle component={component}/>

            { (finalValue || estimatedValue) && (
              <span className="statusMsgBlock">
                <Typography variant="H5" font="UI1" className="valueMsg">{formatDollarAmount(parseFloat(finalValue || estimatedValue))}</Typography>
                {!finalValue && <Typography variant="P1" font="UI1" className="valueMsg estimatedValue">{`(estimated)`}</Typography>}
              </span>
            )}

            <Typography variant="P3" font="UI1" className={`statusMsg ${statusClass} ${!finalValue && !estimatedValue ? 'includeMarginTop' : ''}`}>{statusMsgLong}</Typography>
          </div>
          <>
            {(hasContent || (hasAdminAuthorization && showAdminTools))&& estateComponentDetailsFlag?.enabled && 
          <div className="estateComponentCardLinkIcon">
            <ArrowRight />
          </div>}
          </>
        </LinkButton>
      </div>
      {hasAdminAuthorization && showAdminTools && (
        <div className="adminTools">
          {/* <Typography variant='P1' font="UI1" className='adminCardStatus'>
            <StatusTag admin={true} status={component.status || ''}/>
          </Typography> */}
          <div className="adminButtons">
            <ActionButton
              variant={'solid'}
              ariaLabel="Update Estate Component Button"
              handleClick={handleUpdateClick}
            >Update</ActionButton>
            <ActionButton
              variant={'solid'}
              ariaLabel="Delete Estate Component Button"
              handleClick={handleDeleteClick}
            >Delete</ActionButton>
          </div>
        </div>

      )}
    </div>
  )
}

export default EstateComponentCard