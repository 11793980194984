import { FC } from 'react';
import { ReactComponent as CloseSVGIcon } from '../../../../assets/icons/functionalityIcons/close.svg';
import styles from './CloseIcon.module.scss';

interface CloseIconProps {
  className?: string;
}

const CloseIcon: FC<CloseIconProps> = ({className}) => (
  <div className={styles.CloseIconContainer}>
    <CloseSVGIcon className={className || ''} />
  </div>
);

export default CloseIcon;