import { FC, useContext, useEffect, useState } from 'react'
import styles from './RecentActivityList.module.scss'
import List from '../../atoms/List/List'
import RecentActivityCard from '../RecentActivityCard/RecentActivityCard'
import { useGetRecentActivityLazyQuery } from '../../../../types/graphql'
import { EstateContext } from '../../../../providers/EstateProvider'
import MessageCard from '../MessageCard/MessageCard'
import Typography from '../../atoms/Typography/Typography'

interface RecentActivityListProps {
  testId?: string
  id?: string,
  className?: string
  wrap?: boolean
  gap?: number
  listItemWidth?: number
  listItemMaxWidth?: string
  flex?: string
  data?: any[]
}

const RecentActivityList: FC<RecentActivityListProps> = ({
  testId,
  id,
  className, 
  wrap=true, 
  gap=20, 
  listItemWidth=387, 
  listItemMaxWidth, 
  flex,
  data,
}) => {
  const { selectedEstateId } = useContext(EstateContext)
  const [recentActivity, setRecentActivity] = useState<any[]>([])

  // useRecentActivityListQuery
  const [getRecentActivity] = useGetRecentActivityLazyQuery(
    {
      variables: {estateId: selectedEstateId},
      onCompleted: (data) => {
        setRecentActivity(data.getUserEstate?.recentActivity || [])
      },
    },
  )

  useEffect(() => {
    if(!data) getRecentActivity()
    else setRecentActivity(data)
  }, [data])


  return (
    <div data-testid={testId} id={id} className={`${styles.recentActivityListContainer} ${recentActivity && styles.emptyState} ${className}`}>
      {
        recentActivity.length === 0 ? 
          <></>
          :
          <>
            <Typography font="UI1" variant="H5" className={styles.recentActivityHeader}>Recent Activity</Typography>
            <List
              testId='recentActivityList'
              data={recentActivity}
              direction='horizontal'
              listItemWidth={listItemWidth}
              listItemMaxWidth={listItemMaxWidth}
              flex={flex}
              gap={gap}
              wrap={wrap}
              renderItem={(item: any, index: number) => (
                <RecentActivityCard
                  testId={`recentActivityCard-${index}`}
                  key={index}
                  contentId={item.id}
                  componentId={item.componentId}
                  title={item.header}
                  body={item.body}
                  date={item.displayDate}
                  status={item.status}
                  user={item.user} />
              )} /></>
      } 
    </div>
  )
}

export default RecentActivityList
