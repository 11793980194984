import { FC, useContext } from 'react'
import './ScheduleAppointment.scss'
import { Typography } from '@mui/material'
import CalendarButton from "../../../components/CalendarButton/CalendarButton";
import { Launch } from '@mui/icons-material';
import { ActionContext } from '../../../providers/ActionProvider';
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton';
import LinkButton from '../../../components/DesignSystem/atoms/LinkButton/LinkButton';
import { Helmet } from 'react-helmet'; // helmet provides a way to add scripts to the head of the page dynamically and safely
import { LayoutContext } from '../../../providers/LayoutProvider';


interface ScheduleAppointmentProps {
  testId?: string
  currentAction: any
}


const ScheduleAppointment: FC<ScheduleAppointmentProps> = ({testId, currentAction}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext);
  
  const handleClick = () => {
    setModalContents(<>
      <div className="meetings-iframe-container" data-src={`${currentAction?.calendarLink }?embed=true`}></div>
      <Helmet>
        <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
      </Helmet>
    </>)
    setDisplayModal(true)
  }
  return (
    <>
      <div className='actionDetailsButtonContainer'>
        <ActionButton
          variant="solid"
          ariaLabel="link to calendar integration"
          className="calendarLinkButton actionDetailsButton"
          handleClick={handleClick}
        >
          <Typography>Schedule Appointment</Typography>
        </ActionButton>
      </div>
    </>
  )
}

export default ScheduleAppointment