import { FC } from 'react'
import './Footer.scss'
import { Card, Typography } from '@mui/material'
import logoMark from '../../assets/images/Logomark.svg'
import { ReactComponent as AlixBrandIcon } from '../../assets/icons/logos/alix-icon.svg'

interface FooterProps {
  testId?: string
}

const Footer: FC<FooterProps> = ({testId}) => {
  return (
    <div data-testid={testId} className='footerContainer'>
      <Card className='footerCard'>
        <div className='header'>
          <Typography variant="h2">ALWAYS</Typography>
          <Typography variant="h2">AT YOUR</Typography>
          <Typography variant="h2">SIDE</Typography>
        </div>
        {/* <img src={logoMark} alt="ALIX Logomark" />
         */}
        <AlixBrandIcon className='footerBrandIconSvg'/>
      </Card>
    </div>
  )
}

export default Footer