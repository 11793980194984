import { FC, useContext, useState } from 'react'
import './ManagementTools.scss'
import Typography from '../DesignSystem/atoms/Typography/Typography'
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton'
import { ManagementContext } from '../../providers/ManagementProvider'
import { AccountContext } from '../../providers/Authentication'

interface ManagementToolsProps {
  testId?: string
  editForm?: JSX.Element
  deleteForm?: JSX.Element
  createNotificationForm?: JSX.Element
  setSelected?: () => void
}

const ManagementTools: FC<ManagementToolsProps> = ({
  testId,
  editForm,
  deleteForm,
  createNotificationForm,
  setSelected,
}) => {
  const { setFormToDisplay } = useContext(ManagementContext)
  const { showAdminTools } = useContext(AccountContext)

  return (
    <div data-testid={testId} className={`managementToolsContainer ${showAdminTools? 'display' : 'hidden'}`}>
      <ActionButton 
        ariaLabel='Show Edit Form' 
        variant='solid'
        className='light'
        handleClick={() => {
          if (setSelected) setSelected()
          setFormToDisplay(editForm)
        }}
      >
        <Typography variant='P1' font='UI1'>Edit</Typography>
      </ActionButton>
      <ActionButton 
        ariaLabel='Show Delete Form' 
        variant='solid'
        className='dark'
        handleClick={() => {
          if (setSelected) setSelected()
          setFormToDisplay(deleteForm)
        }}
      >
        <Typography variant='P1' font='UI1'>Delete</Typography>
      </ActionButton>
      <ActionButton
        ariaLabel='Show Create Notification Form'
        variant='solid'
        className='light'
        handleClick={() => {
          if (setSelected) setSelected()
          setFormToDisplay(createNotificationForm)
        }}
      >
        <Typography variant='P1' font='UI1'>Create Notification</Typography>
      </ActionButton>

    </div>
  )
}

export default ManagementTools
