import { FC, useContext, useState } from 'react'

import styles from './NotificationsPopupMenu.module.scss'
import { UserNotification } from '../../../../types/AppTypes';
import { useGetUserEstateNotificationsQuery, useMarkNotificationsSeenMutation } from '../../../../types/graphql'
import Typography from '../../atoms/Typography/Typography';
import CloseIconAsActionButton from '../CloseIconAsActionButton/CloseIconAsActionButton';
import NotificationItemList from '../NotificationItemList/NotificationItemList';
import { AccountContext } from '../../../../providers/Authentication';
import NotificationBell from '../../atoms/NotificationBell/NotificationBell';

interface NotificationsPopupMenuProps {
  showNotificationsPopup: boolean
  setShowNotificationsPopup: (show: boolean) => void
  refetchNotificationsCount: () => void
}

const NotificationsPopupMenu: FC<NotificationsPopupMenuProps> = ({
  showNotificationsPopup,
  setShowNotificationsPopup,
  refetchNotificationsCount,
}) => {
  const [notifications, setNotifications] = useState<UserNotification[]>([])

  const { refetch } = useGetUserEstateNotificationsQuery({
    // Need to add the option to sort by seen, then unseen, and by created at descending
    onCompleted: (data) => {
      data.getUserEstateNotifications && setNotifications(data.getUserEstateNotifications)
    },
  });

  const getUserNotifications = async () => {
    const response = await refetch()
    response.data.getUserEstateNotifications && setNotifications(response.data.getUserEstateNotifications)
    refetchNotificationsCount()
  }

  const handleClose = () => {
    setShowNotificationsPopup(false);
    refetchNotificationsCount()
  };

  if (showNotificationsPopup && notifications.length === 0) return (
    <div className={styles.emptyStateContainer}>
      <div className={styles.notificationsHeader}>
        <Typography font='UI1' variant="H4" fontWeight='medium' >Notifications</Typography>
        <CloseIconAsActionButton clickHandler={handleClose} />
      </div>
      <NotificationBell className={styles.emptyStateBell} width={45} height={45} />
      <Typography font="UI1" variant="H5" fontWeight='regular' className={styles.emptyStateHeaderText}>No notifications just yet</Typography>
      <Typography font="UI1" variant="P2" fontWeight="regular">{`When an update is ready, you'll see it here. Alix is on it!`}</Typography>
    </div>
  )

  if (showNotificationsPopup) return (
    <div className={styles.notificationsPopupMenuContainer}>
      <div className={styles.notificationsHeader}>
        <Typography font='UI1' variant="H4" fontWeight='medium' >Notifications</Typography>
        <CloseIconAsActionButton clickHandler={handleClose} />
      </div>
      <NotificationItemList
        containerClassName={styles.notificationsItemListStylesOverride}
        notifications={notifications}
        refetch={getUserNotifications}
      />
    </div>
  )
}

export default NotificationsPopupMenu