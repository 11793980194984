import { FC } from 'react'
import styles from './NotificationManagementList.module.scss'
import List from '../../atoms/List/List'
import NotificationManagementCard from '../NotificationManagementCard/NotificationManagementCard'
import { Maybe, NotificationLogEntry, NotificationReceipt, NotificationUser } from '../../../../types/graphql'

interface NotificationManagementListProps {
  testId?: string
  notifications: NotificationLogEntry[],
  refetchNotifications: () => void
}

const NotificationManagementList: FC<NotificationManagementListProps> = ({
  testId,
  notifications,
  refetchNotifications,
}) => {

  const userNames = (receipts?: Maybe<NotificationReceipt[]>) => {
    return receipts?.map((receipt: NotificationReceipt) => `${receipt.user?.firstName} ${receipt.user?.lastName}`)
  }

  return (
    <div data-testid={testId} className={styles.notificationManagementListContainer}>
      <List
        testId='notificationManagementList'
        data={notifications}
        direction='vertical'
        listItemWidth='auto'
        listItemMaxWidth='auto'
        flex='1'
        gap={24}
        wrap={false}
        renderItem={(item: NotificationLogEntry, index: number) => (
          <NotificationManagementCard
            testId={`notificationManagementCard-${index}`}
            key={`${index}:${item.id}`}
            title={item.title}
            message={item.message}
            route={item.route}
            routingError={item.routingError}
            estateId={item.estateId}
            estateNotificationId={item.id}
            users={userNames(item.notificationReceipts)}
            createdAt={item.createdAt}
            refetchNotifications={refetchNotifications}
          />
        )}
      />
    </div>
  )
}

export default NotificationManagementList
