// import { Inter, Oswald } from 'next/font/google'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { PaletteMode } from '@mui/material';

// export const inter = Inter({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// })

// export const oswald = Oswald({
//   weight: ['400', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// })

// Create a theme instance.
const buildTheme = (mode: PaletteMode) => {
  const theme = createTheme({
    palette: {
      mode,
      contrastThreshold: 4.5,
      // ...(mode === 'light'
      //   ? {
      primary: {
        main: '#AB826F',
        light: '#E4DAC4',
        dark: '#312B2B',
        contrastText: "#fff",
      },
      secondary: {main: '#AB826F'},
      success: {main: '#AB826F'},
      text: {
        primary: '#312B2B',
        secondary: '#E4DAC4',
      },
    },
    // : {
    //   primary: {
    //     main: '#0061B0',
    //     light: '#548ee2',
    //     dark: '#003880',
    //   },
    //   secondary: {
    //     main: '#838487',
    //     light: '#b3b4b7',
    //     dark: '#56575a',
    //   },
    //   success: {
    //     main: '#21904C',
    //   }
    // }
    // )}
  });

  return responsiveFontSizes(theme);
}

export default buildTheme;