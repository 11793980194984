import ContentExplorer from "../../components/ContentExplorer/ContentExplorer";

const ContentExplorerRoute = () => {
  
  const accessToken  = window.localStorage.getItem('accessToken') || "false";

  const rootFolderId  = window.localStorage.getItem('rootFolderId') || "false";

  return (    
    <ContentExplorer token={accessToken} rootFolderId={rootFolderId} />
  )
}

export { ContentExplorerRoute }