import { FC, useContext } from 'react'
import './index.scss'
import { Typography } from '@mui/material'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../../providers/LayoutProvider'
import { useDeleteUserNotificationsMutation } from '../../../../types/graphql'

interface DeleteEstateNotificationFormProps {
  testId?: string,
  estateId: string,
  estateNotificationId: string,
  refetchNotifications: () => void
}

const DeleteEstateNotificationForm: FC<DeleteEstateNotificationFormProps> = ({
  testId, estateId, estateNotificationId, refetchNotifications,
}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const [ deleteEstateNotificationMutation ] = useDeleteUserNotificationsMutation({
    variables: {
      data: {
        estateId: estateId,
        estateNotificationId: estateNotificationId, 
      },
    },
   
    onCompleted: () => {
      // refresh notification list contents
      refetchNotifications()
    },
    onError: (error) => {
      // handle errors as desired
      alert(error)
    },
  })

  const handleDeleteClick = () => {
    deleteEstateNotificationMutation()
    
    // close the popup and clear the state
    setDisplayModal(false)
    setModalContents(<></>)
  }

  const handleCancelClick = () => {
    // close the popup and clear the state
    setDisplayModal(false)
    setModalContents(<></>)
  }

  return (
    <div data-testid={testId} className='deleteEstateNotificationFormContainer'>
      <Typography variant='h2'>
        Are you sure you would like to delete this notification for all users who have received it? Deletion is permanent and cannot be undone. 
      </Typography>
      <div className='actionButtonContainer'>
        <ActionButton ariaLabel={'delete the selected notification for all users'} variant='solid' handleClick={handleDeleteClick}>
          Delete
        </ActionButton>
        <ActionButton ariaLabel={'cancel delete'} handleClick={handleCancelClick}>
          Cancel
        </ActionButton>
      </div>
    </div>
  )
}

export default DeleteEstateNotificationForm