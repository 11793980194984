import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './FormFields.scss';
import ActionButton from "../../../components/DesignSystem/atoms/ActionButton/ActionButton";
import { ActionCardStatus, useGetActionCardQuery, useStartActionCardMutation } from "../../../types/graphql";
import { EstateContext } from "../../../providers/EstateProvider";
import LinkButton from "../../../components/DesignSystem/atoms/LinkButton/LinkButton";
import { Launch } from "@mui/icons-material";
import { AccountContext } from "../../../providers/Authentication";
import { LayoutContext } from "../../../providers/LayoutProvider";

interface FormFieldsProps {
  testId?: string
  currentAction: any
  refetchCurrentAction: () => void
}

const FormFields: FC<FormFieldsProps> = ({
  testId, currentAction, refetchCurrentAction,
}) => {
  const {selectedEstateId} = useContext(EstateContext)
  const {hasAdminAuthorization} = useContext(AccountContext)
  const {setFlag} = useContext(LayoutContext)
  const [ input, setInput ] = useState( currentAction?.estateDetail?.value || '')
  const [ submitted, setSubmitted ] = useState<boolean>( false )

  const [startActionCardMutation] = useStartActionCardMutation({
    onCompleted: () => {
      refetchCurrentAction()
    },
    onError: (error) => {
      alert(error)
    },
  });

  useGetActionCardQuery({
    skip: !hasAdminAuthorization,
    onCompleted: (data) => {
      setInput(data.actionCard?.estateDetail?.decryptedValue)
    },
    onError: (error) => {
      console.log('error', error);
      alert(error)
    },
    variables: {where: {id: currentAction?.id}},
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFlag({
      title: 'Information sent, thank you!',
      message: "Once the Alix Care Team has confirmed everything is done, we'll archive this action.",
      visible:true,
      type: 'success',
    })
    setSubmitted(true)
    if(currentAction){

      // console.log({input});
      startActionCardMutation({
        variables: {
          startActionCardId: currentAction?.id,// value for 'startActionCardId'
          reply: input,
          estateId: selectedEstateId,
        },
      })
      
    }
  }

  return (
    <div className="formFieldsContainer" data-testid={testId}>
      {/* success box here */}

      { currentAction?.formFieldName && 
        currentAction?.encryptedReply &&
        currentAction.status === ActionCardStatus.New &&
        <>
          <Typography variant='body1'>
          Type below to securely send us the information.
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate className="formContainer">
            <FormControl required variant="outlined" className="formFieldsInput">
              <InputLabel htmlFor="formFieldsActionInput">SECURE INPUT</InputLabel>
              <OutlinedInput 
                id="formFieldsActionInput"
                type="text"
                value={input}
                onChange={(event) => (setInput(event.target.value))}
                endAdornment= {
                  <InputAdornment position="end">
                    <LockOutlinedIcon />
                  </InputAdornment>
                }
                label="SECURE INPUT"
                multiline={true}
                rows={3}
              />
            </FormControl>

            { !submitted &&
              <ActionButton 
                variant="solid"
                ariaLabel="submit form"
                type="submit"
                className="actionDetailsButton dark"
              >
              Send information
              </ActionButton>}
          </Box>
        </>
      }
      { currentAction?.formFieldName && 
        !currentAction?.encryptedReply &&
        <>
          <div className="actionDetailsDivider"></div>
          <Typography variant='body1'>
          Type below to send us the information.
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate className="formContainer">
            <FormControl required variant="outlined" className="formFieldsInput">
              <OutlinedInput 
                id="formFieldsActionInput"
                type="text"
                value={input}
                onChange={(event) => (setInput(event.target.value))}
                multiline={true}
                rows={3}
              />
            </FormControl>

            { !submitted &&
              <ActionButton 
                variant="solid"
                ariaLabel="submit form"
                type="submit"
                className="actionDetailsButton dark"
              >
              Send Now
              </ActionButton>}
          </Box>
        </>
      }
      { currentAction?.documentUploadUrl &&
        !submitted &&
          <LinkButton 
            variant="solid"
            ariaLabel="upload document link"
            className="documentUploadButton dark"
            href={currentAction?.documentUploadUrl || ''}
            newTab
          >
            <div className="uploadDocumentButtonText">
              <Launch />
              <Typography variant="body1" className="light">
                Follow this link to upload documents
              </Typography>
            </div>
          </LinkButton>
      }
    </div>
  );
};

export default FormFields;
