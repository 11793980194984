import { useContext } from 'react'
import { ListDeletedDocumentsQuery, ListDocumentsQuery, useCreateUploadUrlMutation } from '../types/graphql';
import { EstateContext } from '../providers/EstateProvider';
import axios from 'axios';
import { DocumentContext, IFile } from '../providers/DocumentProvider';
import { toast } from 'react-toastify';

const useFileUpload = () => {
  const {setFolderFiles, directoryPath} = useContext(DocumentContext);
  const [createUploadUrlMutation] = useCreateUploadUrlMutation();
  const {selectedEstateId} = useContext(EstateContext);

  const uploadFile = async (file: File, signedUrl: string, fileIndex: number): Promise<void> => {
    const url = new URL(signedUrl);
    const path = url.pathname;
    const id = decodeURIComponent(path.split('/').slice(1).join('/'));

    try {
      setFolderFiles((uploads) => {
        const newUploads = {...uploads}
        
        newUploads[directoryPath || ''][file.name] = {
          uploadedFile: file,
          id: id,
          name: file.name,
          uploadSuccess: false,
          uploadProgress: 0,
          uploadError: '',
        }
        return newUploads
      });

      await axios.put(signedUrl, file, 
        {
          headers: {'Content-Type': file.type},
          onUploadProgress: (progressEvent) => {
            //check if progressEvent.total is null or undefined
            if (!progressEvent.total) return;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFolderFiles((uploads) => {
              const newUploads = {...uploads}
              if(newUploads && newUploads[directoryPath][file.name]) newUploads[directoryPath][file.name].uploadProgress = percentCompleted
              if(newUploads && newUploads[directoryPath][file.name] && percentCompleted === 100) newUploads[directoryPath][file.name].uploadSuccess = true
              return newUploads
            });
          },
        },
      );
      
    } catch (error: any) {
      setFolderFiles((uploads) => {
        const newUploads = uploads
        if(newUploads) newUploads[directoryPath][fileIndex].uploadSuccess = false
        if(newUploads) newUploads[fileIndex].uploadError = error.message
        return newUploads
      });

      console.error('An error occurred while uploading the file', error);
    }
  }

  // Submit the files to the server using files state
  const handleUploadSubmit = ({files, destinationFolder}: {files: File[], destinationFolder: string}) => {
    // For each file, get a signed URL from the server
    // and upload the file to the URL
    // Replace backslashes with nothing to sanitize the destination folder of backslashes
    const sanitizedDestinationFolder = destinationFolder.replace(/\//g, '');

    files.forEach(async (file, index) => {
      const { data } = await createUploadUrlMutation({
        variables: {
          fileName: file.name,
          destinationFolder: sanitizedDestinationFolder,
          estateId: selectedEstateId, 
        }, 
      });
      uploadFile(file, data?.createUploadUrl as string, index);
    })
  }

  const fetchFile = async (url: string) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = await response.data;
  
      // Extract the name from the URL
      const urlParts = url.split('/');
      const name = urlParts[urlParts.length - 1].split('?')[0];
  
      const file = new File([blob], name, { type: blob.type });
      return file;
    } catch (error) {
      console.error(error);
    }
  }

  const formatListDocumentsQueryDataToFiles = async (data: ListDocumentsQuery) => {
    const formattedFiles: Record<string, IFile> = {}
    await Promise.all(data.listDocuments.map(async (document) => {
      formattedFiles[document?.name || ''] = {
        lastModified: document?.modDate || '',
        id: document?.id || '',
        name: document?.name || '',
        signedUrl: document?.signedUrl || '',
        uploadedAt: document?.uploadedAt || '',
        contentType: document?.contentType || '',
        uploadSuccess: true,
        uploadProgress: 100,
        uploadError: '',
      }
    }));
    return formattedFiles
  }

  const formatDeletedFilesQueryDataToFileUploads = async (data: ListDeletedDocumentsQuery) => {
    const formattedFiles: Record<string, IFile> = {}
    data.listDeletedDocuments.forEach((document) => {
      const pathParts = document?.id.split('/');
      const folderName = pathParts[pathParts.length - 2];
      const fileName = `${folderName}/${document?.name}` || '';
      formattedFiles[fileName] = {
        id: document?.id || '',
        name: document?.name || '',
        deleteMarkerId: document?.deleteMarkerId || '',
        uploadSuccess: true,
        uploadProgress: 100,
        uploadError: '',
      }
    });
    return formattedFiles;
  }

  const downloadFile = async(filePath: string) => {
    // const blob = new Blob([file], { type: file.type });
    const file = await fetchFile(filePath);

    if (!file) {
      toast('We are unable to download this file. Please try again later.', { type: 'error' });
      return;
    }

    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = decodeURIComponent(file.name); // ensure correct file name by removing url encoding
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Fetches the file from S3 and allows for preview via the browser
  const viewFile = async (filePath: string) => {
    const file = await fetchFile(filePath);

    if (!file || file.type !== determineMimeType(file.name)) {
      toast('We are unable to preview this file. Please download the file to your device or computer to view it.', { type: 'error' });
      return;
    }

    const url = URL.createObjectURL(file);
    window.open(url, '_blank');
  }
  
  // Example function to determine MIME type (you would need to implement the logic)
  function determineMimeType(fileName: string): string | null {
    const extension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    switch (extension) {
    case 'avi':
      return 'video/x-msvideo';
    case 'css':
      return 'text/css';
    case 'csv':
      return 'text/csv';
    case 'doc':
      return 'application/msword';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'gif':
      return 'image/gif';
    case 'html':
      return 'text/html';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'js':
      return 'application/javascript';
    case 'json':
      return 'application/json';
    case 'mov':
      return 'video/quicktime';
    case 'mp3':
      return 'audio/mpeg';
    case 'mp4':
      return 'video/mp4';
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'ppt':
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'txt':
      return 'text/plain';
    case 'wav':
      return 'audio/wav';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xml':
      return 'application/xml';
    default:
      return null; // Default to null if you cannot determine the MIME type
    }
  }


  return {
    handleUploadSubmit,
    formatFilesQueryDataToFileUploads: formatListDocumentsQueryDataToFiles,
    formatDeletedFilesQueryDataToFileUploads,
    downloadFile,
    viewFile,
  }

}

export default useFileUpload