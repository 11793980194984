import { FC, useContext, useEffect, useRef } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import './Modal.scss';
import { LayoutContext } from '../../providers/LayoutProvider';
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton';
import {ReactComponent as CloseIcon} from '../../assets/icons/navigation/x.svg'

const Modal: FC = () => {
  const {
    displayModal, setDisplayModal, modalContents, setModalContents,
  } = useContext(LayoutContext);

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    if (displayModal && modalRef.current) {
      modalRef.current.focus();
    }
    if (!displayModal) {
      setModalContents(null);
    }
  }, [displayModal]);

  const handleClose = () => {
    setDisplayModal(false);
    setModalContents(null);
  };

  return (
    <div 
      ref={modalRef}
      style={{display: displayModal ? 'block' : 'none'}} 
      onClick={handleClose} 
      aria-labelledby="modal-title" 
      className='layoutModal'
      tabIndex={-1}
    >
      <dialog 
        onClick={e => e.stopPropagation()} 
        className='layoutModalContent'
        open={displayModal} 
      >
        <ActionButton 
          handleClick={handleClose} className="closeButton"
          ariaLabel="Close Modal Button"
        >
          <CloseIcon/>
        </ActionButton>
        {modalContents}
      </dialog>
    </div>
  );
}

export default Modal;