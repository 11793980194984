import { FC, useContext, useEffect, useState } from 'react'
import styles from './KeyDateCard.module.scss'
import Typography from '../../atoms/Typography/Typography'
import Card from '../../atoms/Card/Card'
import { ContentCompleteByType } from '../../../../types/graphql'
import { AccountContext } from '../../../../providers/Authentication'
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";

interface KeyDateCardProps {
  testId?: string
  contentId: string,
  componentId: string,
  title: string
  displayDate: string
  completeByType: ContentCompleteByType
}

const KeyDateCard: FC<KeyDateCardProps> = ({
  testId,
  displayDate,
  title,
  completeByType,
  contentId,
  componentId,
}) => {
  const [ keyDate, setKeyDate ] = useState<string[]>(["", ""])
  const { showAdminTools } = useContext(AccountContext)

  const navigate = useRoleNavigate()

  const clickableAction = () => {
    const url = `/estateComponentDetails/${componentId}?contentId=id-${contentId}`
    navigate(url)
  }


  const handleDate = () => {
    switch (completeByType) {
    case ContentCompleteByType.Exact :
      setKeyDate(displayDate.split(",")[0].split(" "))
      break
    case ContentCompleteByType.Month :
    case ContentCompleteByType.Fuzzy :
      setKeyDate(displayDate.split(" "))
      break
    }
  }

  useEffect(() => {
    handleDate()
  }, [displayDate])


  return (
    <Card 
      data-testid={testId} 
      className={styles.keyDateCardContainer}
      clickable={true}
      clickableAction={clickableAction}
    >
      {/* Date */}
      <div className={styles.date}>
        <Typography variant='H5' font='UI1' fontWeight='regular'>{keyDate[0]}</Typography>
        {
          completeByType === ContentCompleteByType.Exact ?
            <Typography variant='H3' font='UI1' fontWeight='medium'>{keyDate[1]}</Typography>
            :
            <Typography variant='H4' font='UI1' fontWeight='medium'>{keyDate[1]}</Typography>
        }
      </div>
      {/* Title */}
      <Typography className={styles.title} font="UI1" variant='H6'>
        {title}
      </Typography>
    </Card>
  )
}

export default KeyDateCard
