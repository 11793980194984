import { FC } from 'react'
import useResponsiveComponent from '../../../hooks/useResponsiveComponent'
import mobile from './Mobile'
import desktop from './Desktop'

interface DocumentsProps {
  testId?: string
}

const Documents: FC<DocumentsProps> = ({testId}) => {

  const ResponsiveComponent = useResponsiveComponent({
    mobile,
    desktop, 
  })

  return  <ResponsiveComponent />
}

export default Documents