import { FC, useContext } from 'react'
import './index.scss'
import { Typography } from '@mui/material'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../../providers/LayoutProvider'
import { useDeleteOneComponentMutation } from '../../../../types/graphql'
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider'
import { EstateContext } from '../../../../providers/EstateProvider'

interface DeleteOneComponentFormProps {
  testId?: string
}

const DeleteOneComponentForm: FC<DeleteOneComponentFormProps> = ({testId}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const {currentEstateComponent} = useContext(EstateComponentContext);
  const { refetchCurrentEstate } = useContext(EstateContext);
  
  const [ deleteOneComponentMutation ] = useDeleteOneComponentMutation({
    variables: {where: {id: currentEstateComponent?.id}},
    onCompleted: (data) => {
      // handle any actions needed on complete
      refetchCurrentEstate()
    },
    onError: (error) => {
      // handle errors as desired
      alert(error)
    },
  })

  const handleDeleteClick = () => {
    //delete mutation here
    deleteOneComponentMutation()
    setDisplayModal(false)
  }

  const handleCancelClick = () => {
    setDisplayModal(false)
    setModalContents(<></>)
  }

  return (
    <div data-testid={testId} className='deleteOneComponentFormContainer'>
      <Typography variant='h2'>
        Are you sure you would like to delete this item? Deletion is permanent and cannot be undone. 
      </Typography>
      <div className='actionButtonContainer'>
        <ActionButton ariaLabel={'delete the selected action card'} variant='solid' handleClick={handleDeleteClick}>
          Delete
        </ActionButton>
        <ActionButton ariaLabel={'cancel delete'} handleClick={handleCancelClick}>
          Cancel
        </ActionButton>
      </div>
    </div>
  )
}

export default DeleteOneComponentForm