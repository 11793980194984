import { FC, useContext, useState, useEffect } from "react";
import ActionList from "../../components/ActionList/ActionList";
import { ActionCardStatus, SortOrder, useGetKeyDatesQuery, useGetUserEstateActionCardsQuery, useGetUserEstateQuery } from "../../types/graphql";
import { EstateContext } from "../../providers/EstateProvider";
import { MobileLayout } from "../../components/Layouts";
import { AccountContext } from "../../providers/Authentication";
import { ActionContext } from "../../providers/ActionProvider";
import Typography from "../../components/DesignSystem/atoms/Typography/Typography";

import styles from './Mobile.module.scss';
import RecentActivityList from "../../components/DesignSystem/molecules/RecentActivityList/RecentActivityList";
import EstateChartSmall from "../../components/DesignSystem/molecules/EstateChartSmall/EstateChartSmall";
import KeyDateList from "../../components/DesignSystem/molecules/KeyDateList/KeyDateList";

const Mobile: FC = () => {
  const {
    currentEstate, setCurrentEstate, selectedEstateId, setDeceased, setRefetchCurrentEstate, deceased,
  } = useContext(EstateContext);
  const { showAdminTools } = useContext(AccountContext);
  const {
    setActions, setRefetchActionList, setActionsLoading,
  } = useContext(ActionContext);
  const [keyDates, setKeyDates] = useState<any[]>([])

  const statusFilter = showAdminTools ?
    [ActionCardStatus.New,
      ActionCardStatus.Draft,
      ActionCardStatus.InProgress,
      ActionCardStatus.Completed,
    ] :
    [
      ActionCardStatus.New,
    ]

  const {
    refetch: actionsRefetch, data: actionData, loading: actionsLoading,
  } = useGetUserEstateActionCardsQuery({
    variables: {
      estateId: selectedEstateId,
      where: { status: { in: statusFilter } },
      orderBy: [
        { status: SortOrder.Asc },
        { updatedAt: SortOrder.Desc },
      ],
    },
    onError: (error) => {
      alert(error)
    },
  });

  useEffect(() => {
    const actionCards = actionData?.getUserEstate?.actionCards
    setActions(actionCards)
    const estateDeceased = actionData?.getUserEstate?.deceased
    estateDeceased && setDeceased(estateDeceased)
    setActionsLoading(actionsLoading)
  }, [actionData, actionsLoading])

  // Get data from the backend
  const { refetch: estateRefetch, data: estateData } = useGetUserEstateQuery(
    {
      variables: { estateId: selectedEstateId },
      onError: (error) => {
        console.error('Error in EstateView', error);
      },
    },
  )

  useEffect(() => {
    if (estateData?.getUserEstate?.id) {
      if (!estateData.getUserEstate.deceased && deceased) {
        estateData.getUserEstate.deceased = deceased
      }
      setCurrentEstate(estateData)
      const estateDeceased = estateData?.getUserEstate?.deceased
      estateDeceased && setDeceased({ ...estateDeceased })
    }
  }, [estateData]);

  useEffect(() => {
    setRefetchCurrentEstate(() => estateRefetch)
    setRefetchActionList(() => actionsRefetch)
  }, [actionsRefetch, estateRefetch])

  useGetKeyDatesQuery(
    {
      variables: { estateId: selectedEstateId },
      onCompleted: (data) => {
        setKeyDates(data.getUserEstate?.keyDates || [])
      },
    },
  )

  return (
    <MobileLayout includeHeader>
      <div className={styles.homeViewContainer}>
        <div className={styles.homeViewContent}>
          <EstateChartSmall testId="estate-view-piechart" />
          <div
            className={styles.homeViewActionListContainer}
            data-testid={'home-view-action-list-container'}
          >
            <ActionList className={styles.homeViewActionList} showHeader />
          </div>
          <div className={styles.recentActivityContainer}>
            <RecentActivityList
              wrap={false}
              listItemMaxWidth={`calc(33.33% - ${20}px)`}
              listItemWidth={295}
              gap={20}
            />
          </div>
          { keyDates.length > 0 && (
            <div className={styles.keyDatesContainer}>
              <Typography font="UI1" variant="H5" className={styles.keyDatesHeader}>Key Dates</Typography>
              <KeyDateList
                wrap={false}
                gap={20}
                direction='vertical'
              />
            </div>
          )}
        </div>
      </div>
    </MobileLayout>
  )
}

export default Mobile;