import { ReactNode, useContext, useEffect, useState } from "react"
import { AccountContext } from "../providers/Authentication"
import { ActionCardStatus, ComponentStatus, TaskStatus } from "../types/graphql"
import DrawIcon from '@mui/icons-material/Draw';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { ReactComponent as CompleteIcon } from "../assets/icons/statusIcons/complete.svg";
import { ReactComponent as AttentionIcon } from "../assets/icons/statusIcons/attention-status-icon.svg";
import { ReactComponent as ProgressIcon } from "../assets/icons/statusIcons/progress-status-icon.svg";
import { ReactComponent as EmptyCircle } from "../assets/icons/statusIcons/not-ready-status-icon.svg";
import { ReactComponent as InProgress } from "../assets/icons/statusIcons/in-progress.svg";

const useStatus = (
  status: TaskStatus | ActionCardStatus | ComponentStatus | undefined | null, 
  completedAt?: string,
) => {
  const { showAdminTools } = useContext(AccountContext)
  const [ statusClass, setStatusClass ] = useState<string>('')
  const [ statusIcon, setStatusIcon ] = useState< ReactNode | undefined>()
  const [ statusMsgShort, setStatusMsgShort ] = useState<string>('')
  const [ statusMsgLong, setStatusMsgLong ] = useState<string>('')

  useEffect(()=> {
    switch (status) {
    case ComponentStatus.Completed:
      setStatusMsgShort('Complete')
      setStatusMsgLong(`Complete`)
      setStatusClass('completed')
      setStatusIcon(<CompleteIcon />)
      break;
    case TaskStatus.Completed:
    case ActionCardStatus.Completed:
      setStatusMsgShort('Done')
      completedAt ? setStatusMsgLong(`Marked complete on ${completedAt}`) : setStatusMsgLong('Marked complete')
      setStatusClass('completed')
      setStatusIcon(<CompleteIcon />)
      break;
    case TaskStatus.Canceled:
    case ActionCardStatus.Canceled:
      setStatusMsgShort('Canceled')
      setStatusMsgLong('Canceled')
      setStatusClass('canceled')
      setStatusIcon(< CancelIcon />)
      break;
    case TaskStatus.Ready:
      setStatusMsgShort('Ready')
      setStatusMsgLong('Ready for Alix')
      setStatusClass('ready')
      setStatusIcon(<EmptyCircle />)
      break;
    case TaskStatus.Paused:
      setStatusMsgShort('Needs Attention')
      setStatusMsgLong("See what's needed")
      setStatusClass('attention')
      setStatusIcon(< AttentionIcon />)
      break;
    case ComponentStatus.InProgress:
      setStatusMsgShort('In progress')
      setStatusMsgLong('In progress')
      setStatusClass('inProgress')
      setStatusIcon(< InProgress />)
      break;
    case TaskStatus.InProgress:
      setStatusMsgShort('In Progress')
      setStatusMsgLong('You did this. Alix is reviewing.')
      setStatusClass('inProgress')
      setStatusIcon(< ProgressIcon />)
      break;
    case ComponentStatus.Pending:
      setStatusMsgShort('Pending')
      setStatusMsgLong('Pending')
      setStatusClass('pending')
      setStatusIcon(< InProgress />)
      break;
    case ComponentStatus.Blocked:
      setStatusMsgShort('Blocked')
      setStatusMsgLong('Blocked')
      setStatusClass('pending')
      setStatusIcon(null)
      break;
    case TaskStatus.NotReady:
    case 'NOT_READY':
      setStatusMsgShort('Not Ready')
      setStatusMsgLong('')
      setStatusClass('')
      setStatusIcon(< EmptyCircle />)
      break;
    case ActionCardStatus.New:
      if(showAdminTools){
        setStatusMsgShort('New')
        setStatusMsgLong('New Action')
        setStatusClass('attention')
        setStatusIcon(< AttentionIcon />)
      }
      break;
    case ActionCardStatus.Draft:
    case TaskStatus.Draft:
      setStatusMsgShort('Draft')
      setStatusMsgLong('In progress with Alix')
      setStatusClass('draft')
      setStatusIcon(< DrawIcon />)
      break;
    default:
      break;
    }
  }, [status])


  return {
    statusClass,
    statusMsgShort,
    statusMsgLong,
    statusIcon,
  }
}

export default useStatus