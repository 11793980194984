import { FC, useContext, useState } from 'react'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useAdminCreateOneEstateWithDeceasedMutation } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { USStatesAndTerritoriesAbbreviations } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DatePicker } from '@mui/x-date-pickers';

import './adminCreateEstateWithDeceasedForm.scss';

type AdminCreateEstateWithDeceasedFormProps = {
  testId?: string
}

const AdminCreateEstateWithDeceasedForm: FC<AdminCreateEstateWithDeceasedFormProps> = ({ testId }) => {
  const { setDisplayModal, setModalContents } = useContext(LayoutContext);
  const { refetchEstatesList } = useContext(EstateContext);
  const [ adminCreateOneEstateWithDeceasedMutation ] = useAdminCreateOneEstateWithDeceasedMutation();

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: any },
  ) => {
    console.log("values: ", values);

    adminCreateOneEstateWithDeceasedMutation({
      variables: {
        data: {
          hasTrust: values.hasTrust,
          hasWill: values.hasWill,
          channelPartner: values.channelPartner,
          deceased: {
            create: {
              firstName: values.firstName,
              lastName: values.lastName,
              dateOfBirth: values.dob,
              dateOfDeath: values.dod,
              state: values.state,
            },
          },
        },
      },
      onCompleted: () => {
        setDisplayModal(false);
        refetchEstatesList();
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed AdminCreateOneEstateWithDeceasedMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const handleCancelClick = () => {
    setDisplayModal(false)
    setModalContents(<></>)
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    dob: null,
    dod: null,
    state: null,
    hasTrust: false,
    hasWill: false,
    channelPartner: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Decedant's first name is required"),
    lastName: Yup.string().required("Decedant's last name is required"),
    dob: Yup.date().required("Decedant's date of birth is required"),
    dod: Yup.date().required("Decedant's date of death is required"),
    state: Yup.string().required("Decedant's state (or territory) of residence is required")
      .matches(/^[A-Z]{2}$/, "State must be a two letter abbreviation")
      .oneOf(USStatesAndTerritoriesAbbreviations, "Invalid state/territory abbreviation"),
    hasTrust: Yup.boolean(),
    hasWill: Yup.boolean(),
    channelPartner: Yup.string().oneOf([ '', 'Singing Hills', 'Melrose Abbey' ]),
  });

  return (
    <div data-testid={testId} className='adminCreateEstateWithDeceasedFormContainer'>
      <Typography variant='h4' className='adminCreateEstateWithDeceasedFormHeader'>
        Create One Estate with Deceased Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">
                {/* Decedant First Name */}
                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName1"} data-testid={testId}>
                  <Field
                    className={"decedantFirstNameInput fieldInput textInput"}
                    required={true}
                    id={"decedantFirstName"}
                    label={"Decedant first name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"first-name"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName && !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage" />
                </div>

                {/* Decedant Last Name */}
                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName2"} data-testid={testId}>
                  <Field
                    className={"decedantLastNameInput fieldInput textInput"}
                    required={true}
                    id={"decedantLastName"}
                    label={"Decedant last name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"last-name"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName && !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage" />
                </div>

                {/* Decedant Date of Birth */}
                <div className={"inputWrapper dobFieldWrapper textInputWrapper"} key={"dob3"} data-testid={testId}>
                  <Field
                    className={"decedantDobInput fieldInput textInput"}
                    required={true}
                    id={"decedantDob"}
                    label={"Decedant date of birth"}
                    name={"dob"}
                    type={"date"}
                    autoComplete={"dob"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.dob && !!errors.dob}
                    onChange={(date: any) => setFieldValue("dob", date)}
                  />
                  <ErrorMessage name={"dob"} component="div" className="inputErrorMessage" />
                </div>

                {/* Decedant Date of Death */}
                <div className={"inputWrapper dodFieldWrapper textInputWrapper"} key={"dod4"} data-testid={testId}>
                  <Field
                    className={"decedantDodInput fieldInput textInput"}
                    required={true}
                    id={"decedantDod"}
                    label={"Decedant date of death"}
                    name={"dod"}
                    type={"date"}
                    autoComplete={"dod"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.dod && !!errors.dod}
                    onChange={(date: any) => setFieldValue("dod", date)}
                  />
                  <ErrorMessage name={"dod"} component="div" className="inputErrorMessage" />
                </div>

                {/* Decedant State of Residence */}
                <div className={"inputWrapper stateFieldWrapper textInputWrapper"} key={"state5"} data-testid={testId}>
                  <Autocomplete
                    id="state"
                    className={"decedantStateInput fieldInput textInput"}
                    options={USStatesAndTerritoriesAbbreviations.map((abbreviation: string) => abbreviation)}
                    getOptionLabel={(option: string) => option}
                    onChange={(_e, value) => {
                      setFieldValue(
                        "state",
                        value !== null ? value : initialValues.state,
                      );
                    }}
                    renderInput={params => (
                      <Field
                        {...params}
                        className={"stateInput fieldInput textInput"}
                        required={true}
                        id={"state"}
                        label={"Decedant state of residence"}
                        name={"state"}
                        type={"text"}
                        autoComplete={"state"}
                        as={TextField}
                        error={submitCount > 0 && touched.state && !!errors.state}
                      />
                    )}
                  />
                </div>

                {/* Has Trust */}
                <div className={"inputWrapper hasTrustFieldWrapper"}>
                  <Typography variant="body1">Has Trust</Typography>
                  <Field
                    as={Switch}
                    type="checkbox"
                    name={`hasTrust`}
                    id="hasTrust"
                    label="Has Trust"
                    error={submitCount > 0 && touched.hasTrust && !!errors.hasTrust}
                  />
                  <ErrorMessage name={`hasTrust`} component="div" className="inputErrorMessage" />
                </div>

                {/* Has Will */}
                <div className={"inputWrapper hasWillFieldWrapper textInputWrapper"}>
                  <Typography variant="body1">Has Will</Typography>
                  <Field
                    as={Switch}
                    type="checkbox"
                    name={`hasWill`}
                    id="hasWill"
                    label="Has Will"
                    error={submitCount > 0 && touched.hasWill && !!errors.hasWill} 
                  />
                  <ErrorMessage name={`hasWill`} component="div" className="inputErrorMessage" />
                </div>


                {/* Channel Partner */}
                <div className={"inputWrapper channelPartnerFieldWrapper textInputWrapper"} key={"channelPartner6"} data-testid={testId}>
                  <Autocomplete
                    id="channelPartner"
                    className={"channelPartnerInput fieldInput textInput"}
                    options={['', 'Singing Hills', 'Melrose Abbey']}
                    getOptionLabel={(option: string) => option}
                    onChange={(_e, value) => {
                      setFieldValue(
                        "channelPartner",
                        value !== null ? value : initialValues.channelPartner,
                      );
                    }}
                    renderInput={params => (
                      <Field
                        {...params}
                        className={"channelPartnerInput fieldInput textInput"}
                        id={"channelPartner"}
                        label={"Channel Partner"}
                        name={"channelPartner"}
                        type={"text"}
                        autoComplete={"channel-partner"}
                        as={TextField}
                        error={submitCount > 0 && touched.channelPartner && !!errors.channelPartner}
                      />
                    )}
                  />
                </div>

              </Container>
            </Container>


            <Container className="buttonsContainer">
              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                Submit
              </ActionButton>
              <ActionButton ariaLabel={'cancel'} handleClick={handleCancelClick}>
                Cancel
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default AdminCreateEstateWithDeceasedForm;