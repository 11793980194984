import { FC, ReactNode, SetStateAction, createContext, useState } from 'react';
import CryptoJS from 'crypto-js';

import { Auth } from 'aws-amplify';
import { useRoleNavigate } from "../../hooks/useRoleNavigate";
import { ApolloClient, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import buildOneOffApolloClient from '../../helpers/buildOneOffClient';

interface AccountContextValue {
  authenticate: (username: string, password: string) => Promise<void>;
  checkAdmin: () => Promise<unknown>;
  handleNewPasswordChallenge: (newPassword: string) => Promise<CognitoUserSession | false>;
  hasAdminAuthorization: boolean;
  optimizelyID: string;
  setOptimizelyID: React.Dispatch<SetStateAction<string>>;
  userId: string;
  setUserId: React.Dispatch<SetStateAction<string>>;
  userName: string;
  setUserName: React.Dispatch<SetStateAction<string>>;
  refreshSession: () => Promise<void>;
  resetPassword: (email: string, newPassword: string, code: string) => Promise<unknown>;
  sendOtpCode: (email: string) => Promise<unknown>;
  setShowAdminTools: React.Dispatch<SetStateAction<boolean>>;
  showAdminTools: boolean;
  logout: () => void;
  isSessionValid: () => Promise<boolean>;
}

const AccountContext = createContext<AccountContextValue>({} as AccountContextValue);

const Account: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useRoleNavigate();
  const [newUserAttributes, setNewUserAttributes] = useState<any>({});
  const [hasAdminAuthorization, setHasAdminAuthorization] = useState<boolean>(false);
  const [showAdminTools, setShowAdminTools] = useState<boolean>(false);
  const [optimizelyID, setOptimizelyID] = useState<string>('placeholder');
  const [userId, setUserId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const oneOffClient = buildOneOffApolloClient();

  const authenticate = async (email: string, password: string) => {
    // hash the email and set it as the optimizely id
    const hashedID = CryptoJS.SHA256(email).toString();
    setOptimizelyID(hashedID);

    const user = await Auth.signIn(email, password);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      // console.log("NEW PASSWORD REQUIRED");
      setNewUserAttributes({
        username: user.username,
        email: email,
        password: password,
      });

      // navigate to the reset new user password page
      navigate("/resetNewUserPassword");

      return;
    }

    // Get the cognito id
    const userInfo = await Auth.currentUserInfo();
    const currentSession = await Auth.currentSession();
    const userCognitoId = userInfo.username;
    const userCognitoGroups = currentSession.getIdToken().payload["cognito:groups"];

    // Set the user id
    setUserId(userCognitoId);

    // Set the user name
    setUserName(email);

    // Get the jwt token
    const jwtToken = currentSession.getIdToken().getJwtToken();
    console.log("SESSION TOKEN: ", jwtToken);

    // Set the jwt token in the apollo client
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: jwtToken ? `Bearer: ${jwtToken}` : '',
        },
      }
    });

    const httpLink = createHttpLink({ uri: process.env.REACT_APP_ALIX_GRAPHQL_API });

    oneOffClient.setLink(authLink.concat(httpLink));

    // execute single query to check if the user has the latest EULA
    const { data } = await oneOffClient.query({
      query: gql`
          query IsEULACurrent {
            isEULAcurrent
          }
          `,
    });

    // if the user has not accepted the latest EULA, navigate to the EULA page
    if (!data.isEULAcurrent) navigate("/acceptEula");
    else if (userCognitoGroups?.includes("ADMIN")) {
      setHasAdminAuthorization(true);
      setShowAdminTools(true);
      // have to navigate to the admin landing page`with url to activate firewall
      window.location.href = "/manage/adminLanding";
    } else navigate("/home");
  };

  const checkAdmin = async () => {
    try {
      const session = await Auth.currentSession();
      if (session && 'getIdToken' in session) {
        const userCognitoGroups = session.getIdToken().payload["cognito:groups"];

        if (userCognitoGroups?.includes("ADMIN")) {
          setHasAdminAuthorization(true);
          setShowAdminTools(true);
          return true;
        } else return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  const isSessionValid = async (): Promise<boolean> => {
    try {
      const session = await Auth.currentSession();

      return session.isValid();
    } catch (error) {
      console.error('Session is not valid', error);
      return false
    }
  }

  const handleNewPasswordChallenge = async (newPassword: string): Promise<CognitoUserSession | false> => {
    try {
      const user = await Auth.signIn(newUserAttributes.username, newUserAttributes.password) as any;
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED' && user.challengeParam?.userAttributes) {
        await Auth.completeNewPassword(
          user,           // the Cognito User Object
          newPassword,    // the new password
        );
        // User has now successfully changed the password and is logged in
        // You can get the session (tokens) for the authenticated user
        const session = await Auth.currentSession();
        return session;
      }
      return false; // Add this line
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setOptimizelyID('placeholder');

      // handle success
      navigate("/login");

      // refresh the page
      window.location.reload();

      console.log("SIGN OUT SUCCESSFUL");
    } catch (error) {
      // handle error
      console.error("SIGN OUT FAILED: ", error);
    }
  };

  const refreshSession = async () => {
    try {
      await Auth.currentSession();
      // Update state here to trigger a re-render of your components
    } catch (err) {
      if ((err as Error).name === 'NetworkError') {
        // Retry logic here
      } else {
        logout();
        navigate("/login");
        // Provide feedback to the user
      }
    }
  };

  const resetPassword = async (
    email: string,
    code: string,
    newPassword: string,
  ) => {
    try {
      return await Auth.forgotPasswordSubmit(email, code, newPassword);
    } catch (error) {
      console.error("Reset Password failed");
      alert(error);
    }
  }

  const sendOtpCode = async (emailAddress: string) => {
    try {
      await Auth.forgotPassword(emailAddress);
      // console.log('Password reset email sent');
      // Handle success scenario, e.g., show a success message or redirect the user
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  return (
    <AccountContext.Provider value={{
      authenticate,
      checkAdmin,
      handleNewPasswordChallenge,
      hasAdminAuthorization,
      logout,
      optimizelyID,
      setOptimizelyID,
      userId,
      setUserId,
      userName,
      setUserName,
      refreshSession,
      resetPassword,
      sendOtpCode,
      setShowAdminTools,
      showAdminTools,
      isSessionValid,
    }}>
      {children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };