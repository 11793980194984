import { FC, useContext, useEffect } from 'react'
import './DeletedFilesList.scss'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider';
import { useListDeletedDocumentsQuery, ListDeletedDocumentsQuery } from '../../../types/graphql';
import { EstateContext } from '../../../providers/EstateProvider';
import useFileUpload from '../../../hooks/useFileUpload';
import DeletedFileListItem from '../DeletedFileListItem/DeletedFileListItem';

interface FilesListProps {
  testId?: string,
  className?: string,
  type: 'upload' | 'download' | 'menu' | 'options',
  directory?: string
  tabIndex?: number,
  expanded?: boolean,
}

const FilesList: FC<FilesListProps> = ({
  testId, className, type, directory, tabIndex=0, expanded=true,
}) => {
  const { selectedEstateId } = useContext(EstateContext)
  const {
    folderFiles, setFolderFiles, setFolderRefetch,
  } = useContext(DocumentContext);
  const { formatDeletedFilesQueryDataToFileUploads } = useFileUpload()

  const {
    data, error, refetch,
  } = useListDeletedDocumentsQuery({variables: {"estateId": selectedEstateId}} )

  const formatFiles = async (data: ListDeletedDocumentsQuery) => {
    const formatted = await formatDeletedFilesQueryDataToFileUploads(data)
    console.log('formatted:', formatted)
    setFolderFiles((files) => ({
      ...files,
      [directory || '']: formatted,
    }))
  }

  useEffect(() => {
    data && formatFiles(data)

    error && console.error("Error loading ListDocuments:", error)
  }, [data, error])

  useEffect(() => {
    directory && refetch && setFolderRefetch(folders => {
      return {
        ...folders,
        [directory || '']: refetch,
      }
    })
  }, [refetch])

  return (
    <>
      {expanded ? <div data-testid={testId} className={`filesListContainer ${className}`} tabIndex={tabIndex}>
        <ul className='fileList'>
          { folderFiles && directory && folderFiles[directory] && Object.values(folderFiles[directory])
            .sort((fileA, fileB) => fileA.name.localeCompare(fileB.name))
            .map((deletedFile, index) => (
              <DeletedFileListItem tabIndex={tabIndex} key={`${deletedFile.name}${index}`} deletedFile={deletedFile}/>
            ))}
            
        </ul>
      </div> : null}
    </>
  )
}

export default FilesList

