import React, { FC } from 'react'
import './ComponentSubTypeIcon.scss'
import CircleIcon from '../CircleIcon/CircleIcon'

// import { ReactComponent as BankAccountIcon } from '../../assets/icons/estateComponent/bank-account.svg'
import { ReactComponent as BankAccountIcon } from '../../assets/icons/estateComponent/bank-account.svg'
import { ReactComponent as RetirementIcon } from '../../assets/icons/estateComponent/retirement.svg'
import { ReactComponent as BillToCancelIcon } from '../../assets/icons/estateComponent/bill-to-cancel.svg'
import { ReactComponent as DebtIcon } from '../../assets/icons/estateComponent/debt.svg'
import { ReactComponent as DiscoveryIcon } from '../../assets/icons/estateComponent/discovery.svg'
import { ReactComponent as DistributionIcon } from '../../assets/icons/estateComponent/distribution.svg'
import { ReactComponent as InvestmentIcon } from '../../assets/icons/estateComponent/investment.svg'
import { ReactComponent as LifeInsuranceIcon } from '../../assets/icons/estateComponent/life-insurance.svg'
import { ReactComponent as PersonalPropertyIcon } from '../../assets/icons/estateComponent/personal-property.svg'
import { ReactComponent as ProbateIcon } from '../../assets/icons/estateComponent/probate.svg'
import { ReactComponent as RealEstateIcon } from '../../assets/icons/estateComponent/real-estate.svg'
import { ReactComponent as TaxIcon } from '../../assets/icons/estateComponent/tax.svg'
import { ReactComponent as UnclaimedPropertyIcon } from '../../assets/icons/estateComponent/unclaimed-property.svg'
import { ReactComponent as VehicleIcon } from '../../assets/icons/estateComponent/vehicle.svg'
import { ReactComponent as DefaultIcon } from '../../assets/icons/estateComponent/default.svg'
import { componentSubTypeToTintColorMapping } from '../../helpers'

interface ComponentSubTypeIconProps {
  subType: string
  bordered?: boolean
}

const ComponentSubTypeIcon: FC<ComponentSubTypeIconProps> = ({
  subType,
  bordered=false,
}) => {
  const componentSubTypeIcons: { [key: string]: JSX.Element } = {
    BankAccount: (<BankAccountIcon />),
    BillToCancel: (<BillToCancelIcon />),
    Debt: (<DebtIcon />),
    Discovery: (<DiscoveryIcon />),
    Distribution: (<DistributionIcon />),
    Investment: (<InvestmentIcon />),
    LifeInsurance: (<LifeInsuranceIcon />),
    PersonalProperty: (<PersonalPropertyIcon />),
    Probate: (<ProbateIcon />),
    RealEstate: (<RealEstateIcon />),
    Retirement: (<RetirementIcon />),
    Tax: (<TaxIcon />),
    UnclaimedProperty: (<UnclaimedPropertyIcon />),
    Vehicle: (<VehicleIcon />),
    Default: (<DefaultIcon />),
  };

  let ComponentIcon;
  if(subType){
    ComponentIcon = (props: React.SVGProps<SVGSVGElement>) => React.cloneElement(componentSubTypeIcons[subType] || componentSubTypeIcons['Default'], props)
  } else ComponentIcon = (props: React.SVGProps<SVGSVGElement>) => React.cloneElement(componentSubTypeIcons['Default'], props);
  
  if (bordered) return (
    <div className={`componentSubTypeIconContainer`}>
      <CircleIcon 
        containerClass={`componentSubTypeIconCircleContainer bordered`}
        iconComponent={<ComponentIcon />}
      />
    </div>
  )
  else return (
    <div className={`componentSubTypeIconContainer`}>
      <CircleIcon 
        containerClass='componentSubTypeIconCircleContainer'
        backgroundColor={
          ["Default", ""].includes(subType) ? 
            "#CDB4A9" : // default color
            componentSubTypeToTintColorMapping[subType as keyof typeof componentSubTypeToTintColorMapping]
        }
        iconComponent={<ComponentIcon />}
      />
    </div>
  )
}

export default ComponentSubTypeIcon