import { FC } from "react";
import {EstateComponentGroup,
  EstateComponentGroupsBySubType} from './index';
import EstateSubtypeCard from "./EstateSubtypeCard";
import { Container } from "@mui/material";

interface EstateComponentsBySubtypeProps {
  data: {
    estateComponentsGroupedBySubtype: EstateComponentGroupsBySubType
  }
  testId: string
}

const EstateComponentsBySubtype: FC<EstateComponentsBySubtypeProps> = ({
  data,
  testId,
}) => {
  const {estateComponentsGroupedBySubtype} = data;
  return (
    <Container className='estateComponentsOuterContainer' data-testid={testId}>
      {
        Object.entries(estateComponentsGroupedBySubtype).length && Object.entries(estateComponentsGroupedBySubtype).map(([subType, componentGroup]: [string, EstateComponentGroup], index: number) => (
          <EstateSubtypeCard 
            subType={subType} 
            componentGroup={componentGroup} 
            index={index}
            key={index}          
          />
        ))
      }
    </Container>
  )
}

export default EstateComponentsBySubtype