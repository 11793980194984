import { FC } from 'react'
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import { MobileLayout } from '../../components/Layouts'
import careteam from '../../assets/images/careteam.png';
import LinkButton from '../../components/DesignSystem/atoms/LinkButton/LinkButton';

import './Mobile.scss'

const Mobile: FC = () => {
  return (
    <MobileLayout
      includeSubHeader={false}
      includeCareTeamCard={false}
    >
      <div id="careTeamMobileContent">
        {/* Avatar Alyssa */}
        <img src={careteam} alt="Help Avatar" />

        {/* Flavor Text 1 */}
        <div className="flavorText1">
          <Typography font="UI1" variant="H4" className="howCanWeHelpText">{"How can we help?"}</Typography>
          <Typography font="UI1" variant="P1" className="noRequestText">
            {"No question or request is too small. Contact us anytime and we'll get back to you ASAP."}
          </Typography>
          <LinkButton href='mailto:careteam@meetalix.com' ariaLabel={"Send email to care team"} variant={"solid"} className='connectButton emailUsButton'>
          Email us
          </LinkButton>
        </div>

      </div>
    </MobileLayout>
  )
}

export default Mobile
