import { FC, useContext } from 'react'
import Typography from '../../atoms/Typography/Typography'
import { convertTimestampToHumanReadable2, useScreenWidth } from '../../../../helpers'
import Card from '../../atoms/Card/Card'
import { RoutingError, useMarkNotificationsSeenMutation } from '../../../../types/graphql'
import { toast } from 'react-toastify'

import styles from './NotificationItem.module.scss'
import { AccountContext } from '../../../../providers/Authentication/Account'
import { useRoleNavigate } from '../../../../hooks/useRoleNavigate'
import { buildRouteFromBEUrl } from '../../../../helpers/functions'

interface NotificationItemProps {
  createdAt: string,
  id: string,
  message: string,
  refetch: () => Promise<void>,
  route?: string,
  testId?: string,
  title: string,
  routingError: RoutingError | null,
  unread: boolean, 
}

const NotificationItem: FC<NotificationItemProps> = ({
  createdAt,
  id,
  message,
  refetch,
  route = null,
  testId,
  title,
  routingError = null,
  unread,
}) => {
  const screenWidth = useScreenWidth();
  const { userId } = useContext(AccountContext)
  const navigate = useRoleNavigate();
  const errorMessage = "The linked content is no longer available. Please contact the Care Team if you have questions."

  // define mutation to mark as read
  const [markNotificationAsSeen]  = useMarkNotificationsSeenMutation(
    {
      variables: {
        notificationIds: [id],
        userId,
      },
      onCompleted: (data) => {
        refetch()
      },
      onError: (error) => {
        console.error('mark as read mutation error', error)
      },
    },
  )

  //on click, mark as read
  const handleClick = () => {
    markNotificationAsSeen()
    if (routingError) toast.error(errorMessage, { autoClose: 2000 });
    else if (route) navigate(buildRouteFromBEUrl(route));
  }

  return (
    <Card clickable clickableAction={handleClick} data-testid={testId} className={[styles.notificationItemContainer, unread ? styles.unread : ''].join(' ')}>
      <div className={[styles.unreadIndicatorBar, unread ? styles.unread : ''].join(' ')}></div>
      <div className={styles.cardContent}>
        <Typography className={ screenWidth >= 800 ? styles.normalTitle : styles.mobileTitle } font="UI1" variant="P1" fontWeight='medium'>
          {title}
        </Typography>
        <Typography className={ screenWidth >= 800 ? styles.normalBody : styles.mobileBody } font="UI1" variant="P2" fontWeight='regular'>
          {message}
        </Typography>
        <Typography className={styles.createdAt} font="UI1" variant='P3' fontWeight='regular'>
          {convertTimestampToHumanReadable2(createdAt)}
        </Typography>
      </div>
      <div className={ styles.unreadContainer } >
        <div className={unread ? styles.unreadIndicator : ''} />
      </div>
    </Card>
  )
}

export default NotificationItem
