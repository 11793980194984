import { FC, useContext, useEffect } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton'
import { SortOrder, useAdminGetUsersOnEstateQuery, useUpdateOneEstateMutation } from '../../types/graphql'
import { EstateContext } from '../../providers/EstateProvider'
import { UserContext } from '../../providers/UserProvider'
import { LayoutContext } from '../../providers/LayoutProvider'
import UpdateOneUserForm from '../FormikForms/admin/UpdateOneUserForm/UpdateOneUserForm'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import CreateOneUserForm from '../FormikForms/admin/CreateOneUserForm/CreateOneUserForm'

import './selectUser.scss'
import AddExistingUserToEstateForm from '../FormikForms/admin/AddExistingUserToEstateForm/AddExistingUserToEstateForm'
import { ApolloError } from '@apollo/client'

interface SelectUserProps {
  testId?: string
}

const SelectUser: FC<SelectUserProps> = ({ testId }) => {
  const {
    selectedEstateId, setRefetchUsersOnEstate, refetchEstatesList,
  } = useContext(EstateContext)
  const { setSelectedUserId } = useContext(UserContext)
  const { setModalContents } = useContext(LayoutContext)

  const { data: usersOnEstate, refetch: refetchUsersOnEstate } = useAdminGetUsersOnEstateQuery({
    variables: {
      where: { id: selectedEstateId },
      orderBy: { userRole: { sort: SortOrder.Asc } },
    },
  });
  const [updateOneEstateMutation] = useUpdateOneEstateMutation();

  useEffect(() => {
    refetchEstatesList();
  }, [usersOnEstate])

  // Set the refetch users on estate function in the estate context
  useEffect(() => {
    setRefetchUsersOnEstate(() => refetchUsersOnEstate);
  }, [refetchUsersOnEstate]);

  const handleUpdateUserClick = (userId: string, element: ReactJSXElement) => {
    setSelectedUserId(userId);
    setModalContents(element);
  }

  const handleRemoveUserFromEstateClick = (userId: string) => {
    if (!selectedEstateId) return;

    updateOneEstateMutation({
      variables: {
        data: {
          users: {
            delete: [
              {
                "userId_estateId": {
                  estateId: selectedEstateId,
                  userId: userId,
                },
              },
            ],
          },
        },
        where: { id: selectedEstateId },
      },
      onCompleted: () => {
        refetchUsersOnEstate();
      },
      onError: (e: ApolloError) => {
        console.error("Failed updateOneEstateMutation call");
        console.error(e.message);
        alert(e.message);
      },
    });
  }

  return (
    <div data-testid={testId} className='selectUserContainer'>
      <div className='selectUserHeaderContainer'>
        <Typography variant='h4'>
          Select Customer to Update
        </Typography>
        <div className="selectUserButtonContainer">
          <ActionButton
            ariaLabel='Create User'
            className='modifyUsersButton add'
            variant="solid"
            handleClick={() => {
              setModalContents(<CreateOneUserForm />);
            }}
          >
            Create New User
          </ActionButton>
          <ActionButton
            ariaLabel='Create User'
            className='modifyUsersButton update'
            variant="solid"
            handleClick={() => {
              setModalContents(<AddExistingUserToEstateForm />);
            }}
          >
            Add Existing User to this Estate
          </ActionButton>
        </div>
      </div>

      <hr />

      <Stack className='userSelectStack'>
        {
          usersOnEstate?.estate?.users?.map(({
            user,
            userRole,
          }, index) => (
            <Card className="userSelectCard" key={index}>
              <Typography variant='body1'>
                {`${user.firstName} ${user.lastName} - ${userRole}`}
              </Typography>
              <div className='userSelectButtonContainer'>
                <ActionButton
                  ariaLabel='Remove User'
                  variant="solid"
                  className='dark remove'
                  handleClick={() => handleRemoveUserFromEstateClick(user.id)}
                >
                  Remove user from estate
                </ActionButton>
                <ActionButton
                  ariaLabel='Update User'
                  variant="solid"
                  className='dark update'
                  handleClick={() => handleUpdateUserClick(user.id, <UpdateOneUserForm />)}
                >
                  Update user
                </ActionButton>
              </div>
            </Card>
          ))
        }
      </Stack>

    </div>
  )
}

export default SelectUser