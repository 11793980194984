import { FC, useContext, useEffect } from 'react'
import './ManagementLayout.scss'
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton'
import { ManagementContext } from '../../providers/ManagementProvider'

interface ManagementLayoutProps {
  testId?: string,
  createForm?: JSX.Element,
  customerView?: JSX.Element,
  buttonText?: string,
}

const ManagementLayout: FC<ManagementLayoutProps> = ({
  testId,
  createForm,
  customerView,
  buttonText,
}) => {
  const { formToDisplay, setFormToDisplay } = useContext(ManagementContext)

  useEffect(() => {
    if (createForm) {
      setFormToDisplay(createForm)
    }
  }, [createForm])

  return (
    <div data-testid={testId} className='managementLayoutContainer'>
      <div className='leftBlock'>
        <div className='customerView'>
          {customerView}
        </div>
        <ActionButton
          ariaLabel='Show Create Form' 
          handleClick={() => {
            setFormToDisplay(createForm)
          }}
          variant='solid'
          className='showCreateFormButton dark'
        >
          { buttonText || "Show Create Form"}
        </ActionButton>
      </div>
      <div className='formView'>
        {formToDisplay}
      </div>
    </div>
  )
}

export default ManagementLayout
