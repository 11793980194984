import { FC } from 'react';
import { ReactComponent as BellIcon } from '../../../../assets/icons/functionalityIcons/bell.svg';

interface NotificationBellProps {
  className?: string;
  width?: number;
  height?: number;
}

// TODO: EVENTUALLY WE WILL NEED TO DISPlAY A RED DOT ON THE BELL ICON WHEN THERE ARE UNREAD NOTIFICATIONS

const NotificationBell: FC<NotificationBellProps> = ({
  className, width=24, height=24,
}) => (
  <BellIcon 
    style={{
      width,
      height,
    }} 
    className={className || ''} 
  />
);

export default NotificationBell;