import { FC } from 'react'
import './EstateComponentSummarySubtitle.scss'
import Typography from '../../../atoms/Typography/Typography'
import { EstateComponent } from '../../../../../views/EstateView'

interface EstateComponentSummarySubTitleProps {
  testId?: string,
  component: EstateComponent
}

const EstateComponentSummarySubTitle: FC<EstateComponentSummarySubTitleProps> = ({
  testId,
  component,
}) => {
  const {
    name, descriptor, accountNumber,
    address, city, state, zip,
    subType,
  } = component;

  switch (subType) {
  case 'RealEstate':
    return (
      (address || city || state || zip) ?
        <div data-testid={testId} className='estateComponentSummarySubtitleContainer'>
          {address && <Typography variant='P1' font='UI1' className='componentAddress'>{address}, </Typography>}
          {city && <Typography variant='P1' font='UI1'  className='componentAddress'>{city}, </Typography>}
          {state && <Typography variant='P1' font='UI1'  className='componentAddress'>{state}, </Typography>}
          {zip && <Typography variant='P1' font='UI1'  className='componentAddress'>{zip}</Typography>}
        </div>
        :
        <div data-testid={testId} className='estateComponentSummarySubtitleContainer'>
          <Typography variant='P1' font='UI1'  className='componentName'>{name}</Typography>
        </div>  
    )
  case 'BankAccount':
    return (
      <div data-testid={testId} className='estateComponentSummarySubtitleContainer'>
        <Typography variant='P1' font='UI1'  className='componentName'>{descriptor}</Typography>
        {accountNumber && <Typography variant='P1' font='UI1'  className='componentAccountNumber'>{accountNumber}</Typography>}
      </div>
    )
  default:
    return (
      <div data-testid={testId} className='estateComponentSummarySubtitleContainer'>
        <Typography variant='P1' font='UI1'  className='componentName'>{descriptor}</Typography>
      </div>
    )
  }
}

export default EstateComponentSummarySubTitle