import { Box, Container, FormControl, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, TextField, Typography } from '@mui/material'
import { FC, useContext, useState } from 'react'
import AuthViewLayout from '../AuthViewLayout'
// import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton'
import './iForgot.scss';
import { AccountContext } from '../../../providers/Authentication'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import LinkButton from '../../../components/DesignSystem/atoms/LinkButton/LinkButton';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../../config/policy-links';

interface ForgotPasswordProps {
  testId?: string
}

const ForgotPassword: FC<ForgotPasswordProps> = ({testId}) => {
  // phase 1 - get email sent
  const [email, setEmail] = useState('');
  const [otpCodeSent, setOtpCodeSent] = useState(false);

  // phase 2 - enter password and OTP code
  const [otpCode, setOtpCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConformNewPassword] = useState('');
  const [newPasswordSet, setNewPasswordSet] = useState(false);

  const {
    sendOtpCode,
    resetPassword,
  } = useContext(AccountContext);

  const handleSubmit = async () => {
    const errorMessages = [];
    // Ensure that an email is submitted if an otpCode has not been sent
    if (!email && !otpCodeSent) errorMessages.push("Please include your email address");
    else if (otpCodeSent) { // Otherwise check that the necessary info has been included
      if (!otpCode) errorMessages.push("Please enter the otpCode that was sent to your email");
      if (!newPassword) errorMessages.push("Please enter a new password");
      else if (newPassword !== confirmNewPassword) errorMessages.push("The two passwords do not match");
    }

    // Handle detected errors
    if (errorMessages.length > 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    // Take action
    if (!otpCodeSent) await sendOtpCode(email).then(() => { setOtpCodeSent(true); });
    else await resetPassword(email, otpCode, newPassword).then(() => {
      setNewPasswordSet((prevState) => !prevState);
    }).catch(console.error);
  }

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState);
  }

  let subHeaderMessage = '';
  switch (true) {
  case newPasswordSet:
    subHeaderMessage = 'Please use this new password on your next login'
    break;
  case otpCodeSent:
    subHeaderMessage = 'Please update your password to one of your choosing';
    break;
  default:
    subHeaderMessage = 'Please tell us where to send a password reset link.';
    break;
  }

  return (
    <AuthViewLayout data-testid={testId}>
      <div className="resetPasswordContainer">
        <Typography variant="h1" className='light'>
          {newPasswordSet ? "Your password has been updated" : "Let's fix this"}
        </Typography>
        <Typography variant="body1" className='light'>
          {subHeaderMessage}
        </Typography>

        {
          newPasswordSet ? (
            <LinkButton
              href="/login"
              ariaLabel={"Return to Login Page"}
              variant={"solid"}
              className="returnToLoginPageLink"
            >
              Go back to login
            </LinkButton>
          ) : (
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="resetPasswordFormContainer"
              noValidate
            >
              {
                !otpCodeSent ? (
                  // Only need an email address for the first step
                  <TextField
                    className="emailAddressInput light"
                    required
                    id="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    onChange={(event) => (setEmail(event.target.value.trim()))}
                    autoComplete="email"
                    autoFocus
                  />
                ) : (
                  <>
                    {/* Show otp input */}
                    <TextField
                      className="otpCodeInput light"
                      required
                      id="otpCode"
                      label="OTP Code"
                      variant="standard"
                      name="otpCode"
                      onChange={(event) => (setOtpCode(event.target.value.trim()))}
                      autoComplete="otpCode"
                    />

                    {/* new password */}
                    <FormControl required variant="outlined" className="hiddenInputContainer">
                      {/* <InputLabel htmlFor="newPasswordInput">Select a New Password</InputLabel> */}
                      <TextField
                        id="newPasswordInput"
                        name="newPassword"
                        label="Select a New Password"
                        className="light"
                        variant="standard"
                        required
                        value={newPassword}
                        type={showPassword ? 'text' : 'password'}
                        onChange={(event) => (setNewPassword(event.target.value.trim()))}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>

                    {/* Confirm Password Again */}
                    <FormControl required variant="outlined" className="hiddenInputContainer lastInput">
                      {/* <InputLabel htmlFor="confirmPasswordInput">Please Enter it Again</InputLabel> */}
                      <TextField
                        id="confirmPasswordInput"
                        type={showPassword ? 'text' : 'password'}
                        label="Please Enter it Again"
                        variant="standard"
                        required
                        className="light"
                        value={confirmNewPassword}
                        onChange={(event) => (setConformNewPassword(event.target.value.trim()))}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </>
                )
              }

              <Container className="buttonsContainer">
                <ActionButton
                  type="submit"
                  ariaLabel="Reset Password"
                  variant="solid"
                  className="resetPasswordButton"
                >
                  {otpCodeSent ? "Save Password" : "Send one time reset code"}
                </ActionButton>
                <Typography variant="body1" className="serviceAgreementText light">
                  {`By clicking “Sign in” you agree to Alix’s `}
                  <Link href={TERMS_OF_SERVICE}>Terms of Service</Link>
                  {` and `}
                  <Link href={PRIVACY_POLICY}>Privacy Notice</Link>
                  {`.`}
                </Typography>
                {
                  !otpCodeSent && (
                    <Link
                      href="/login"
                      variant="body1"
                      className="returnToLogin"
                    >
                      I know my password
                    </Link>
                  )
                }
              </Container>
            </Box>
          )
        }
      </div>
    </AuthViewLayout >
  )
}

export default ForgotPassword;