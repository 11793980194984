import './crawlContent.scss';

const CrawlContent = () => {
  return (
    <div className="crawl">
      <div className="crawl-content">
        <p>A long time ago, in a galaxy far, far away....</p>
        <h1>Episode IV</h1>
        <h2>A NEW HOPE</h2>
        <p>{`It is a period of civil war. Rebel spaceships, striking from a hidden base, have won their first victory against the evil Galactic Empire.`}</p>
        <br />
        <p>{`During the battle, Rebel spies managed to steal secret plans to the Empire's ultimate weapon, the DEATH STAR, an armored space station with enough power to destroy an entire planet.`}</p>
        <br />
        <p>{`Pursued by the Empire's sinister agents, Princess Leia races home aboard her starship, custodian of the stolen plans that can save her people and restore freedom to the galaxy`}</p>
      </div>
    </div>
  );
};

export default CrawlContent;