import { FC, useContext, useState } from 'react'
import styles from './CreateNotificationByGroupForm.module.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCreateNotificationByGroupMutation, CreateNotificationByGroupInput, NotificationGroup, RouteElementInput, RelatedElementType } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, TextField, Switch } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import Typography from '../../../DesignSystem/atoms/Typography/Typography';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import Card from '../../../DesignSystem/atoms/Card/Card';
import { useDecision } from '@optimizely/react-sdk';

type CreateNotificationByGroupFormProps = {
  linkedToName?: string,
  routeElements?: RouteElementInput[],
  setNotificationData?: () => void,
  relatedElementType?: null | RelatedElementType,
  testId?: string,
}

const CreateNotificationByGroupForm: FC<CreateNotificationByGroupFormProps> = ({ 
  linkedToName = '',
  routeElements = null,
  setNotificationData,
  relatedElementType=null,
  testId,
}) => {

  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const [createNotificationByGroupMutation] = useCreateNotificationByGroupMutation();
  const { selectedEstateId } = useContext(EstateContext);
  const [options, _setOptions] = useState<any>({
    "group": [
      {
        "submitValue": "EXECUTOR",
        "displayValue": "Executor",
      },
      {
        "submitValue": "ALL",
        "displayValue": "All",
      },
      {
        "submitValue": "BENEFICIARY",
        "displayValue": "Beneficiary",
      },
    ],
  })
  const [decision, clientReady] = useDecision('push_notification');

  const handleSubmit = async (
    values: CreateNotificationByGroupInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as CreateNotificationByGroupInput

    // strip off the leading/trailing white space
    const formattedValues = {
      message: nestedValues.message.trim(),
      title: nestedValues.title.trim(),
      group: nestedValues.group,
      estateId: nestedValues.estateId,
      enablePush: nestedValues.enablePush,
      relatedElementType,
      routeElements,
    }

    createNotificationByGroupMutation({
      variables: { data: formattedValues },
      onCompleted: (data: any) => {
        setDisplayModal(false);
        setNotificationData && setNotificationData()
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed createNotificationByGroupMutation call");
        console.error(e.message);
        alert(e.message);
      },
    });
  }

  const initialValues = {
    estateId: selectedEstateId || "",
    enablePush: false,
    group: NotificationGroup.Executor,
    message: "",
    title: "",
  };

  const validationSchema = Yup.object().shape({
    estateId: Yup.string().required("estateId is required"),
    enablePush: Yup.boolean(),
    group: Yup.string().required("group is required"),
    message: Yup.string()
      .trim()
      .required("a non-blank message is required"),
    title: Yup.string()
      .trim()
      .required("a non-blank title is required")
      .max(150, "title must be less than 150 characters"),
  });

  return (
    <Card data-testid={testId} className={styles.createNotificationByGroupFormContainer}>
      <Typography font='UI1' variant='H4' className={styles.createNotificationByGroupFormHeader}>
        Notify the customer
      </Typography>

      {
        linkedToName && (
          <>
            <Typography font='UI1' variant='P1' className={styles.linkedToHeader + ' ' + styles.centeredText}>
              {`This notification will be linked to`}
            </Typography>
            <Typography font='UI1' variant='H6' className={styles.linkedToMessage + ' ' + styles.centeredText}>
              {linkedToName}
            </Typography>
          </>
        )
      }
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Field
              name={"estateId"}
              type={"hidden"}
              value={values.estateId}
            />

            <div className={styles.topRowInputsContainer}>

              <div className={"inputWrapper dropdownInputWrapper " + styles.groupFieldWrapper} key={"group1"} data-testid={testId}>
                <Autocomplete
                  options={options.group || []}
                  getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                  renderInput={(params: any) =>
                    <Field
                      {...params}
                      className={"groupInput fieldInput dropdownInput"}
                      required={true}
                      id={"group"}
                      label={"Group"}
                      name={"group"}
                      type={"text"}
                      autoComplete={"group"}
                      as={TextField}
                      error={submitCount > 0 && touched.group && !!errors.group}
                    />
                  }
                  defaultValue={options.group.find((option: any) => option.submitValue === initialValues.group)}
                  onChange={(_, value) => {
                    setFieldValue("group", value?.submitValue)
                  }}
                />
                <ErrorMessage name={"group"} component="div" className="inputErrorMessage" />
              </div>

              <div className={"inputWrapper " + styles.titleFieldWrapper}>
                <Field
                  className={"fieldInput titleInput " + styles.titleField}
                  required={true}
                  id={"title"}
                  label={"Title"}
                  name={"title"}
                  type={"text"}
                  autoComplete={"title"}
                  as={TextField}
                  error={submitCount > 0 && touched.title && !!errors.title}
                />
                <ErrorMessage name={"title"} component="div" className="inputErrorMessage" />
              </div>
            </div>

            <Field
              className={"fieldInput textInput " + styles.messageInput}
              required={true}
              id={"message"}
              label={"Message"}
              name={"message"}
              type={"text"}
              autoComplete={"message"}
              as="textarea"
              renderInput={(params: any) => <TextField className={styles.messageInput} {...params} />}
              error={submitCount > 0 && touched.message && !!errors.message}
            />
            <ErrorMessage name={"message"} component="div" className="inputErrorMessage" />

            {clientReady && decision.enabled && (
              <div className={styles.enablePushFieldWrapper}>
                <Typography font='UI1' variant='P1'>{`Send Push Notification(s)`}</Typography>
                <Field
                  as={Switch}
                  type="checkbox"
                  name={`enablePush`}
                  id="enablePush"
                  label="Send Push Notification"
                  error={submitCount > 0 && touched.enablePush && !!errors.enablePush}
                />
                <ErrorMessage name={`enablePush`} component="div" className="inputErrorMessage" />
              </div>
            )}

            <div className={styles.buttonsContainer}>
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className={styles.button}
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Send Notification"
                variant="solid"
                className={[styles.button, styles.sendButton, 'dark'].join(" ")}
              >
                SEND
              </ActionButton>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default CreateNotificationByGroupForm