import { FC, useContext, useEffect, useState } from 'react'
import styles from './RecentActivityWrap.module.scss'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'
import RecentActivityList from '../../../components/DesignSystem/molecules/RecentActivityList/RecentActivityList'
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton'
import { useGetRecentActivityQuery } from '../../../types/graphql'
import { EstateContext } from '../../../providers/EstateProvider'

interface RecentActivityWrapProps {
  testId?: string
  className?: string
  wrap?: boolean
  gap?: number
  listItemWidth?: number
  listItemMaxWidth?: string
  flex?: string
}

const RecentActivityWrap: FC<RecentActivityWrapProps> = ({
  testId, className, wrap=true, gap=20, listItemWidth=387, listItemMaxWidth, flex,
}) => {
  const { selectedEstateId } = useContext(EstateContext)
  const [ showMoreButton, setShowMoreButton ] = useState(false)
  const [ showMoreRecentActivity, setShowMoreRecentActivity ] = useState(false)
  const [recentActivity, setRecentActivity] = useState<any[]>([])

  useEffect(() => {
    const maxHeight = 425; // max height in pixels
    // MutationObserver watches for changes in the DOM
    const observer = new MutationObserver(() => {
      const element = document.getElementById('recent-activity-list-id') as HTMLElement;
      if (element) {
        if (element.scrollHeight > maxHeight) {
          // Content is overflowing, show the "See More" button
          setShowMoreButton(true);
        } else {
          // Content is not overflowing, hide the "See More" button
          setShowMoreButton(false);
        }
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true, 
    });

    return () => observer.disconnect(); // Clean up on component unmount
  }, [recentActivity]);

  // useRecentActivityListQuery
  useGetRecentActivityQuery(
    {
      variables: {estateId: selectedEstateId},
      onCompleted: (data) => {
        setRecentActivity(data.getUserEstate?.recentActivity || [])
      },
    },
  )
  
  return (
    <div 
      data-testid={testId} 
      className={`${styles.recentActivityWrapContainer} ${className}`}
    >
      <div className={styles.content}>
        <RecentActivityList 
          className={`${styles.recentActivityList} ${showMoreRecentActivity && styles.showMore}`}
          id='recent-activity-list-id'
          wrap={wrap}
          gap={gap}
          listItemWidth={listItemWidth}
          listItemMaxWidth={listItemMaxWidth}
          flex={flex}
          data={recentActivity}
        />
        {showMoreButton && 
          <ActionButton
            ariaLabel="View More Activity" 
            variant='solid'
            className={`${styles.viewMoreActivityButton} transparent`}
            id='see-more-button-id'
            handleClick={() => setShowMoreRecentActivity(!showMoreRecentActivity)}
          >
            <Typography className={styles.buttonText} font="UI1" variant="L1" fontWeight="medium">
              {showMoreRecentActivity ? 
                "Hide Activity" : 
                "View More Activity"
              }
            </Typography>
          </ActionButton>
        }
      </div>
    </div>
  )
}

export default RecentActivityWrap
