// React components
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom-v5-compat";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

// Alix-specific components and assets
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Account } from "./providers/Authentication/Account";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Account>
        <App />
      </Account>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
