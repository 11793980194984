import { FC, useRef } from 'react'
import './List.scss'
import { Typography } from '@mui/material'
import useScrollToObserver from '../../../../hooks/useScrollToObserver'

interface ListProps {
  testId?: string
  data?: any[]
  className?: string
  renderItem: (item: any, index: number, parentRef: any) => React.ReactNode
  gap?: number
  direction?: 'horizontal' | 'vertical'
  flex?: number | string
  listItemWidth?: number | string
  listItemMaxWidth?: number | string
  listItemMinWidth?: number | string
  wrap?: boolean
}

const List: FC<ListProps> = ({
  testId,
  className = '',
  data,
  renderItem,
  gap = 0,
  direction = 'vertical',
  flex = 0,
  listItemWidth = 'auto',
  listItemMaxWidth = 'auto',
  listItemMinWidth,
  wrap = true,
}) => {
  const parentRef = useRef(null);

  return (
    <ul data-testid={testId} className={`scrollableList ${direction} ${wrap && 'wrap'} ${className}`} ref={parentRef} style={{ gap: `${gap}px` }}>
      {data?.map((item, index) => {
        return (
          <li key={`list-${index}`} style={{
            flex: flex,
            minWidth: listItemMinWidth || direction === 'horizontal' ? listItemWidth : 'auto',
            width: listItemWidth,
            maxWidth: listItemMaxWidth,
          }}>
            {renderItem(item, index, parentRef)}
          </li>
        )
      })}
    </ul>
  )
}

export default List
