import { FC } from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/functionalityIcons/alert.svg';

interface AlertIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const InfoIcon: FC<AlertIconProps> = ({
  className, width = 24, height = 24,
}) => (
  <Icon
    style={{
      width,
      height,
    }}
    className={className || ''}
  />
);

export default InfoIcon;