import { Stack, Typography } from '@mui/material'
import { FC, useContext } from 'react'

import './AdminToolBar.scss';
import SelectAnEstate from '../SelectAnEstate/SelectAnEstate';
import { AccountContext } from '../../../providers/Authentication';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../providers/EstateProvider';
import { LayoutContext } from '../../../providers/LayoutProvider';
import AdminCreateEstateWithDeceasedForm from '../../FormikForms/admin/AdminCreateEstateWithDeceasedForm/AdminCreateEstateWithDeceasedForm';

interface AdminToolBarProps {
  testId?: string
}

const AdminToolBar: FC<AdminToolBarProps> = ({testId}) => {
  const {showAdminTools, setShowAdminTools} = useContext(AccountContext);
  const {setCurrentEstate} = useContext(EstateContext);
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext);

  const handleAddEstateClick = () => {
    setModalContents(<AdminCreateEstateWithDeceasedForm />)
    setDisplayModal(true)
  }

  return (
    <div data-testid={testId} className='adminToolBarContainer'>
      {showAdminTools ? 
        <button aria-label='hide admin tools' onClick={() => setShowAdminTools(false)}>
          <Visibility />
        </button> 
        : 
        <button aria-label='show admin tools' onClick={() => setShowAdminTools(true)}>
          <VisibilityOff />
        </button>
      }
      <SelectAnEstate />
      <ActionButton 
        ariaLabel='Open add an Estate Popup'
        trackClick={false}
        handleClick={handleAddEstateClick}
        className='addEstateButton'
      >
        <AddIcon />
      </ActionButton>
    </div>
  )
}

export default AdminToolBar;