import { FC, MouseEvent, KeyboardEvent } from 'react';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import CloseIcon from '../../atoms/CloseIcon/CloseIcon';
import styles from './CloseIconAsActionButton.module.scss';

interface NotificationBellAsLinkProps {
  closeIconClassName?: string;
  className?: string;
  clickHandler: () => void;
  testId?: string;
}

const NotificationBellAsLink: FC<NotificationBellAsLinkProps> = ({
  closeIconClassName, className, clickHandler, testId,
}) => {

  const containerClassName = [styles.CloseIconAsActionButtonContainer, className].filter(str => !!str).join(' ');

  return (
    <ActionButton
      ariaLabel="Notifications"
      tabIndex={0}
      className={containerClassName}
      testId={testId}
      handleClick={clickHandler}
    >
      <CloseIcon className={closeIconClassName}/>
    </ActionButton>
  );
};

export default NotificationBellAsLink;