import { FC, useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import './Headers.scss';
import { EstateContext } from '../../providers/EstateProvider';
import { convertTimestampToHumanReadable2, makeNamePossessive } from '../../helpers/functions';
import { AccountContext } from '../../providers/Authentication';
import ActionButton from '../DesignSystem/atoms/ActionButton/ActionButton';
import CreateOneDeceasedForm from '../FormikForms/admin/CreateOneDeceasedForm/CreateOneDeceasedForm';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { LayoutContext } from '../../providers/LayoutProvider';
import UpdateOneDeceasedForm from '../FormikForms/admin/UpdateOneDeceasedForm/UpdateOneDeceasedForm';
import SelectUser from '../SelectUser/SelectUser';
import { ReactComponent as AlixLogo } from '../../assets/icons/logos/alix-logo.svg';
import MenuDropdown from '../MenuDropdown/MenuDropdown';
import useFileUpload from '../../hooks/useFileUpload';
import NotificationBellAsLink from '../DesignSystem/molecules/NotificationBellAsLink/NotificationBellAsLink';
import { useScreenWidth } from '../../helpers';
import NotificationBellAsPopupMenu from '../DesignSystem/molecules/NotificationBellAsPopupMenu/NotificationBellAsPopupMenu';
import { useDecision } from '@optimizely/react-sdk';

interface HeaderProps {
  fixed?: boolean;
}

const Header: FC<HeaderProps> = ({ fixed = false }) => {
  const screenWidth = useScreenWidth();
  const { hasAdminAuthorization } = useContext(AccountContext)
  const [notificationFlag] = useDecision('notifications_feature', {autoUpdate: true});

  return (
    <div id="appHeader" className={`headerContainer ${fixed && 'fixed'}`}>
      <AlixLogo className='headerLogoSvg' />

      <div className="rightSide">
        { notificationFlag.enabled && !hasAdminAuthorization && 
        <div className="bellContainer">
          {screenWidth < 800 ?
            <NotificationBellAsLink />
            :
            <NotificationBellAsPopupMenu />
          }
        </div>}
        <MenuDropdown />
      </div>
    </div>
  );
};

const SubHeader: FC<{
  text?: string,
  altTitleText?: string,
  adminDeceasedTools?: boolean,
  adminDocumentTools?: boolean,
  adminCustomerTools?: boolean,
}> = ({
  text = "Together, we're settling the estate",
  altTitleText = "Settlement Plan",
  adminDeceasedTools,
  adminDocumentTools,
  adminCustomerTools,
},
) => {
  const { deceased, currentEstate } = useContext(EstateContext)
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const { showAdminTools } = useContext(AccountContext)
  const location = useLocation();
  const { handleUploadSubmit } = useFileUpload();
  const [headingTitle, setHeadingTitle] = useState('')
  const [deceasedName, setDeceasedName] = useState('')

  useEffect(() => {
    const deceasedDisplayName = deceased ? [deceased.firstName, deceased.lastName].join(' ') : '';
    setHeadingTitle(`${deceased ? text + ' of ' : text}`)
    setDeceasedName(`${deceased ? deceasedDisplayName : ''}`)
  }, [deceased])

  const handleAdminClick = (formComponent: ReactJSXElement) => {
    setModalContents(formComponent)
    setDisplayModal(true)
  }

  return (
    <div id="appSubheader" className="subheaderContainer">
      <div className="headerTitle">
        <Typography variant={"h2"} align="left" className='title'>{headingTitle}</Typography>
        <Typography variant={"h1"} align="left" className='subtitle'>{deceasedName}</Typography>
        {deceasedName && <Typography variant={"h6"} align="left" className='altSubtitle'>{`${makeNamePossessive(deceasedName)} Estate`}</Typography>}
        <Typography variant={"h2"} align="left" className='altTitle'>{altTitleText}</Typography>
        {deceased && deceased?.dateOfBirth && deceased.dateOfDeath && location.pathname !== '/plan' &&
            <Typography
              align='left'
              className="subtitleDate"
              variant="body1"
            >{`${convertTimestampToHumanReadable2(deceased?.dateOfBirth)} - ${convertTimestampToHumanReadable2(deceased?.dateOfDeath)}`}</Typography>
        }
      </div>
      <div className="adminHeaderTools">
        {showAdminTools && adminDeceasedTools &&
            (
              !deceased ?
                <ActionButton
                  ariaLabel='Open Add Deceased Form'
                  handleClick={() => handleAdminClick(<CreateOneDeceasedForm />)}
                  className='addDeceasedButton'
                >
                  Add Deceased to Estate
                </ActionButton>
                :
                <ActionButton
                  ariaLabel='Open Update Deceased Form'
                  handleClick={() => handleAdminClick(<UpdateOneDeceasedForm />)}
                  className='addDeceasedButton'
                >
                  Update Deceased
                </ActionButton>
            )
        }

        {/* TODO: THIS ACTION BUTTON MAY NEED TO CHANGE TO A CREATE CUSTOMER IF THERE ARE NO USERS ON THE ESTATE */}
        {showAdminTools && adminCustomerTools &&
            <ActionButton
              ariaLabel='Open Select User Form'
              handleClick={() => handleAdminClick(<SelectUser />)}
              className='updateCustomerButton'
            >
              Modify Customers/Users on Estate
            </ActionButton>
        }
      </div>
    </div>
  );
}

export {
  Header,
  SubHeader,
};