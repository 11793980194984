// Built in React Components
import { FC, useContext, useEffect, useMemo, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom-v5-compat";

// Third Party Libraries
import _debounce from 'lodash/debounce';
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider } from "@emotion/react";
import { Amplify, Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

// Alix-specific Pages and Routing setup
import buildTheme from "./styles/theme";
import NoMatch from "./views/NoMatch";

// Views
import EstateComponentDetailsManagement from './views/management/EstateComponentDetailsManagement/EstateComponentDetailsManagement';
import SignUp from "./views/SignUp";
import EstateView from "./views/EstateView";
import CareTeamView from "./views/CareTeam/";
import { HomeView } from "./views/Home";
import AcceptEula from "./views/Authentication/AcceptEula/";
import DeceasedInformation from "./views/Authentication/DeceasedInformation/DeceasedInformation";
import Playground from "./views/Playground/Playground";
import { AccountContext } from "./providers/Authentication";
import ControlledRoute from "./components/ControlledRoute/ControlledRoute";
import { Login, ResetNewUserPassword, ForgotPassword, CustomerRegister } from "./views/Authentication/index";
import DesignView from "./views/Design/Design";
import { ActionDetails } from "./views/ActionDetails";
import { ActionProvider } from "./providers/ActionProvider";
import { EstateProvider } from "./providers/EstateProvider";
import { ManagementProvider } from "./providers/ManagementProvider";
import { LayoutProvider } from "./providers/LayoutProvider";
import { AdminLanding } from "./views/AdminLanding";
import { EstateComponentProvider } from './providers/EstateComponentProvider';
import "./styles/index.scss";

// Configure aws amplify
import awsConfig from './aws-exports';
import { UserProvider } from "./providers/UserProvider";
import { Documents } from "./views/Documents";
import { DocumentProvider } from "./providers/DocumentProvider";
import EstateComponentDetails from "./views/EstateComponentDetails";
import NotificationsManagement from "./views/management/NotificationsManagement/NotificationsManagement";
import NotificationsView from "./views/Notifications/NotificationsView";
import { useScreenWidth } from "./helpers";
import { ContentExplorerRoute } from "./views/ContentExplorer";
Amplify.configure(awsConfig);

const App: FC = () => {
  const mode = "light";
  const theme = useMemo(() => (buildTheme(mode)), [mode]);
  const screenWidth = useScreenWidth();

  const {
    optimizelyID, refreshSession, hasAdminAuthorization, userId, userName,
  } = useContext(AccountContext);

  // Set up the Apollo Client
  const httpLink = createHttpLink({ uri: process.env.REACT_APP_ALIX_GRAPHQL_API });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({
        message, locations, path,
      }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
        ),
      );

      const needsRefresh = graphQLErrors?.some(({ message }) => (message.includes("Access denied! You need to be authorized to perform this action!")));

      if (needsRefresh) refreshSession();
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const authLink = setContext(async (_, { headers }) => {
    // Get the authentication token from AWS Amplify
    let accessToken = '';
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const session = await Auth.currentSession();
      if (session) {
        accessToken = session.getAccessToken().getJwtToken();
      }
    }

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer: ${accessToken}` : '',
        'x-client-platform': "web",
      },
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  // Automatic logout functionality
  const { logout } = useContext(AccountContext);

  const logoutTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const startLogoutTimer = () => {
    // only have logout timer if node_env is not local
    if (process.env.NODE_ENV === 'development') return;
    logoutTimer.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
  };

  const resetLogoutTimer = () => {
    // return if the node_env is local
    if (process.env.NODE_ENV === 'development') return;

    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
    startLogoutTimer();
  };

  useEffect(() => {
    startLogoutTimer();
    // executeAfterRefresh();
    const handleUserActivity = _debounce(resetLogoutTimer, 300);

    // Attach event listeners for user activity events
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      if (logoutTimer.current) clearTimeout(logoutTimer.current);
      handleUserActivity.cancel();
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  });

  useEffect(() => {
    console.log('Version: ', process.env.REACT_APP_VERSION);
    const storeRefreshFlag = () => {
      localStorage.setItem('refreshFlag', 'true');
    };

    // Register the event listener for the beforeunload event
    window.addEventListener('beforeunload', storeRefreshFlag);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', storeRefreshFlag);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <OptimizelyProvider
              optimizely={createInstance({
                sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
                logLevel: process.env.NODE_ENV === 'production' ? "INFO" : "ERROR",
              })}
              user={{
                id: optimizelyID,
                attributes: {
                  isAdmin: hasAdminAuthorization,
                  userId: userId,
                  userName: userName,
                },
              }}
            >

              <ManagementProvider>
                <UserProvider>
                  <EstateComponentProvider>
                    <ActionProvider>
                      <DocumentProvider>
                        <EstateProvider>
                          <LayoutProvider>
                            <ToastContainer position="top-center" />
                            <Routes>
                              {/* Unauthorized People can access these routes */}
                              <Route path="/" element={<Login />} />
                              <Route path="/login" element={<Login />} />
                              <Route path="/register" element={
                                <ControlledRoute
                                  body={<CustomerRegister />}
                                  optimizelyFlagIdentifier="self_registration"
                                />
                              } />
                              <Route path="/resetNewUserPassword" element={<ResetNewUserPassword />} />
                              <Route path="/iforgot" element={<ForgotPassword />} />
                              <Route path="/signup" element={<SignUp />} />
                              <Route path="/content-explorer" element={<ContentExplorerRoute />} />

                              {/* Authorized Customers can access these routes */}
                              <Route path="/acceptEula" element={
                                <ControlledRoute
                                  body={<AcceptEula />}
                                  requiresSession={true}
                                />
                              } />
                              <Route path="/actionDetails/:actionCardId" element={
                                <ControlledRoute
                                  body={<ActionDetails />}
                                  requiresSession={true}
                                />
                              } />
                              <Route path="/careteam" element={
                                <ControlledRoute
                                  body={<CareTeamView />}
                                  requiresSession={true}
                                />
                              } />
                              <Route path="/deceasedInfo" element={
                                <ControlledRoute
                                  body={<DeceasedInformation />}
                                  requiresSession={true}
                                  optimizelyFlagIdentifier="deceased_information_form"
                                />
                              } />
                              <Route path="/documents" element={
                                <ControlledRoute
                                  body={<Documents />}
                                  requiresSession={true}
                                  optimizelyFlagIdentifier="documents_page"
                                />
                              } />
                              <Route path="/estate" element={
                                <ControlledRoute
                                  body={<EstateView />}
                                  requiresSession={true}
                                />
                              } />
                              <Route path="/estateComponentDetails/:estateComponentId" element={
                                <ControlledRoute
                                  body={<EstateComponentDetails />}
                                  requiresSession={true}
                                  optimizelyFlagIdentifier="estate_component_details_page"
                                />
                              } />

                              {
                                screenWidth < 800 &&
                                  <Route path="/notifications" element={
                                    <ControlledRoute
                                      body={<NotificationsView />}
                                      requiresSession={true}
                                      optimizelyFlagIdentifier="notifications_feature"
                                    />
                                  } />
                              }

                              <Route path="/home" element={
                                <ControlledRoute
                                  body={<HomeView />}
                                  requiresSession={true}
                                />
                              } />

                              {/* Authorized Admins can access these routes */}
                              <Route path="/manage/home" element={
                                <ControlledRoute
                                  body={<HomeView />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />
                              <Route path="/manage/actionDetails/:actionCardId" element={
                                <ControlledRoute
                                  body={<ActionDetails />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />
                              <Route path="/manage/deceasedInfo" element={
                                <ControlledRoute
                                  body={<DeceasedInformation />}
                                  requiresSession={true}
                                  optimizelyFlagIdentifier="deceased_information_form"
                                  requiresAdminAuthorization={true}
                                />
                              } />
                              <Route path="/manage/documents" element={
                                <ControlledRoute
                                  body={<Documents />}
                                  requiresSession={true}
                                  optimizelyFlagIdentifier="documents_page"
                                  requiresAdminAuthorization={true}
                                />
                              } />
                              <Route path="/manage/estate" element={
                                <ControlledRoute
                                  body={<EstateView />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />

                              <Route path="/manage/estateComponentDetails/:estateComponentId" element={
                                <ControlledRoute
                                  body={<EstateComponentDetailsManagement />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />

                              <Route path="/manage/notifications" element={
                                <ControlledRoute
                                  body={<NotificationsManagement />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                  optimizelyFlagIdentifier="notifications_feature"
                                />
                              } />

                              <Route path="/manage/adminLanding" element={
                                <ControlledRoute
                                  body={<AdminLanding />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />

                              <Route path="/design" element={
                                <ControlledRoute
                                  body={<DesignView />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />

                              <Route path="/playground" element={
                                <ControlledRoute
                                  body={<Playground />}
                                  requiresSession={true}
                                  requiresAdminAuthorization={true}
                                />
                              } />


                              {/* Unable to find a page with this route */}
                              <Route path="*" element={<NoMatch />} />
                            </Routes>
                          </LayoutProvider>
                        </EstateProvider>
                      </DocumentProvider>
                    </ActionProvider>
                  </EstateComponentProvider>
                </UserProvider>
              </ManagementProvider>
            </OptimizelyProvider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </ApolloProvider >
  );
};

export default App;
