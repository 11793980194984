import { FC, useContext, useEffect, useState } from 'react'
import './FileListItem.scss'
import { CircularProgress } from '@mui/material'
import Typography from '../../DesignSystem/atoms/Typography/Typography'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider'
import FileTypeIcon from '../FileTypeIcon/FileTypeIcon'
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/fileTypes/download.svg'
import {ReactComponent as ActionsIcon} from '../../../assets/icons/fileTypes/vertical-dots.svg'
import {ReactComponent as RenameIcon} from '../../../assets/icons/fileTypes/pencil-line.svg'
import {ReactComponent as MoveIcon} from '../../../assets/icons/fileTypes/default-file.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/fileTypes/trash-can.svg'
import { convertTimestampToHumanReadable2 } from '../../../helpers'
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../providers/LayoutProvider'
import FilePopupOptions from '../FilePopupOptions/FilePopupOptions'
import useFileUpload from '../../../hooks/useFileUpload'
import RenameDocumentForm from '../../FormikForms/admin/RenameDocumentForm/RenameDocumentForm'
import { AccountContext } from '../../../providers/Authentication'
import DeleteOneFileItemForm from '../../FormikForms/admin/DeleteOneFileItemForm/DeleteOneFileItemForm'
import { useDeleteDocumentMutation } from '../../../types/graphql'
import { EstateContext } from '../../../providers/EstateProvider'
import MoveDocumentsForm from '../../FormikForms/generated/MoveDocumentsForm/MoveDocumentsForm'

interface FileListItemProps {
  file: IFile,
  type: 'upload' | 'download' | 'menu' | 'options' | 'deleted',
  tabIndex?: number,
  showCheckBox?: boolean,
} 

const FileListItem: FC<FileListItemProps> = ({
  file, type, tabIndex=0, showCheckBox=true,
}) => {
  const { showAdminTools } = useContext(AccountContext);
  const {
    setDisplayPopupModal, setPopupModalContents, setPopupModalTitle, setDisplayModal, setModalContents,
  } = useContext(LayoutContext);
  const { selectedEstateId } = useContext(EstateContext);
  const [checked, setChecked] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { downloadFile, viewFile } = useFileUpload();
  const {
    setSelectedFile, setFilesToMove, filesToMove, folderRefetch, directoryPath,
  } = useContext(DocumentContext);

  useEffect(() => {
    // if filesToMove is nul the uploadedFile then uncheck the checkbox
    if (!filesToMove) {
      // uncheck checkbox
      setChecked(false);
    } else {
      // if filesToMove is in the list of files to move then check the checkbox
      let fileChecked = false
      if(file)  fileChecked = filesToMove.some(fileToMove => fileToMove.id === file.id);
      setChecked(fileChecked);
    }
  }, [filesToMove])

  const handleRename = () => {
    file && setSelectedFile(file )
    setModalContents(<RenameDocumentForm/>)
    setDisplayModal(true)
  }

  const handleDownload = () => {
    file && downloadFile(file.signedUrl || '')
  }

  const handleMove = (documentId: string) => {
    setDisplayModal(true)
    setModalContents(<MoveDocumentsForm documentIds={[documentId]} estateId={selectedEstateId} refetch={folderRefetch[directoryPath]} />)
  }

  const handleCheckChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    setFilesToMove(prevState => {
      if(prevState && file) {
        if (newChecked) {
          return [...prevState, file];
        } else {
          const result = prevState.filter(item => item.name !== file.name);
          return result;
        }
      } else {
        return file ? [file] : []; // return an empty array when prevState is null or undefined
      }
    });
  };

  const handleAdminDeleteClick = () => {
    if (file) {
      setModalContents(<DeleteOneFileItemForm fileId={file.id|| ''} fileName={file.name} setDeleting={setDeleting}/>);
      setDisplayModal(true);
    } else {
      alert('Cannot delete a non-existing file');
    }
  };

  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    variables: {id: file.id|| ''},
    onCompleted: () => {
      // window.location.pathname === "/documents" ?
      folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]() //TODO: try adding this back
      // :
      // refetchDocumentsList()
    },
    onError: (error) => {
      console.error(`Error deleting document: ${file.name}`, error)
      alert(`Error deleting ${file.name}: ${error}`)
      setDeleting(false)
    },
  });

  const buildDisplayFileName = (fileName: string) => {
    // remove urlEncoding from fileName
    fileName = decodeURIComponent(fileName);

    return fileName;
  }

  return (
    <li 
      tabIndex={tabIndex} 
      className='fileListItem'
    >
      { showAdminTools && showCheckBox && <input
        type="checkbox"
        name={file.name || ''}
        onChange={handleCheckChange}
        checked={checked}
      />}
      <ActionButton
        ariaLabel='View File'
        handleClick={() => viewFile(file.signedUrl || '')}
        variant='solid'
        className='fileListItemContent'
        tabIndex={tabIndex}
      >
        <FileTypeIcon fileType={file.contentType || ''}/>
        <Typography variant='P1' font='UI1'>{ file.name || ''}</Typography>
      </ActionButton>

      {type === 'upload' &&
        <div className="endCap">
          <div className={`fileProgressCircle fileProgressBackground ${ file && file.uploadProgress > 99 && 'hide'}`}>
            <CircularProgress variant="determinate" value={file.uploadProgress} size={22}/>
          </div>

          {deleting?
            <div className={`fileProgressCircle fileProgressBackground`}>
              <CircularProgress size={22}/>
            </div>
            :
            <ActionButton 
              ariaLabel='Delete File'
              variant='solid'
              className={`endCap fileProgressComplete fileProgressBackground ${file.uploadProgress === 100 && 'show'}`}
              handleClick={() => {
                setDeleting(true)
                deleteDocumentMutation()
              }}
              tabIndex={tabIndex}
            >
              <CloseIcon />
            </ActionButton>
          }
        </div>
      }
      
      {type === 'download' &&
        <div className="endCap">
          <ActionButton 
            ariaLabel='Download File'
            variant='solid'
            className={'endCap downloadFileButton'}
            handleClick={() => {
              downloadFile(file.signedUrl || '')
            }}
            tabIndex={tabIndex}
          >
            <DownloadIcon className='downloadIcon'/>
          </ActionButton>
        </div>
      }

      {type === 'menu' &&
        <div className="endCap">
          <ActionButton 
            ariaLabel='Download File'
            variant='solid'
            className={'endCap'}
            handleClick={() => {
              setPopupModalTitle(file.name)
              if(file) setPopupModalContents(
                <FilePopupOptions file={file}/>,
              )
              setDisplayPopupModal(true)
            }}
            tabIndex={tabIndex}
          >
            <ActionsIcon />
          </ActionButton>
        </div>
      }

      {
        type === 'options' &&
        <div className="endCap fileOptions">
          <div className='fileDate'>
            {file && <Typography variant='P1' font="UI1">{convertTimestampToHumanReadable2(new Date(file.uploadedAt || Date.now()).toISOString())}</Typography>}
          </div>
          <div className='optionButtonContainer'>
            <ActionButton 
              ariaLabel='Open Move File Form'
              variant='solid'
              className='iconBackground'
              handleClick={() => handleMove(file.id || '')}
              tabIndex={tabIndex}
            >
              <MoveIcon className='optionIcon'/>
            </ActionButton>
          </div>
          <div className='optionButtonContainer'>
            <ActionButton 
              ariaLabel='Rename File'
              variant='solid'
              className='iconBackground'
              handleClick={handleRename}
              tabIndex={tabIndex}
            >
              <RenameIcon className='optionIcon'/>
            </ActionButton>
          </div>
          <div className='optionButtonContainer'>
            <ActionButton 
              className='popupIconBackground'
              variant='solid'
              ariaLabel='Download File'
              handleClick={handleDownload}
              tabIndex={tabIndex}
            >
              <DownloadIcon className='optionIcon'/>
            </ActionButton>
          </div>

          {showAdminTools && (deleting?
            <div className={`fileProgressCircle fileProgressBackground`}>
              <CircularProgress size={22}/>
            </div>
            :
            <ActionButton 
              ariaLabel='Delete File'
              variant='solid'
              // className={`endCap`}
              handleClick={handleAdminDeleteClick}
              tabIndex={tabIndex}
            >
              <DeleteIcon className='optionIcon' />
            </ActionButton>)
          }
        </div>
      }
    </li>
  )
}

export default FileListItem