import { FC, useContext, useState, useEffect } from 'react'
import styles from './NotificationsManagement.module.scss'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'
import { UserLayout } from '../../../components/Layouts'
import NotificationManagementList from '../../../components/DesignSystem/molecules/NotificationManagementList/NotificationManagementList'
import CreateNotificationByGroupForm from '../../../components/FormikForms/admin/CreateNotificationByGroupForm/CreateNotificationByGroupForm'
import { NotificationLogEntry, useGetNotificationLogQuery } from '../../../types/graphql'
import { EstateNotification } from '../../../types/AppTypes'
import { EstateContext } from '../../../providers/EstateProvider'
import Card from '../../../components/DesignSystem/atoms/Card/Card'

interface NotificationsManagementProps {
  testId?: string,
}

const NotificationsManagement: FC<NotificationsManagementProps> = ({ testId }) => {
  const {selectedEstateId} = useContext(EstateContext)
  const [notifications, setNotifications] = useState<NotificationLogEntry[]>([])

  const {refetch} = useGetNotificationLogQuery({
    variables: {estateId: selectedEstateId || ''},
    onCompleted: (data) => {
      data.getNotificationLog && setNotifications(data.getNotificationLog)
    },
  });

  const asyncRefetch = async () => {
    const response = await refetch();
    response.data.getNotificationLog && setNotifications(response.data.getNotificationLog)
  }

  return (
    <UserLayout
      data-testid={testId}
      includeSubHeader={false}
    >
      <div className={styles.notificationsManagementContainer}>
        <Typography font="UI2" variant="H4" className={styles.notificationsHeader}>
            Notifications
        </Typography>

        <CreateNotificationByGroupForm setNotificationData={() => asyncRefetch()}/>
        {/* <button onClick={() => asyncRefetch()}>Refresh List</button> */}
        
        <Card className={styles.notificationListContainer}>
          <Typography font="UI1" variant="H4">
            History 
          </Typography>
          <NotificationManagementList notifications={notifications} refetchNotifications={() => asyncRefetch()}/>
        </Card>
      </div>

    </UserLayout>
  )
}

export default NotificationsManagement
