import { FC, useContext, useEffect, useState } from "react";
import './ActionList.scss';
import ActionCard from "../ActionCard/ActionCard";
import { Action, ActionContext } from "../../providers/ActionProvider";
import AddIcon from '@mui/icons-material/Add';
import ActionButton from "../DesignSystem/atoms/ActionButton/ActionButton";
import { LayoutContext } from "../../providers/LayoutProvider";
import { EstateContext } from "../../providers/EstateProvider";
import { AccountContext } from "../../providers/Authentication";
import CreateOneActionCardForm from "../FormikForms/admin/CreateOneActionCardForm/CreateOneActionCardForm";
import ComponentSubTypeIcon from "../ComponentSubTypeIcon/ComponentSubTypeIcon";
import { ActionCardStatus } from "../../types/graphql";
import LoadingOverlay from "../../views/LoadingOverlay";
import Typography from "../DesignSystem/atoms/Typography/Typography";
import List from "../DesignSystem/atoms/List/List";
import MessageCard from "../DesignSystem/molecules/MessageCard/MessageCard";
import useScreenWidth from "../../hooks/useScreenWidth";
import { useRoleNavigate } from "../../hooks/useRoleNavigate";

interface ActionListParams {
  testId?: string;
  className?: string;
  showHeader?: boolean;
  showNextSteps?: boolean;
  direction?: 'horizontal' | 'vertical';
  gap?: number;
  flex?: number | string;
  listItemWidth?: number | string;
  listItemMaxWidth?: number | string;
  listItemMinWidth?: number | string;
}

const ActionList: FC<ActionListParams> = ({
  testId,
  className,
  showHeader=false,
  showNextSteps=true,
  direction='vertical',
  gap=16,
  flex=0,
  listItemWidth='auto',
  listItemMaxWidth='auto',
  listItemMinWidth,
}) => {
  const navigate = useRoleNavigate();
  const {
    setCurrentAction, actions, actionsLoading,
  } = useContext(ActionContext);
  const { currentEstate} = useContext(EstateContext)
  const {
    setDisplayModal, setModalContents, flag, setFlag, 
  } = useContext(LayoutContext);
  const {showAdminTools} = useContext(AccountContext);
  const [attentionActions, setAttentionActions] = useState<Action[]>([]);
  const [customerCompletedActions, setCustomerCompletedActions] = useState<Action[]>([]);
  const screenWidth = useScreenWidth();

  useEffect(() => {
    const newFlag = {...flag}
    newFlag.visible = false
    setFlag(newFlag)
  }, [currentEstate])

  useEffect(() => {
    actions && setAttentionActions(actions.filter(action => 
      action.status === ActionCardStatus.New || 
      action.status === ActionCardStatus.Draft,
    ))
    actions && setCustomerCompletedActions(actions.filter(action => 
      action.status === ActionCardStatus.Completed || 
      action.status === ActionCardStatus.InProgress,
    ))
  }, [actions])

  const handleClick = (action: Action | undefined) => {
    if (action) navigate(`/actionDetails/${action.id}`)
    else alert("Action is undefined")
  }

  const handleCreateNewActionCard = () => {
    setCurrentAction(null) // insures no contamination from other actions on create
    setModalContents(<CreateOneActionCardForm />)
    setDisplayModal(true);
  }

  return (
    <div className={`actionListContainer ${className}`} data-testid={testId}>
      <div className="actionListHeaderContainer__outer">
        <div className="actionListHeaderContainer__inner">
          {showAdminTools &&
            <div className="buttonContainer">
              <ActionButton
                ariaLabel="Add New Action Card"
                className="addIcon adminAddActionCardButton"
                handleClick={handleCreateNewActionCard}
              >
                <AddIcon />
              </ActionButton>
            </div>
          }
        </div>
      </div>
      <Typography font="UI1" variant="H5" className="actionListHeader">
        { screenWidth < 800 ? "Your to do's" : "For you to do"}
      </Typography>
      { actions && actions.length > 0 ?
        <>
          { attentionActions.length !== 0 ? 
            <>
              <List
                data={attentionActions}
                gap={gap}
                direction={direction}
                flex={flex}
                listItemWidth={listItemWidth}
                listItemMaxWidth={listItemMaxWidth}
                listItemMinWidth={listItemMinWidth}
                renderItem={(action: any, index: number) => (
                  <ActionCard
                    key={index}
                    icon={<ComponentSubTypeIcon subType={action?.component?.subType || ''} />}
                    buttonText={"See details"}
                    handleClick={() => handleClick(action)}
                    buttonVariant={index === 0 ? "solid" : "outline"}
                    action={action}
                  />
                )}
              />
            </>
            :
            showNextSteps &&
            <div className="actionListEmptyState">
              <Typography font="UI1" variant="H5" className="emptyStateMessage">No next steps for you</Typography>
            </div>
          }

          { customerCompletedActions.length !== 0 &&
          <>
            <Typography font="UI1" variant="H5" className="actionListHeader">
              { `Completed steps`}
            </Typography>
            <List 
              data={customerCompletedActions}
              gap={4}
              renderItem={(action: any, index: number) => (
                <ActionCard
                  key={index}
                  icon={<ComponentSubTypeIcon subType={action?.component?.subType || ''} />}
                  buttonText={"See details"}
                  handleClick={() => handleClick(action)}
                  buttonVariant={index === 0 ? "solid" : "outline"}
                  action={action}
                />
              )}
            />
          </>
          }
        </>
        :
        actionsLoading ?
          <LoadingOverlay />
          :
          <MessageCard 
            style={{maxWidth: '529px'}}
            message={
              <>
                <Typography font="UI1" variant="P1" fontWeight="medium">No action needed. </Typography>
                <Typography font="UI1" variant="P1" >Alix is on it. Please enjoy life to its fullest.</Typography>
              </>
            }/>
      }
    </div>
  );
};

export default ActionList;
