import { FC } from "react";

// Import 3rd party libraries and components
import { Grid } from "@mui/material";

// Import alix-specific pages and components
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const LoadingOverlay: FC = () => {
  return (
    <Grid
      container
      spacing={0}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{minHeight: '100vh'}}
    >
      <LoadingIndicator size={10} />
    </Grid>
  );
};

export default LoadingOverlay;
