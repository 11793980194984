import { FC, useContext, useEffect, useState } from 'react'
import './FolderSelector.scss'
import { Typography, Autocomplete, TextField } from '@mui/material'
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { InputMaybe, useListDocumentsQuery } from '../../../../types/graphql';
import { EstateContext } from '../../../../providers/EstateProvider';
import LoadingOverlay from '../../../../views/LoadingOverlay';
import LoadingIndicator from '../../../LoadingIndicator/LoadingIndicator';

interface FolderSelectorProps {
  testId?: string,
  fieldName: string,
  labelText: string,
  initialValue?: InputMaybe<string>,
}

interface FormValues {
  [key: string]: any; // Adjust this to match the shape of your form values
}

interface Folder {
  id: string,
  displayValue: string,
}

const FolderSelector: FC<FolderSelectorProps> = ({
  testId,
  fieldName,
  labelText,
  initialValue,
}) => {
  const {selectedEstateId} = useContext(EstateContext)
  const [ folders, setFolders ] = useState<Folder[]>()
  const [ initialFolder, setInitialFolder ] = useState<Folder>()
  const [ loading, setLoading ] = useState<boolean>(true)

  useEffect(() => {
    if(folders) {
      const result = folders.find((folder: {
        id: string,
        displayValue: string,
      }) => folder.id === initialValue)
      console.log(result)
      if (result) {
        setInitialFolder(result)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }, [folders])
  
  const {
    setFieldValue, submitCount, touched, errors, 
  } = useFormikContext<FormValues>();

  useListDocumentsQuery({
    variables: {"estateId": selectedEstateId},
    onCompleted: (data) => {
      const queryFolders = data.listDocuments.map( folder => {
        return ({
          id: `${folder.name}`, 
          displayValue: folder.name,
        })
      })
      setFolders(queryFolders)
    },
    onError: (e) => {
      console.error(e);
      alert(e.message);
    },
  } )
  
  return (
    <>
      { 
        loading ?
          <LoadingIndicator />
          :
          <>
            <Autocomplete
              options={folders || []}
              getOptionLabel={(option: {id: string, displayValue: string}) => option.displayValue}
              onChange={(_, value) => setFieldValue(`${fieldName}`, value?.id)}
              defaultValue={initialFolder}
              renderInput={(params) => (
                <Field
                  {...params}
                  className={`${fieldName}Input fieldInput textInput`}
                  required={true}
                  id={`${fieldName}`}
                  label={`${labelText}` || `${fieldName}`}
                  name={`${fieldName}`}
                  type={"text"}
                  autoComplete={`${fieldName}`}
                  as={TextField}
                  error={submitCount > 0 && touched[fieldName] && !!errors[fieldName]}
                />
              )}
            />
            <ErrorMessage name={`${fieldName}`} component="div" className="inputErrorMessage"/>
          </>
      }
    </>
  )
}

export default FolderSelector

