import React, { useState, createContext, FC, ReactNode } from 'react';
import { EstateDocument, Maybe } from '../types/graphql';

export interface IFile { 
  uploadedFile?: File,
  id?: string,
  name: string,
  deleteMarkerId?: string,
  lastModified?: Date,
  signedUrl?: string,
  uploadSuccess: boolean, 
  uploadProgress: number, 
  uploadError: string,
  uploadedAt?: Date,
  contentType?: string,
}

interface IDocumentContext {
  refetchDocumentsList: () => Promise<any> | undefined;
  setRefetchDocumentsList: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  folderRefetch: Record<string, () => Promise<any> | undefined>;
  setFolderRefetch: React.Dispatch<React.SetStateAction<Record<string, () => Promise<any> | undefined>>>;
  filePath: string;
  setFilePath: React.Dispatch<React.SetStateAction<string>>;
  directoryPath: string;
  setDirectoryPath: React.Dispatch<React.SetStateAction<string>>;
  selectedFile: Maybe<IFile>;
  setSelectedFile: React.Dispatch<React.SetStateAction<Maybe<IFile>>>;
  folderFiles: Record<string, Record<string, IFile>> | null;
  setFolderFiles: React.Dispatch<React.SetStateAction<Record<string,  Record<string, IFile>> | null>>;
  filesToMove: IFile[] | null;
  setFilesToMove: React.Dispatch<React.SetStateAction<IFile[] | null>>;
}

const defaultState: IDocumentContext = {
  refetchDocumentsList: () => undefined,
  setRefetchDocumentsList: () => () => {},
  folderRefetch: {},
  setFolderRefetch: () => () => {},
  directoryPath: "",
  setDirectoryPath: () => () => {},
  filePath: "",
  setFilePath: () => () => {},
  selectedFile: null,
  setSelectedFile: () => () => {},
  folderFiles: null,
  setFolderFiles: () => () => {},
  filesToMove: null,
  setFilesToMove: () => () => {},
};

export const DocumentContext = createContext(defaultState);

export const DocumentProvider:FC<{ children: ReactNode }> = ({children}) => {
  const [refetchDocumentsList, setRefetchDocumentsList ] = useState<IDocumentContext['refetchDocumentsList']>(defaultState.refetchDocumentsList);
  const [folderRefetch, setFolderRefetch] = useState<IDocumentContext['folderRefetch']>(defaultState.folderRefetch);
  const [directoryPath, setDirectoryPath] = useState<IDocumentContext['directoryPath']>(defaultState.directoryPath);
  const [filePath, setFilePath] = useState<IDocumentContext['filePath']>(defaultState.filePath);
  const [selectedFile, setSelectedFile] = useState<IDocumentContext['selectedFile']>(defaultState.selectedFile);
  const [folderFiles, setFolderFiles] = useState<IDocumentContext['folderFiles']>(defaultState.folderFiles);
  const [filesToMove, setFilesToMove] = useState<IDocumentContext['filesToMove']>(defaultState.filesToMove);


  return (
    <DocumentContext.Provider
      value={{
        refetchDocumentsList,
        setRefetchDocumentsList,
        folderRefetch,
        setFolderRefetch,
        directoryPath,
        setDirectoryPath,
        filePath,
        setFilePath,
        selectedFile,
        setSelectedFile,
        folderFiles,
        setFolderFiles,
        filesToMove,
        setFilesToMove,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
