const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    storage: window.sessionStorage,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
};

export default awsconfig;
