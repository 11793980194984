import { FC, useContext, useState } from 'react'
import styles from './Mobile.module.scss'
import { MobileLayout } from "../../components/Layouts";
import { UserNotification } from '../../types/AppTypes';
import { useGetUserEstateNotificationsQuery } from '../../types/graphql';
import NotificationBell from '../../components/DesignSystem/atoms/NotificationBell/NotificationBell';
import NotificationItemList from '../../components/DesignSystem/molecules/NotificationItemList/NotificationItemList';
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import { LayoutContext } from '../../providers/LayoutProvider';
    
interface MobileProps {
  testId?: string
}
    
const Mobile: FC<MobileProps> = ({testId}) => {
  const [notifications, setNotifications] = useState<UserNotification[]>([])
  const { setNotificationIds } = useContext(LayoutContext)

  const {refetch} = useGetUserEstateNotificationsQuery({
    onCompleted: (data) => {
      if( data.getUserEstateNotifications) {
        setNotifications(data.getUserEstateNotifications)
        setNotificationIds(data.getUserEstateNotifications.map((notification) => notification.id))
      }
    },
  });

  const getUserNotifications = async () => {
    const response = await refetch()
    response.data.getUserEstateNotifications && setNotifications(response.data.getUserEstateNotifications)
  }
  
  return (
    <MobileLayout
      includeSubHeader={false}
      includeBackNavHeader={true}
      includeFooter={false}
      includeCareTeamCard={false}
      headerText='Notifications'
    >
      <div data-testid={testId} className={styles.mobileContainer}>
        { notifications.length > 0 ? (
          <div className={styles.notificationCardListContainer}>
            <NotificationItemList notifications={notifications} refetch={getUserNotifications}/>
          </div>
        ) : (
          <div className={styles.emptyStateContainer}>
            <NotificationBell className={styles.emptyStateBell} width={54} height={54} />
            <Typography font="UI1" variant="H4" fontWeight='regular' className={styles.emptyStateHeaderText}>No notifications just yet</Typography>
            <Typography font="UI1" variant="P2" fontWeight="regular">{`When an update is ready, you'll see it here. Alix is on it!`}</Typography>
          </div>
        )}
      </div>
    </MobileLayout>
  )
}
    
export default Mobile
      