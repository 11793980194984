import { FC, useContext } from 'react'

import { DesktopLayout } from '../../../components/Layouts'
import DocumentsList from '../DocumentsList.tsx/DocumentsList'

import { useDecision } from "@optimizely/react-sdk";
import "box-ui-elements/dist/explorer.css";

import styles from './Desktop.module.scss'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'
import BoxContentExplorer from './BoxContentExplorer';
import { AccountContext } from '../../../providers/Authentication';

const Desktop: FC = () => {
  const [useContentExplorer] = useDecision('use-content-explorer', { autoUpdate: true });
  const {hasAdminAuthorization} = useContext(AccountContext)
  
  return (
    <DesktopLayout
      includeSubHeader={false}
    >
      {useContentExplorer.enabled && !hasAdminAuthorization ? (        
        <BoxContentExplorer />
      ) : (
        <>
          <div className={styles.documentsHeaderContainer}>
            <Typography variant='H3' font="UI2" className={styles.documentsHeader}>Document Vault</Typography>
          </div><DocumentsList />
        </>
      )}
    </DesktopLayout>
  )
}

export default Desktop