import { useContext, useEffect } from 'react';
import { useRoleNavigate } from "../hooks/useRoleNavigate";
import { useGetCurrentUserLazyQuery } from '../types/graphql';
import { AccountContext } from '../providers/Authentication';
import { EstateContext } from '../providers/EstateProvider';
import { UserContext } from '../providers/UserProvider';
import { useDecision } from '@optimizely/react-sdk';

export const useNoDecedantInfoRedirect = () => {
  const navigate = useRoleNavigate();
  const {hasAdminAuthorization} = useContext(AccountContext);
  const {currentEstate, deceased} = useContext(EstateContext);
  const {setCurrentUser} = useContext(UserContext);
  const [decedentInfoFlag] = useDecision('deceased_information_form', {autoUpdate: true});
  const [getCurrentUser, {loading}] = useGetCurrentUserLazyQuery();

  useEffect(() => {
    if(decedentInfoFlag){
      // If not an admin user, check if there is deceased information on the current estate
      if(!hasAdminAuthorization && currentEstate && !currentEstate?.getUserEstate?.deceased && !deceased){
        // If not, show the deceased information form
        navigate('/deceasedInfo');
      }
    }
    if(!loading) {
      getCurrentUser({
        onCompleted: (data) => {
          if (data.getCurrentUser) {
            setCurrentUser(data);
          }
        },
        onError: (error) => {
          console.error('error', error);
        },
      });
    }
  }, [currentEstate, decedentInfoFlag, hasAdminAuthorization, getCurrentUser, loading, navigate, setCurrentUser, deceased]);
}