import { FC, useContext } from 'react'
import './RecentActivityCard.scss'
import Typography from '../../atoms/Typography/Typography'
import Card from '../../atoms/Card/Card'
import { ContentStatus, Maybe, User } from '../../../../types/graphql'
import { ReactComponent as InProcessIcon } from '../../../../assets/icons/statusIcons/in-progress.svg'
import { ReactComponent as CompleteIcon } from '../../../../assets/icons/statusIcons/complete.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/navigation/right-arrow.svg'
import { truncate } from '../../../../helpers/functions'
import { useRoleNavigate } from "../../../../hooks/useRoleNavigate";
import { AccountContext } from '../../../../providers/Authentication'

interface RecentActivityCardProps {
  testId?: string
  contentId: string,
  componentId: string,
  status?: ContentStatus
  user?: Maybe<User> | undefined
  title?: string
  body?: string
  date?: string
}

const RecentActivityCard: FC<RecentActivityCardProps> = ({
  testId,
  contentId,
  componentId,
  status = ContentStatus.InProcess,
  user,
  title = 'Component Card Title',
  body = 'Component Card Description',
}) => {
  const { showAdminTools } = useContext(AccountContext)
  const navigate = useRoleNavigate()

  const clickableAction = () => {
    const url = `/estateComponentDetails/${componentId}?contentId=id-${contentId}`
    navigate(url)
  }

  const handleIcon = () => {
    if (user?.avatar) {
      return <img src={user.avatar} />
    } else {
      return status === ContentStatus.Completed ? <CompleteIcon /> : <InProcessIcon />
    }
  }

  return (
    <Card 
      data-testid={testId} 
      className='recentActivityCardContainer'
      clickable
      clickableAction={clickableAction}
    >
      <div className='cardTitle'>
        {handleIcon()}
        <Typography variant='P1' font='UI1' className='wordWrap'>
          {title}
        </Typography>
      </div>
      <div className={`cardBody`}>
        <Typography 
          variant='H5' 
          font='UI1'
          fontWeight='regular'
          useDangerouslySetInnerHTML={truncate(body, 100)} 
        />
      </div>
      <div className={`cardFooter ${'flex-end'}`}>
        {/* { user && <Typography variant='P2' font='UI1' className='userName'>
          {`From ${user?.firstName}`}
        </Typography>} */}
        <div className='footerLink'>
          <Typography variant='L1' font='UI1'>
            View Details
          </Typography>
          <ArrowRight />
        </div>
      </div>
    </Card>
  )
}

export default RecentActivityCard
