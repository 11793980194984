import { FC, useContext, useEffect, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useUpdateOneUserMutation, UserUpdateInput, UserType, useGetUserQuery, UpdateOneUserMutation, GetUserQuery } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { UserContext } from '../../../../providers/UserProvider';
import LoadingOverlay from '../../../../views/LoadingOverlay';
import SelectUser from '../../../SelectUser/SelectUser';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

type UpdateOneUserFormProps = {
  testId?: string,
}

const UpdateOneUserForm: FC<UpdateOneUserFormProps> = ({ testId }) => {
  // provide the update entity as this variable
  const {
    selectedUser,
    setSelectedUser,
    selectedUserId, 
    setSelectedUserId,
  } = useContext(UserContext)
  const {refetchUsersOnEstate} = useContext(EstateContext);

  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const [updateOneUserMutation] = useUpdateOneUserMutation();
  const [entity, setEntity] = useState(selectedUser?.getUser)
  const [options, setOptions] = useState<any>({
    "type": [
      {
        "submitValue": "ADMIN",
        "displayValue": "Admin",
      },
      {
        "submitValue": "CARETEAM",
        "displayValue": "Careteam",
      },
      {
        "submitValue": "CUSTOMER",
        "displayValue": "Customer",
      },
      {
        "submitValue": "OPS",
        "displayValue": "Ops",
      },
    ],
  });
  const [initialValues, setInitialValues] = useState<any>(null);

  const { refetch:refetchUserQuery, loading } = useGetUserQuery({
    variables: { where: { id: selectedUserId } },
    onCompleted: (data: GetUserQuery) => {
      setSelectedUser(data);
      setInitialValues({
        type: data?.getUser?.type || UserType.Admin,
        // externalId: data?.getUser?.externalId || '',
        email: data?.getUser?.email || '',
        firstName: data?.getUser?.firstName || '',
        lastName: data?.getUser?.lastName || '',
        phone: data?.getUser?.phone || '',
        dateOfBirth: data?.getUser?.dateOfBirth ? dayjs.utc(data?.getUser?.dateOfBirth) : null,
        partnerId: data?.getUser?.partnerId || '',
        address: data?.getUser?.address || '',
        city: data?.getUser?.city || '',
        county: data?.getUser?.county || '',
        state: data?.getUser?.state || '',
        country: data?.getUser?.country || '',
      });
      setEntity(data?.getUser);
    },
  });

  useEffect(() => {
    refetchUserQuery();
  }, [selectedUserId]);

  useEffect(() => {
    setEntity(selectedUser?.getUser)
  }, [selectedUser]);

  const handleSubmit = async (
    values: UserUpdateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as UserUpdateInput

    updateOneUserMutation({
      variables: {
        data: nestedValues,
        where: { id: entity?.id },
      },
      onCompleted: (data: UpdateOneUserMutation) => {
        console.log(data);
        refetchUsersOnEstate();
        setSelectedUserId(null);
        setSelectedUser(null);
        setModalContents(<SelectUser />);
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed updateOneUserMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  };

  const handleCancelClick = () => {
    setModalContents(<SelectUser />);
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string(),
    externalId: Yup.string(),
    email: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string(),
    dateOfBirth: Yup.string().nullable(),
    partnerId: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    county: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
  });

  if (!initialValues || loading) return <LoadingOverlay />;
  return (
    <div data-testid={testId} className='updateOneUserFormContainer'>
      {
        entity ?
          <>
            <Typography variant='h4' className='updateOneUserFormHeader'>
              Update One User Form
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors, touched, submitCount, setFieldValue,
              }) => (
                <Form noValidate>
                  <Container className="formContainer">

                    <Typography variant="h5">Required Information</Typography>
                    <Container className="formSection">

                      <div className={"inputWrapper typeFieldWrapper dropdownInputWrapper"} key={"type2"} data-testid={testId}>
                        <Autocomplete
                          options={options.type || []}
                          getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                          renderInput={(params: any) =>
                            <Field
                              {...params}
                              className={"typeInput fieldInput dropdownInput"}
                              required={false}
                              id={"type"}
                              label={"Type"}
                              name={"type"}
                              type={"text"}
                              autoComplete={"type"}
                              as={TextField}
                              error={submitCount > 0 && touched.type && !!errors.type}
                            />
                          }
                          defaultValue={options.type.find((option: any) => option.submitValue === initialValues.type)}
                          onChange={(_, value) => setFieldValue("type", value?.submitValue)}
                        />
                        <ErrorMessage name={"type"} component="div" className="inputErrorMessage" />
                      </div>
                      {/*     
                    <div className={"inputWrapper externalIdFieldWrapper textInputWrapper"} key={"externalId3"} data-testid={testId}>
                      <Field
                        className={"externalIdInput fieldInput textInput"}
                        required={false}
                        id={"externalId"}
                        label={"External Id"}
                        name={"externalId"}
                        type={"text"}
                        autoComplete={"externalId"}
                        as={TextField}
                        error={submitCount > 0 && touched.externalId&& !!errors.externalId}
                      />
                      <ErrorMessage name={"externalId"} component="div" className="inputErrorMessage"/>
                    </div> */}

                      <div className={"inputWrapper emailFieldWrapper textInputWrapper"} key={"email4"} data-testid={testId}>
                        <Field
                          className={"emailInput fieldInput textInput"}
                          disabled={true}
                          required={false}
                          id={"email"}
                          label={"Email"}
                          name={"email"}
                          type={"text"}
                          autoComplete={"email"}
                          as={TextField}
                          error={submitCount > 0 && touched.email && !!errors.email}
                        />
                        <ErrorMessage name={"email"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName5"} data-testid={testId}>
                        <Field
                          className={"firstNameInput fieldInput textInput"}
                          required={false}
                          id={"firstName"}
                          label={"First Name"}
                          name={"firstName"}
                          type={"text"}
                          autoComplete={"firstName"}
                          as={TextField}
                          error={submitCount > 0 && touched.firstName && !!errors.firstName}
                        />
                        <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName6"} data-testid={testId}>
                        <Field
                          className={"lastNameInput fieldInput textInput"}
                          required={false}
                          id={"lastName"}
                          label={"Last Name"}
                          name={"lastName"}
                          type={"text"}
                          autoComplete={"lastName"}
                          as={TextField}
                          error={submitCount > 0 && touched.lastName && !!errors.lastName}
                        />
                        <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage" />
                      </div>
                    </Container>

                    <hr />

                    <Typography variant="h5">Optional Information</Typography>
                    <Container className="formSection">

                      <div className={"inputWrapper phoneFieldWrapper textInputWrapper"} key={"phone7"} data-testid={testId}>
                        <Field
                          className={"phoneInput fieldInput textInput"}
                          required={false}
                          id={"phone"}
                          label={"Phone"}
                          name={"phone"}
                          type={"text"}
                          autoComplete={"phone"}
                          as={TextField}
                          error={submitCount > 0 && touched.phone && !!errors.phone}
                        />
                        <ErrorMessage name={"phone"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper dateOfBirthFieldWrapper textInputWrapper"} key={"dateOfBirth8"} data-testid={testId}>
                        <Field
                          className={"dateOfBirthInput fieldInput textInput"}
                          required={false}
                          id={"dateOfBirth"}
                          label={"Date Of Birth"}
                          name={"dateOfBirth"}
                          type={"date"}
                          autoComplete={"dateOfBirth"}
                          as={DatePicker}
                          error={submitCount > 0 && touched.dateOfBirth && !!errors.dateOfBirth}
                          value={initialValues.dateOfBirth}
                          onChange={(date: any) => setFieldValue("dateOfBirth", date)}
                        />
                        <ErrorMessage name={"dateOfBirth"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper partnerIdFieldWrapper textInputWrapper"} key={"partnerId9"} data-testid={testId}>
                        <Field
                          className={"partnerIdInput fieldInput textInput"}
                          required={false}
                          id={"partnerId"}
                          label={"Partner Id"}
                          name={"partnerId"}
                          type={"text"}
                          autoComplete={"partnerId"}
                          as={TextField}
                          error={submitCount > 0 && touched.partnerId && !!errors.partnerId}
                        />
                        <ErrorMessage name={"partnerId"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper addressFieldWrapper textInputWrapper"} key={"address10"} data-testid={testId}>
                        <Field
                          className={"addressInput fieldInput textInput"}
                          required={false}
                          id={"address"}
                          label={"Address"}
                          name={"address"}
                          type={"text"}
                          autoComplete={"address"}
                          as={TextField}
                          error={submitCount > 0 && touched.address && !!errors.address}
                        />
                        <ErrorMessage name={"address"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper cityFieldWrapper textInputWrapper"} key={"city11"} data-testid={testId}>
                        <Field
                          className={"cityInput fieldInput textInput"}
                          required={false}
                          id={"city"}
                          label={"City"}
                          name={"city"}
                          type={"text"}
                          autoComplete={"city"}
                          as={TextField}
                          error={submitCount > 0 && touched.city && !!errors.city}
                        />
                        <ErrorMessage name={"city"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper countyFieldWrapper textInputWrapper"} key={"county12"} data-testid={testId}>
                        <Field
                          className={"countyInput fieldInput textInput"}
                          required={false}
                          id={"county"}
                          label={"County"}
                          name={"county"}
                          type={"text"}
                          autoComplete={"county"}
                          as={TextField}
                          error={submitCount > 0 && touched.county && !!errors.county}
                        />
                        <ErrorMessage name={"county"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper stateFieldWrapper textInputWrapper"} key={"state13"} data-testid={testId}>
                        <Field
                          className={"stateInput fieldInput textInput"}
                          required={false}
                          id={"state"}
                          label={"State"}
                          name={"state"}
                          type={"text"}
                          autoComplete={"state"}
                          as={TextField}
                          error={submitCount > 0 && touched.state && !!errors.state}
                        />
                        <ErrorMessage name={"state"} component="div" className="inputErrorMessage" />
                      </div>

                      <div className={"inputWrapper countryFieldWrapper textInputWrapper"} key={"country14"} data-testid={testId}>
                        <Field
                          className={"countryInput fieldInput textInput"}
                          required={false}
                          id={"country"}
                          label={"Country"}
                          name={"country"}
                          type={"text"}
                          autoComplete={"country"}
                          as={TextField}
                          error={submitCount > 0 && touched.country && !!errors.country}
                        />
                        <ErrorMessage name={"country"} component="div" className="inputErrorMessage" />
                      </div>
                    </Container>

                  </Container>

                  <Container className="buttonsContainer">
                    <ActionButton
                      ariaLabel="Submit form"
                      variant="outline"
                      className="submitFormButton"
                      handleClick={handleCancelClick}
                    >
                      Cancel
                    </ActionButton>

                    <ActionButton
                      type="submit"
                      ariaLabel="Submit form"
                      variant="solid"
                      className="submitFormButton"
                    >
                      Submit
                    </ActionButton>
                  </Container>
                </Form>
              )}
            </Formik>
          </>
          :
          <LoadingOverlay />
      }
    </div>
  )
}

export default UpdateOneUserForm