import { FC, useContext, useEffect, useState } from 'react'
import './DocumentUpload.scss'
import { ActionContext } from '../../../providers/ActionProvider'
import DropZoneInput from '../../../components/File/DropZoneInput/DropZoneInput'
import { AccountContext } from '../../../providers/Authentication'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider'
import { useScreenWidth } from '../../../helpers'
import FilePickerInput from '../../../components/File/FilePickerInput/FilePickerInput'
import { EstateContext } from '../../../providers/EstateProvider'
import { ListDocumentsQuery, useListDocumentsQuery } from '../../../types/graphql'
import axios from 'axios';
import FilesList from '../../../components/File/FilesList/FilesList'
import { CircularProgress, Typography } from '@mui/material'

interface DocumentUploadProps {
  testId?: string
  currentAction: any
}

const fetchFile = async (url: string) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = await response.data;

    // Extract the name from the URL
    const urlParts = url.split('/');
    const name = urlParts[urlParts.length - 1].split('?')[0];

    const file = new File([blob], name, { type: blob.type });
    return file;
  } catch (error) {
    console.error(error);
  }
}

const DocumentUpload: FC<DocumentUploadProps> = ({testId, currentAction}) => {
  const {hasAdminAuthorization} = useContext(AccountContext)
  const {
    setRefetchDocumentsList,
    directoryPath,
    setDirectoryPath,
    setFolderFiles,
    folderFiles,
  } = useContext(DocumentContext);
  const {selectedEstateId} = useContext(EstateContext);
  const screenWith = useScreenWidth()
  const [loadingFiles, setLoadingFiles] = useState(true)

  const [ filePath, setFilePath ] = useState<string>('')

  useEffect(() => {
    setDirectoryPath(currentAction?.documentUploadUrl || '')
    setFilePath( `${currentAction?.documentUploadUrl}/` || '')
  } , [currentAction])

  useEffect(() => {
    setFolderFiles(null)
  }, [] )

  return (
    <div className="documentUploadContainer" data-testid={testId}>
      <div className='fileListContainer uploadListMobile'>
        {
          folderFiles && currentAction?.documentUploadUrl && folderFiles[currentAction?.documentUploadUrl] ?
            Object.keys(folderFiles[currentAction?.documentUploadUrl || ""]).length > 0 ?
              <div className='uploadedHeader'>
                <Typography variant='body1' className='uploadListMobileTitle'>You Uploaded</Typography>
              </div>
              :
              <div className='uploadedHeader'>
                <Typography variant='body1' className='uploadListMobileTitle'>No Files Uploaded</Typography>
              </div>
            :
            <div className='uploadedHeader'>
              <Typography variant='body1' className='uploadListMobileTitle'>Checking For Files </Typography>
              <CircularProgress size={22}/>
            </div>
        }
        <FilesList directory={currentAction?.documentUploadUrl || ""} type="upload"/>
      </div>

      <div className='fileUploaderContainer'>
        {currentAction?.documentUploadUrl &&
          screenWith > 800 &&
          <DropZoneInput destinationFolder={filePath} className='dropZoneInput'/>
        }
        {
          currentAction?.documentUploadUrl &&
          screenWith <= 800 &&
          <FilePickerInput destinationFolder={filePath}/>
        }
      </div>

      <div className='fileListContainer uploadListDesktop'>
        {
          currentAction?.documentUploadUrl ?
            folderFiles && currentAction?.documentUploadUrl && folderFiles[currentAction?.documentUploadUrl] ?
              Object.keys(folderFiles[currentAction?.documentUploadUrl || ""]).length > 0 ?
                <div className='uploadedHeader'>
                  <Typography variant='body1' className='uploadListDesktopTitle'>You Uploaded</Typography>
                </div>
                :
                <Typography variant='body1' className='uploadListDesktopTitle'>No Files Uploaded</Typography>
              :
              <div className='uploadedHeader'>
                <Typography variant='body1' className='uploadListDesktopTitle'>Checking For Files </Typography>
                <CircularProgress size={22}/>
              </div>
            :
            <Typography variant='body1' className='uploadListDesktopTitle'>No Upload Directory Provided</Typography>

        }
        <div className='fileListDesktopContainer uploadListDesktop'>
          <FilesList directory={currentAction?.documentUploadUrl || ""} className='uploadListDesktop borders' type='upload'/>
        </div>
      </div>
    </div>
  )
}

export default DocumentUpload