import React, { useState, createContext, FC, ReactNode } from 'react';


export type Flag = {
  type: 'success' | 'inProgress' | 'alert'
  title: string
  message?: string
  visible: boolean
}

interface ILayoutContext {
  displayModal: boolean;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsClearable: boolean;
  setModalIsClearable: React.Dispatch<React.SetStateAction<boolean>>;
  modalContents: React.ReactNode;
  setModalContents: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  showCareTeamConnectButton: boolean;
  displayPopupModal: boolean;
  setDisplayPopupModal: React.Dispatch<React.SetStateAction<boolean>>;
  popupModalContents: React.ReactNode;
  setPopupModalContents: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  popupModalTitle: string;
  setPopupModalTitle: React.Dispatch<React.SetStateAction<string>>;
  setShowCareTeamConnectButton: React.Dispatch<React.SetStateAction<boolean>>;
  flag: Flag;
  setFlag: React.Dispatch<React.SetStateAction<Flag>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  notificationIds: string[];
  setNotificationIds: React.Dispatch<React.SetStateAction<string[]>>;
}



const defaultState: ILayoutContext = {
  displayModal: false,
  setDisplayModal: () => {},
  modalIsClearable: false,
  setModalIsClearable: () => {},
  modalContents: "",
  setModalContents: () => "",
  displayPopupModal: false,
  setDisplayPopupModal: () => {},
  popupModalContents: "",
  setPopupModalContents: () => "",
  popupModalTitle: '',
  setPopupModalTitle: () => {},
  showCareTeamConnectButton: false,
  setShowCareTeamConnectButton: () => {},
  flag: {
    title: '',
    message: '',
    visible: false,
    type: 'success',
  },
  setFlag: () => ({
    title: '',
    message: '',
    visible: false,
    type: 'success',
  }),
  isLoading: true,
  setIsLoading: () => true,
  notificationIds: [],
  setNotificationIds: () => [],
};

export const LayoutContext = createContext(defaultState);

export const LayoutProvider:FC<{ children: ReactNode }> = ({children}) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [modalIsClearable, setModalIsClearable] = useState<boolean>(false);
  const [modalContents, setModalContents] = useState<ILayoutContext["modalContents"]>(defaultState["modalContents"]);
  const [displayPopupModal, setDisplayPopupModal] = useState<boolean>(false);
  const [popupModalContents, setPopupModalContents] = useState<ILayoutContext["popupModalContents"]>(defaultState["popupModalContents"]);
  const [popupModalTitle, setPopupModalTitle] = useState<ILayoutContext["popupModalTitle"]>(defaultState["popupModalTitle"]);
  const [showCareTeamConnectButton, setShowCareTeamConnectButton] = useState<boolean>(false);
  const [ flag, setFlag ] = useState<ILayoutContext['flag']>(defaultState.flag)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ notificationIds, setNotificationIds ] = useState<string[]>([])

  return (
    <LayoutContext.Provider
      value={{
        displayModal,
        setDisplayModal,
        modalIsClearable,
        setModalIsClearable,
        modalContents,
        setModalContents,
        displayPopupModal,
        setDisplayPopupModal,
        popupModalContents,
        setPopupModalContents,
        popupModalTitle,
        setPopupModalTitle,
        showCareTeamConnectButton,
        setShowCareTeamConnectButton,
        flag,
        setFlag,
        isLoading,
        setIsLoading,
        notificationIds,
        setNotificationIds,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
