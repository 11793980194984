import { Container, Link, Typography } from '@mui/material'
import { ChangeEvent, FC, useContext, useState } from 'react'
import './acceptEula.scss';
import AuthViewLayout from '../AuthViewLayout';
import LoadingOverlay from '../../LoadingOverlay';
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton';
import { gql, useMutation } from '@apollo/client';
import { AccountContext } from '../../../providers/Authentication/Account';
import { useRoleNavigate } from "../../../hooks/useRoleNavigate";
import { AGREEMENT_TERMS, PRIVACY_POLICY, TERMS_OF_SERVICE, TERMS_OF_USE } from '../../../config/policy-links';

interface AcceptEulaProps {
  testId?: string
}

const ACCEPT_EULA_MUTATION = gql`
mutation AcceptEULA {
  acceptEULA {
    id
  }
}`;

const AcceptEula: FC<AcceptEulaProps> = ({ testId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agreedToEula, setAgreedToEula] = useState<boolean>(false);
  const [agreedToEulaErrMessage, setAgreedToEulaErrMessage] = useState<string>('');
  const [acceptEula, {
    data, loading, error,
  }] = useMutation(ACCEPT_EULA_MUTATION);
  const { hasAdminAuthorization } = useContext(AccountContext);
  const navigate = useRoleNavigate();

  const handleCheckboxClick = async (event: ChangeEvent<HTMLInputElement>) => {
    setAgreedToEula(event.target.checked);
    setAgreedToEulaErrMessage('');
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    let errEncountered = false;
    let urlToRedirectTo = "";

    // If the user has not agreed to Eula, short circuit
    if (!agreedToEula) {
      setAgreedToEulaErrMessage("You must agree in order to continue.")
      errEncountered = true;
    } else {
      // Execute the mutation that will allow us to accept the EULA
      const result = await acceptEula();

      // If there was an error, short circuit
      if (result.errors) {
        setAgreedToEulaErrMessage("There was an error submitting your EULA agreement. Please try again later.");
        errEncountered = true;
      // } else if (createResult.errors) { //or if there were errors with the create result, short circuit
      //   setAgreedToEulaErrMessage("There was an error creating your account. Please try again later.");
      //   errEncountered = true;
      } else {
        // If there was no error, check if the user is an admin
        if (hasAdminAuthorization) {
          // If the user is an admin, redirect them to the admin dashboard
          urlToRedirectTo = "/adminLanding";
        } else {
          // If the user is not an admin, redirect them to the home page
          urlToRedirectTo = "/home";
        }
      }
    }

    // Execute the short circuit
    if (errEncountered) {
      setIsLoading(false);
      return null;
    } else {
      setIsLoading(false);
      navigate(urlToRedirectTo);
      return null;
    }
  }

  if (isLoading) return <LoadingOverlay />;
  else return (
    <AuthViewLayout>
      <div className="acceptEulaContainer">
        {/* Welcome Header */}
        <div className="welcomeHeaderContainer">
          <Typography variant="h1" className="welcomeTitle light">
            Welcome
          </Typography>

          <Typography variant="body1" className="welcomeMessage light">
            {"We need to present some legal information to you before we can continue. Please take a moment to read the following documents"}
          </Typography>
          <ul className="documentLinks">
            <li><Link href={AGREEMENT_TERMS}>End User License Agreement</Link></li>
            <li><Link href={TERMS_OF_SERVICE}>Terms of Service</Link></li>
            <li><Link href={TERMS_OF_USE}>Terms of Use</Link></li>
            <li><Link href={PRIVACY_POLICY}>Privacy Policy</Link></li>
          </ul>
        </div>

        {/* EULA Agreement Notices and Agreement */}
        <div className="eulaNoticeContainer__outer">
          <div className="eulaNoticeContainer">
            <div className="noticeContainer">
              <div className="checkBoxContainer">
                <input type="checkbox" onChange={handleCheckboxClick} />
              </div>
              <Typography variant="body1" className="serviceAgreementText">
                <span>{"I have read and agreed to Alix's "}</span>
                <Link
                  variant="body1"
                  className="licenseAgreementLink"
                  onClick={() => window.open(AGREEMENT_TERMS)}
                >
                  {"License Agreement"}
                </Link>
                {", "}
                <Link
                  variant="body1"
                  className="termsOfServiceLink"
                  onClick={() => window.open(TERMS_OF_SERVICE)}
                >
                  {"Terms of Service"}
                </Link>
                {", "}
                <Link
                  variant="body1"
                  className="termsOfUseLink"
                  onClick={() => window.open(TERMS_OF_USE)}
                >
                  {"Terms of Use"}
                </Link>
                {", and "}
                <Link
                  variant="body1"
                  className="privacyNoticeLink"
                  onClick={() => window.open(PRIVACY_POLICY)}
                >
                  {"Privacy Policy"}
                </Link>
              </Typography>
            </div>


            {/* EULA Err Message */}
            {
              agreedToEulaErrMessage && (
                <Typography variant="body1" className="errorsMessage">
                  {agreedToEulaErrMessage}
                </Typography>
              )
            }
          </div>
        </div>

        {/* Submit Button */}
        <div className="buttonsContainer">
          <ActionButton
            type="submit"
            ariaLabel="Submit EULA Agreement"
            variant="solid"
            className="submitEulaAgreementButton"
            handleClick={handleSubmit}
          >
            Submit
          </ActionButton>
        </div>
      </div>
    </AuthViewLayout>
  )
}

export default AcceptEula