import { FC, useContext, useState } from 'react';
import * as Yup from 'yup';

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Typography, TextField, Container, InputAdornment, IconButton, Link, Checkbox } from '@mui/material';

import LoadingOverlay from '../../LoadingOverlay';
import AuthViewLayout from '../AuthViewLayout';
import { useRoleNavigate } from "../../../hooks/useRoleNavigate";
import { ErrorMessage, Field, FieldInputProps, Form, Formik } from 'formik';
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton';

import './resetNewUserPassword.scss';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ApolloClient, InMemoryCache, createHttpLink, from, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AccountContext } from '../../../providers/Authentication';
import buildOneOffApolloClient from '../../../helpers/buildOneOffClient';
import { AGREEMENT_TERMS, PRIVACY_POLICY, TERMS_OF_SERVICE, TERMS_OF_USE } from '../../../config/policy-links';

const ResetNewUserPassword: FC<{ testId?: string }> = ({ testId }) => {
  const navigate = useRoleNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authenticationError, setAuthenticationError] = useState<string>('');
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const { handleNewPasswordChallenge } = useContext(AccountContext);

  const httpLink = createHttpLink({ uri: process.env.REACT_APP_ALIX_GRAPHQL_API });

  const oneOffClient = buildOneOffApolloClient();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .trim()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must have at least one lowercase character')
      .matches(/[A-Z]/, 'Password must have at least one uppercase character')
      .matches(/\d/, 'Password must have at least one number')
      .matches(/[^a-zA-Z0-9]/, 'Password must have at least one special character'),
    newPasswordConfirm: Yup.string()
      .trim()
      .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
      .required('You must enter the password again'),
    acceptedEula: Yup.boolean().oneOf([true], 'You must agree in order to continue'),
  });

  async function handleSubmit({
    newPassword, newPasswordConfirm, acceptedEula,
  }: { newPassword: string; newPasswordConfirm: string; acceptedEula: boolean; }) {
    setIsLoading(true);

    try {
      const userSession = await handleNewPasswordChallenge(newPassword.trim());

      if (!userSession) {
        setAuthenticationError("There was an error setting your new password. Please try again.")
        setIsLoading(false);
        return null;
      }

      console.log(userSession.isValid());

      const isAnAdmin = userSession.getIdToken().payload["cognito:groups"]?.includes("Admin");
      const stringifiedToken = userSession.getIdToken().getJwtToken();

      console.log(stringifiedToken);

      if (!isAnAdmin && stringifiedToken) {

        // create an authLink
        const authLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer: ${stringifiedToken}`,
            },
          }
        });

        oneOffClient.setLink(authLink.concat(httpLink));

        // check if the user has the latest EULA
        const { data } = await oneOffClient.query({
          query: gql`
            query IsEULACurrent {
              isEULAcurrent
            }
            `,
        });

        // if the user has not accepted the latest EULA accept it
        if (!data.isEULAcurrent) await oneOffClient.mutate({
          mutation: gql`mutation AcceptEULA {
            acceptEULA {
              id
            }
          }`,
        });

        navigate("/home");
      } else navigate("/adminLanding");

      // set is loading to false
      setIsLoading(false);
    } catch (e) {
      console.error("Error encountered while attempting to submit the new password");
      console.error(e);
      setIsLoading(false);
    }

  }

  if (isLoading) return <LoadingOverlay />;
  else return (
    <AuthViewLayout>
      <div className="resetNewUserPasswordContainer" data-testid={testId}>
        <div className="welcomeHeaderContainer">
          <Typography variant="h1" className='welcome light'>
            Welcome
          </Typography>
          <Typography variant="body1" className='light'>
            Create a password.
          </Typography>
        </div>

        <Formik
          initialValues={{
            newPassword: '',
            newPasswordConfirm: '',
            acceptedEula: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors, touched, submitCount,
          }) => (
            <Form noValidate>
              {/* Password and Confirm Password Inputs */}
              <div className="fieldWithMargin">
                <Field
                  className={`passwordInput light ${touched.newPassword && errors.newPassword && 'error'}`}
                  id="newPassword"
                  label="Password"
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  variant="standard"
                  as={TextField}
                  error={submitCount > 0 && touched.newPassword && !!errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="newPassword" component="div" className="inputErrorMessage" />
              </div>

              <div className="fieldWithMargin">
                <Field
                  className={`passwordInput light ${touched.newPasswordConfirm && errors.newPasswordConfirm && 'error'}`}
                  id="newPasswordConfirm"
                  label="Confirm Password"
                  name="newPasswordConfirm"
                  autoComplete="new-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="standard"
                  as={TextField}
                  error={submitCount > 0 && touched.newPasswordConfirm && !!errors.newPasswordConfirm}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="newPasswordConfirm" component="div" className="inputErrorMessage" />
                <ErrorMessage name="form" component="div" className="inputErrorMessage" />
              </div>

              {/* EULA Checkbox */}
              <div className={`fieldWithMargin eulaField ${ touched.acceptedEula && errors.acceptedEula ? 'error' : ''}`}>
                <div className="row">
                  <Field
                    id="acceptedEula"
                    name="acceptedEula"
                    type="checkbox"
                    variant="standard"
                    error={submitCount > 0 && touched.acceptedEula && !!errors.acceptedEula}
                  >
                    {({ field }: { field: FieldInputProps<boolean> }) => (
                      <Checkbox
                        {...field}
                        className={touched.acceptedEula && errors.acceptedEula ? "acceptedEula error" : "acceptedEula normal"}
                      />
                    )}
                  </Field>
                  <label htmlFor="acceptedEula" className="eulaCheckboxLabel">
                    <Typography variant="body1" className="serviceAgreementText">
                      <span>{"I have read and agreed to Alix's "}</span>
                      <Link
                        variant="body1"
                        className="endUserLicenseAgreementLink"
                        onClick={() => window.open(AGREEMENT_TERMS)}
                      >
                        {"License Agreement"}
                      </Link>
                      {", "}
                      <Link
                        variant="body1"
                        className="termsOfServiceLink"
                        onClick={() => window.open(TERMS_OF_SERVICE)}
                      >
                        {"Terms of Service"}
                      </Link>
                      {", "}
                      <Link
                        variant="body1"
                        className="termsOfUseLink"
                        onClick={() => window.open(TERMS_OF_USE)}
                      >
                        {"Terms of Use"}
                      </Link>
                      {", and "}
                      <Link
                        variant="body1"
                        className="privacyPolicyLink"
                        onClick={() => window.open(PRIVACY_POLICY)}
                      >
                        {"Privacy Policy"}
                      </Link>
                    </Typography>
                  </label>
                </div>
                <ErrorMessage name="acceptedEula" component="div" className="inputErrorMessage" />
              </div>

              <Container className="buttonsContainer">
                <ActionButton
                  type="submit"
                  ariaLabel="Sign In"
                  variant="solid"
                  className="saveNewPasswordButton"
                >
                  Create password
                </ActionButton>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </AuthViewLayout>
  );
}

export default ResetNewUserPassword;
