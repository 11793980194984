import React, { useState, createContext, FC, ReactNode } from 'react';

interface IManagementContext {
  formToDisplay: React.ReactNode;
  setFormToDisplay: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

const defaultState: IManagementContext = {
  formToDisplay: null,
  setFormToDisplay: () => {},
};

export const ManagementContext = createContext(defaultState);

export const ManagementProvider:FC<{ children: ReactNode }> = ({children}) => {
  const [formToDisplay, setFormToDisplay] = useState<React.ReactNode>(null);

  return (
    <ManagementContext.Provider
      value={{
        formToDisplay,
        setFormToDisplay,
      }}
    >
      {children}
    </ManagementContext.Provider>
  );
};
