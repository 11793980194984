import { FC } from 'react';

import {Box,
  Typography} from '@mui/material';

const NoMatch:FC = () => { 
  return (
    <>
      <Box
        sx = {{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant="h1" sx = {{pt: 10}}>Error 404</Typography>
        <Typography variant="h3">Page not found</Typography>
        <Typography variant="h3" sx = {{color: 'text.secondary'}}>
          The page you are looking for does not exist.
        </Typography>
        <Typography variant="h5" sx = {{color: 'text.secondary'}}>
          <a href="/home">Go back to the dashboard</a>
        </Typography>
      </Box>
    </>
  )
};

export default NoMatch;