import { FC } from 'react'
import styles from './MessageCard.module.scss'
import { ReactComponent as EmptyStateIcon } from '../../../../assets/icons/logos/alix-message.svg'
import Card from '../../atoms/Card/Card'
import Typography from '../../atoms/Typography/Typography'

interface MessageCardProps {
  testId?: string
  message: React.ReactNode
  icon?: React.ReactNode
  style?: React.CSSProperties
}

const MessageCard: FC<MessageCardProps> = ({
  testId, 
  message='No data available',
  icon= <EmptyStateIcon />,
  style,
}) => {
  return (
    <Card data-testid={testId} className={styles.messageCardContainer} style={style}>
      <div className={styles.icon}>
        {icon}
      </div>
      <Typography className={`${styles.message}`} variant='P1' font='UI1'>
        {message}
      </Typography>
    </Card>
  )
}

export default MessageCard
