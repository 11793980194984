import { useDecision } from "@optimizely/react-sdk";
import { AccountContext } from "../../providers/Authentication";
import { useContext, useEffect, useState } from "react";
import LoadingOverlay from "../../views/LoadingOverlay";
import { useRoleNavigate } from "../../hooks/useRoleNavigate";
import { EstateContext } from "../../providers/EstateProvider";
import { useTrackPageViewMutation } from "../../types/graphql";
import CrawlContent from "./CrawlContent";

interface ControlledRouteProps {
  body: React.ReactNode;
  requiresSession?: boolean;
  requiresAdminAuthorization?: boolean;
  optimizelyFlagIdentifier?: string;
  managementRoute?: string;
}

const ControlledRoute: React.FC<ControlledRouteProps> = ({
  body,
  requiresSession,
  optimizelyFlagIdentifier,
  requiresAdminAuthorization,
}) => {
  const {
    logout, checkAdmin, isSessionValid, optimizelyID,
  } = useContext(AccountContext);
  const [validSessionPresent, setValidSessionPresent] = useState<boolean>(false);
  const [routeIsEnabled, setRouteIsEnabled] = useState(false);
  const [featureFlagDecision] = useDecision(optimizelyFlagIdentifier || '', { autoUpdate: true }, { overrideUserId: optimizelyID }); // useDecision also exposes a clientReady boolean, but it is not used here, the clientReady boolean is used to tell if the SDK is ready to make decisions or not we need to implement this eventually in the future.
  const [escortOutPath, setEscortOutPath] = useState<string>(''); // used to redirect to home if feature flag is not enabled
  const navigate = useRoleNavigate();
  const isFeatureFlagControlled = !!optimizelyFlagIdentifier;
  const {selectedEstateId, currentEstate} = useContext(EstateContext)
  const [trackPageViewMutation] = useTrackPageViewMutation();
  // const {data: estateIdData} = useGetUserEstateIdQuery({variables: {estateId: selectedEstateId}});

  const routeSecurity = async () => {
    // Ensure the user is logged in and has the correct permissions
    const valid = await isSessionValid();
    const userHasAdminAuthorization = await checkAdmin();
    const path = window.location.pathname;
    const validUserRoute = userHasAdminAuthorization ? path.includes('/manage') : true;
    
    if ((requiresSession && !valid) || !validUserRoute) {
      console.log("requiresSession && !valid")
      setEscortOutPath('/login');
      return;
    } else if (requiresAdminAuthorization && !userHasAdminAuthorization) {
      console.log("requiresAdminAuthorization && !userHasAdminAuthorization")
      setEscortOutPath('/login');
      return;
    } else setValidSessionPresent(true);

    // Ensure the feature flag is enabled
    if (isFeatureFlagControlled && featureFlagDecision?.enabled === false) {
      console.log("isFeatureFlagControlled && featureFlagDecision.enabled === false")
      setEscortOutPath('/login');
      return;
    } else setRouteIsEnabled(true);
  };

  // Handle invocation of routeSecurity when the feature flag decision is updated
  useEffect(() => {
    // Do not perform routeSecurity until ready to do so
    if (isFeatureFlagControlled && featureFlagDecision?.reasons.includes("Optimizely SDK not configured properly yet.")) return;

    // If the route needs security, perform routeSecurity
    if (requiresSession || requiresAdminAuthorization || isFeatureFlagControlled) routeSecurity();
    else {
      setValidSessionPresent(true);
      setRouteIsEnabled(true);
    }
  }, [requiresSession, isFeatureFlagControlled, featureFlagDecision]);

  // handle redirecting to the correct page if necessary
  useEffect(() => {
    if (!escortOutPath) return;
    else if (escortOutPath.includes("login")) logout();
    else navigate(escortOutPath);
  }, [escortOutPath]);

  // Track the page view
  useEffect(() => {
    const estateId = currentEstate?.getUserEstate?.id || selectedEstateId || '';
    let page = window.location.pathname.split('/')[1];
    page = page.charAt(0).toUpperCase() + page.slice(1);
    
    // if (!estateId) return;
    console.log(`Tracking ${page} Page View`)
    trackPageViewMutation({
      variables: {
        estateId,
        page,
      },
    });
  }, [ selectedEstateId, currentEstate, window.location.pathname ]);

  // May the force be with you
  const [showCrawl, setShowCrawl] = useState(false);
  const releaseDateCode = ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Digit5', 'Digit2', 'Digit5', 'Digit1', 'Digit9', 'Digit7', 'Digit7'];
  let releaseDateIndex = 0;
  const handleReleaseDateCode = (event: KeyboardEvent) => {
    if (event.code === releaseDateCode[releaseDateIndex]) {
      releaseDateIndex++;
      if (releaseDateIndex === releaseDateCode.length) {
        setShowCrawl(true);
        releaseDateIndex = 0;
      }
    } else {
      releaseDateIndex = 0;
    }
    
    // after 42 seconds, reset the konami code and set showCrawl to false
    setTimeout(() => {
      releaseDateIndex = 0;
      setShowCrawl(false);
    }, 45000);
  };
  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      releaseDateIndex = 0;
      setShowCrawl(false);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleReleaseDateCode);
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleReleaseDateCode);
      window.addEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (validSessionPresent && routeIsEnabled) ? 
    <>
      {body}
      {showCrawl && (<CrawlContent />)}
    </> : 
    <LoadingOverlay />;
};

export default ControlledRoute;