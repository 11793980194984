import { FC, useContext } from 'react'
import './index.scss';

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useRenameDocumentMutation} from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Container, TextField, Typography } from '@mui/material';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DocumentContext } from '../../../../providers/DocumentProvider';
import { extname } from 'path';

type RenameDocumentFormProps = {
  testId?: string
}

const RenameDocumentForm: FC<RenameDocumentFormProps> = ({testId}) => {
  
  const {
    setDisplayModal, setModalContents, setPopupModalContents, setDisplayPopupModal, 
  } = useContext(LayoutContext)
  const { selectedEstateId, currentEstate } = useContext(EstateContext)
  const {
    selectedFile, refetchDocumentsList, folderRefetch, directoryPath,
  } = useContext(DocumentContext)
  const [ renameDocumentMutation ] = useRenameDocumentMutation();

  const handleSubmit = async (
    values: {
      updatedName: string,
      currentName: string,
      estateId: string,
    },
  ) => {
    const prefix = selectedFile?.id?.split("/").slice(0, -1).join("/")
    // Throw an error if prefix is null or undefined
    if (!prefix) {
      throw new Error("Prefix is null or undefined");
    }

    // TODO: remove logic to build full target key once we updated the mutation to have directory and fileName args
    const extension = selectedFile?.id ? selectedFile?.id.split('.').pop() : ""
    const fullTargetKey = `${prefix}/${values.updatedName.trim()}.${extension}`

    // don't submit if fullTargetKey is the same as what is already set
    if (selectedFile?.id === undefined || selectedFile?.id === fullTargetKey) {
      setDisplayModal(false);
      setPopupModalContents(null);
      setDisplayPopupModal(false);
      return;
    }

    //pull in related mutation
    renameDocumentMutation({
      variables: {
        targetKey: fullTargetKey,
        sourceKey: selectedFile?.id,
        estateId: values.estateId,
      },
      onCompleted: (data) => {
        setDisplayModal(false);
        setPopupModalContents(null);
        setDisplayPopupModal(false);
        refetchDocumentsList && refetchDocumentsList()
        folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]()
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed renameDocumentMutation call");
        console.error(e.message);
        alert(e.message);
      },
    });
  
    customSubmitAction()
  }

  const initialValues = {
    updatedName: selectedFile?.name || "",
    currentName: selectedFile?.name || "",
    estateId: selectedEstateId || currentEstate?.getUserEstate?.id || "",
  };

  const validationSchema = Yup.object().shape( {
    estateId: Yup.string().required("estateId is required"),
    currentName: Yup.string().required("currentName is required"),
    updatedName: Yup.string()
      .trim()
      .required("a non-empty name is required")
      .matches(/^[^/]*$/, 'Slash (/) is not allowed character for the name'),
  });

  const customSubmitAction = () => {
    // Optional: add additional actions to be executed on submit.
  }

  return (
    <div data-testid={testId} className='renameDocumentFormContainer'>
      <Typography variant='body1' className='renameDocumentFormHeader'>
        Rename your document
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">
                <div className={"inputWrapper estateIdFieldWrapper textInputWrapper"} key={"estateId0"} data-testid={testId}>
                  <Field
                    className={"estateIdInput fieldInput textInput"}
                    required={true}
                    id={"estateId"}
                    label={"Estate Id"}
                    name={"estateId"}
                    type={"hidden"}
                    autoComplete={"estateId"}
                    value={selectedEstateId}
                    // as={TextField}
                    error={submitCount > 0 && touched.estateId && !!errors.estateId}
                  />
                  <ErrorMessage name={"estateId"} component="div" className="inputErrorMessage"/>
                </div>


                <div className={"inputWrapper currentNameFieldWrapper textInputWrapper"} key={"currentName1"} data-testid={testId}>
                  <Field
                    className={"sourceKeyInput fieldInput textInput"}
                    id={"currentName"}
                    label={"Current Name"}
                    type={"hidden"}
                    name={"currentName"}
                    autoComplete={"currentName"}
                    disabled={true}
                    // as={TextField}
                    error={submitCount > 0 && touched.currentName && !!errors.currentName}
                  />
                  <ErrorMessage name={"currentName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper updatedNameFieldWrapper textInputWrapper"} key={"updatedName1"} data-testid={testId}>
                  <Field
                    className={"updatedNameInput fieldInput textInput"}
                    id={"updatedName"}
                    label={"New Name"}
                    name={"updatedName"}
                    type={"text"}
                    autoComplete={"updatedName"}
                    variant={"standard"}
                    as={TextField}
                    error={submitCount > 0 && touched.updatedName && !!errors.updatedName}
                  />
                  <ErrorMessage name={"updatedName"} component="div" className="inputErrorMessage"/>
                </div>

              </Container>
            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="cancelFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
              Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
               Done
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RenameDocumentForm