import { FC, useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';

import ActionButton from '../../components/DesignSystem/atoms/ActionButton/ActionButton';
import CreateOneComponentForm from '../../components/FormikForms/admin/CreateOneComponentForm/CreateOneComponentForm';
import { MobileLayout } from "../../components/Layouts";
import LoadingOverlay from '../LoadingOverlay';
import EstateComponentsBySubtype from './EstateComponentsBySubtype';
import EstateValueAndChart from './EstateValueAndChart';

import { AccountContext } from '../../providers/Authentication';
import { EstateContext } from '../../providers/EstateProvider';
import { LayoutContext } from '../../providers/LayoutProvider';

import { useGetUserEstateQuery, UserEstateRole } from '../../types/graphql';
import { EstateComponentGroup, EstateComponentGroupsBySubType } from './estateViewTypes';

interface MobileProps {
  testId?: string
}

const Mobile: FC<MobileProps> = ({ testId }) => {
  // Calculate the height and width that should be used for the PieChart
  const maximumSideLength = 295
  let pieChartWidth = Math.floor(.7 * (window.innerWidth || document.documentElement.clientWidth))
  if (pieChartWidth > maximumSideLength) pieChartWidth = maximumSideLength
  const {
    selectedEstateId, setRefetchCurrentEstate, setCurrentEstate,
  } = useContext(EstateContext)
  const { hasAdminAuthorization, showAdminTools } = useContext(AccountContext)
  const {
    setDisplayModal, setModalContents, setIsLoading,
  } = useContext(LayoutContext)

  // State for estate components grouped by subtype
  const [estateComponentsGroupedBySubtype, setEstateComponentsGroupedBySubtype] = useState<EstateComponentGroupsBySubType>({})

  // Get data from the backend
  const {
    loading, data, refetch: estateRefetch,
  } = useGetUserEstateQuery({
    variables: {
      estateId: selectedEstateId,
      where: { "userRole": { "equals": UserEstateRole.Executor } },
    },
    onCompleted: (data) => {
      if (data.getUserEstate) setCurrentEstate(data)
    },
    onError: (error) => {
      console.error('Error in EstateView', error)
    },
  })

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetchCurrentEstate(() => estateRefetch)
  }, [estateRefetch])

  useEffect(() => {
    if (data?.getUserEstate?.componentBreakDown) {
      const componentGroupOrder = {
        Blocked: 0,
        Pending: 1,
        InProgress: 2,
        Completed: 3,
      }

      const groupedBySubtype: EstateComponentGroupsBySubType = {}

      data.getUserEstate.estateComponents.forEach((componentDataItem) => {
        const {
          type, subType, estimatedValue, finalValue,
        } = componentDataItem
        const valueToAdd = finalValue ? parseFloat(finalValue) : parseFloat(estimatedValue)
        const key = subType as keyof EstateComponentGroupsBySubType

        if (!groupedBySubtype[key]) groupedBySubtype[key] = {
          type,
          subType,
          totalValue: 0,
          components: [],
        }
        groupedBySubtype[key]!.totalValue += valueToAdd
        groupedBySubtype[key]!.components.push(componentDataItem)
      })

      const sortedGroupedBySubtype: EstateComponentGroupsBySubType = {}

      for (const subType in groupedBySubtype) {
        const group = groupedBySubtype[subType as keyof EstateComponentGroupsBySubType] as EstateComponentGroup
        const sortedComponents = group.components.sort((a, b) => {
          const statusA = componentGroupOrder[a.status]
          const statusB = componentGroupOrder[b.status]
          return statusA - statusB
        })

        sortedGroupedBySubtype[subType as keyof EstateComponentGroupsBySubType] = {
          ...group,
          components: sortedComponents,
        }
      }

      setEstateComponentsGroupedBySubtype(sortedGroupedBySubtype)
    }
  }, [data])

  const handleAdminCreateClick = () => {
    setModalContents(<CreateOneComponentForm />)
    setDisplayModal(true)
  }
  return (
    <MobileLayout includeSubHeader={false}>
      {
        loading ?
          <LoadingOverlay />
          :
          <div className="estateOuterContainer" data-testid='estate-view'>
            <EstateValueAndChart testId={'estate-value-and-chart'} />

            <Stack direction={'row'} className='adminButtonsContainer'>
              {
                showAdminTools && hasAdminAuthorization && (
                  <ActionButton
                    className='createComponentButton'
                    variant='outline'
                    ariaLabel='Add new estate component'
                    handleClick={handleAdminCreateClick}
                  >
                    <AddIcon />
                  </ActionButton>
                )
              }
            </Stack>

            <EstateComponentsBySubtype data={{ estateComponentsGroupedBySubtype }} testId={'estate-components-by-subtype'} />
          </div>
      }
    </MobileLayout>
  )
}

export default Mobile
