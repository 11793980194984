const handleNestedValues = (values: Record<string, any>, formData: Record<string, { unextractedType: string }>) => {
  const newValues = {...values}; // Create a new object
  Object.keys(newValues).forEach(key => {
    if (newValues[key] === '') {
      delete newValues[key];
    } else if (formData[key] && formData[key].unextractedType.includes('Nested')) {
      newValues[key] = {connect: {id: newValues[key]}};
    }
  });
  return newValues; // Return the new object
}

const filterUndefinedValues = (values: any) => {
  const filteredValues: Record<string, any> = Object.entries(values).reduce((acc: Record<string, any>, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return filteredValues;
};

const stripPhoneInput = (phoneInputString: string) => {
  // const nestedValues = handleNestedValues(values, formData) as CreateUserInput
  // strip all whitespace and non-alphanumeric characters from phone number
  phoneInputString = phoneInputString.replace(/\D/g, '');

  // add country code to phone number
  phoneInputString = '+1' + phoneInputString;

  return phoneInputString;
};

const USStatesAndTerritoriesAbbreviations = [
  // US States
  "AK",  "AL",  "AR",  "AZ",  "CA",  "CO",  "CT",  "DE",  "FL",  "GA",  "HI",  "IA",  "ID",  "IL",  "IN",
  "KS",  "KY",  "LA",  "MA",  "MD",  "ME",  "MI",  "MN",  "MO",  "MS",  "MT",  "NC",  "ND",  "NE",  "NH",
  "NJ",  "NM",  "NV",  "NY",  "OH",  "OK",  "OR",  "PA",  "RI",  "SC",  "SD",  "TN",  "TX",  "UT",  "VA",
  "VT",  "WA",  "WI",  "WV",  "WY",
  // US Territories
  "AS",  "DC",  "GU",  "MP",  "PR",  "TT",  "VI",
]

export {
  handleNestedValues,
  filterUndefinedValues,
  stripPhoneInput,
  USStatesAndTerritoriesAbbreviations,
}
