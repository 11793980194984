import './typography.scss';

import { FC, ReactNode } from 'react'

interface TypographyProps {
  casing?: 'uppercase' | 'lowercase' | 'capitalize'
  children?: ReactNode
  className?: string
  font: 'UI1' | 'UI2'
  fontWeight?: 'regular' | 'medium' | 'semibold'
  useDangerouslySetInnerHTML?: string
  variant: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'P1' | 'P2' | 'P3' | 'L1' | 'L2' | 'L3'
}

const Typography: FC<TypographyProps> = ({
  casing,
  children,
  className,
  font,
  fontWeight,
  useDangerouslySetInnerHTML,
  variant,
}) => {
  let body;
  const classes = [
    variant,
    font,
    className,
    casing,
    fontWeight,
  ].filter((str) => str).join(" ");

  switch (variant) {
  case 'H1':
    body = <h1 className={classes}>{children}</h1>
    break;
  case 'H2':
    body = <h2 className={classes}>{children}</h2>
    break;
  case 'H3':
    body = <h3 className={classes}>{children}</h3>
    break;
  case 'H4':
    body = <h4 className={classes}>{children}</h4>
    break;
  case 'H5':
    body = <h5 className={classes}>{children}</h5>
    break;
  case 'H6':
    body = <h6 className={classes}>{children}</h6>
    break;
  case 'L1':
  case 'L2':
  case 'L3':
  case 'P1':
  case 'P2':
  case 'P3':
    body = <p className={classes}>{children}</p>
    break;
  }

  if (useDangerouslySetInnerHTML) {
    return <div className='wordWrap' dangerouslySetInnerHTML={{ __html: useDangerouslySetInnerHTML }} />
  }

  return body;
}

export default Typography