import { FC, useContext, useRef } from 'react'
import './FilePickerInput.scss'
import useFileUpload from '../../../hooks/useFileUpload'
import { DocumentContext } from '../../../providers/DocumentProvider'

interface FilePickerInputProps {
  testId?: string,
  destinationFolder: string,
  altText?: React.ReactNode,
  expanded?: boolean,
  handleExpand?: () => void,
}

const FilePickerInput: FC<FilePickerInputProps> = ({
  testId, destinationFolder, altText, expanded=true, handleExpand,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const { handleUploadSubmit } = useFileUpload();
  const { folderFiles, directoryPath } = useContext(DocumentContext);
  const htmlIdentifier = `filePicker${destinationFolder}`;

  // Add files to the files state from Select File button
  const selectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const appendIndex = event.target.files.length > 1;
      const files = [...event.target.files].map((file, index) => {
        // return the file if the name of the file's name (not including extension) is not 'image'
        if (file.name.split('.').slice(0, -1).join('.') !== 'image') return file;

        // Generate a unique identifier for each file
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const milliseconds = date.getMilliseconds();
        const indexAppend = appendIndex ? index : '';
        const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${indexAppend}`;

        // Split the filename and extension
        const parts = file.name.split('.');
        const extension = parts.pop();
        const filename = parts.join('.');

        // Create a new file with the desired name
        return new File([file], `${filename}_${timestamp}.${extension}`, { type: file.type });
      });

      handleUploadSubmit({
        files,
        destinationFolder,
      });
    }
  }

  return (
    <div data-testid={testId} className='filePickerInputContainer'>
      <label 
        className='filePickerLabel'
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            document.getElementById(htmlIdentifier)?.click();
          }
        }}
        onClick={() => {
          // we are using thing instead of htmlFor because we want propagation of the click event
          document.getElementById(htmlIdentifier)?.click();

          if (!expanded && handleExpand) handleExpand();
        }}
      >
        {altText ? altText : folderFiles && Object.keys(folderFiles[directoryPath]).length > 0 ? 'Upload More' : 'Upload Files and Images'}
      </label>
      <input
        ref={fileInput}
        type="file"
        id={htmlIdentifier}
        name={htmlIdentifier}
        accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
        multiple
        onChange={selectHandler}
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default FilePickerInput