import { FC } from 'react'
import styles from './NotificationItemList.module.scss'
import List from '../../atoms/List/List'
import NotificationItem from '../NotificationItem/NotificationItem'

interface NotificationItemListProps {
  testId?: string
  containerClassName?: string
  notifications: any[]
  refetch: () => Promise<void>
}

const NotificationItemList: FC<NotificationItemListProps> = ({
  testId,
  containerClassName,
  notifications,
  refetch,
}) => {
  const containerClass = [containerClassName, styles.notificationItemListContainer].filter(str => !!str).join(' ')

  return (
    <div data-testid={testId} className={containerClass}>
      <List
        testId='notificationItemList'
        className='noScroll'
        data={notifications}
        direction='vertical'
        listItemWidth='auto'
        listItemMaxWidth='auto'
        flex='1'
        gap={0}
        wrap={false}
        renderItem={(item: any, index: number) => (
          <NotificationItem
            testId={`notificationItem-${index}`}
            key={index}
            id={item.id}
            title={item.title}
            message={item.message}
            unread={item.seenAt === null}
            route={item.route}
            routingError={item.routingError}
            createdAt={item.createdAt}
            refetch={refetch}
          />
        )}
      />
    </div>
  )
}

export default NotificationItemList
