import { createContext, useState, FC, ReactNode } from 'react';
import { Component } from '../types/graphql';

interface EstateComponentContextProps {
  currentEstateComponent: Component | null;
  setCurrentEstateComponent: React.Dispatch<React.SetStateAction<any>>;
  estateComponentDetailsListRefetch: () => Promise<any> | undefined;
  setEstateComponentDetailsListRefetch: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  selectedComponentContentCardId: string;
  setSelectedComponentContentCardId: React.Dispatch<React.SetStateAction<string>>;
}

const defaultState: EstateComponentContextProps = {
  currentEstateComponent: null,
  setCurrentEstateComponent: () => { },
  estateComponentDetailsListRefetch: () => undefined,
  setEstateComponentDetailsListRefetch: () => { },
  selectedComponentContentCardId: '',
  setSelectedComponentContentCardId: () => { },
}

export const EstateComponentContext = createContext(defaultState);

export const EstateComponentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentEstateComponent, setCurrentEstateComponent] = useState<any>(defaultState.currentEstateComponent);
  // const [refetchEstateComponentList, setRefetchEstateComponentList] = useState<EstateComponentContextProps['']>(defaultState.refetchActionList);
  const [estateComponentDetailsListRefetch, setEstateComponentDetailsListRefetch] = useState<EstateComponentContextProps['estateComponentDetailsListRefetch']>(defaultState.estateComponentDetailsListRefetch);
  const [selectedComponentContentCardId, setSelectedComponentContentCardId] = useState<string>('');

  return (
    <EstateComponentContext.Provider
      value={{
        currentEstateComponent,
        setCurrentEstateComponent,
        estateComponentDetailsListRefetch,
        setEstateComponentDetailsListRefetch,
        selectedComponentContentCardId,
        setSelectedComponentContentCardId,
      }}
    >
      {children}
    </EstateComponentContext.Provider>
  );
};