import {FC} from 'react'
import useResponsiveComponent from '../../hooks/useResponsiveComponent'

import MobileView from './Mobile'
import DesktopView from './Desktop'

const CareTeamView: FC = () => {
  const ResponsiveComponent = useResponsiveComponent({
    mobile: MobileView,
    desktop: DesktopView,
  })

  return <ResponsiveComponent />
}

export default CareTeamView