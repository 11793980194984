import { FC } from 'react'
import './EstateComponentSummary.scss'
import Typography from '../../atoms/Typography/Typography';
import { ComponentSubType, useGetEstateComponentSummaryQuery } from '../../../../types/graphql';
import LoadingOverlay from '../../../../views/LoadingOverlay';
import { formatDollarAmount } from '../../../../helpers';
import ComponentSubTypeIcon from '../../../ComponentSubTypeIcon/ComponentSubTypeIcon';
import EstateComponentSummarySubTitle from './EstateComponentSummarySubtitle/EstateComponentSummarySubtitle';
import { EstateComponent } from '../../../../views/EstateView';
import useStatus from '../../../../hooks/useStatus';

interface EstateComponentSummaryProps {
  estateComponentId: string // Should be a UUID
  testId?: string
}

const EstateComponentSummary: FC<EstateComponentSummaryProps> = ({testId, estateComponentId}) => {
  const {data, loading} = useGetEstateComponentSummaryQuery({variables: { componentId: estateComponentId }});
  const { statusMsgShort } = useStatus(data?.getEstateComponent?.status);

  if (loading) return <LoadingOverlay />;
  return (
    <div data-testid={testId} className='estateComponentSummaryContainer'>

      <div className="estateComponentDetailsHeadersAndIconContainer">
        <div className="estateComponentDetailsHeaders">
          <Typography variant="H3" font="UI1" className="estateComponentDetailsHeader">
            {data?.getEstateComponent?.name}
          </Typography>
          <EstateComponentSummarySubTitle component={data?.getEstateComponent as EstateComponent} />
        </div>
        <div className="estateComponentDetailsTypeIcon">
          <ComponentSubTypeIcon subType={data?.getEstateComponent?.subType || '' as ComponentSubType} />
        </div>
      </div>

      <div className="estateComponentDetailsSummaryContainer">
        <div className="estimatedValueDetailsContainer">
          { data?.getEstateComponent?.finalValue ?
            <>
              <Typography variant="H6" font="UI1" className='greyText'>
            Final Value
              </Typography>
              <Typography variant="H5" font="UI1">
                {formatDollarAmount(data?.getEstateComponent?.finalValue || 0)}
              </Typography> 
            </>
            :
            <>
              <Typography variant="H6" font="UI1" className='greyText'>
            Estimated Value
              </Typography>
              <Typography variant="H5" font="UI1">
                {formatDollarAmount(data?.getEstateComponent?.estimatedValue || 0)}
              </Typography> 
            </>
          }
        </div>
        <div className="settlementStatusDetailsContainer">
          <Typography variant="H6" font="UI1" className='greyText'>
          Settlement
          </Typography>
          <Typography variant="H5" font="UI1">
            {statusMsgShort}
          </Typography>
        </div>
      </div>

    </div>
  )
}

export default EstateComponentSummary
