const useAutoScroll = (
  scrollToClassName: string,
  selectedVisibility: 'showBottom' | 'showFull',
) => {
  if (!scrollToClassName) return;
  
  const elements = document.querySelectorAll(`.${scrollToClassName}`);
  const child = elements[elements.length - 1];
  if (!child) return;

  // Find the closest ancestor to this child that has the class "scrollableList"
  const parent = child.closest('.scrollableList');
  if (!parent) return;
  
  const childRect = child.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();
  
  if (!childRect || !parentRect) return;

  // Calculate the difference between the top of the child and the top of the parent
  let amountToScroll;
  switch (selectedVisibility) {
  case 'showBottom':
    amountToScroll = (childRect.bottom - (childRect.height / 3)) - parentRect.top;
    break;
  case 'showFull':
    amountToScroll = (childRect.bottom - (childRect.height / 3)) - parentRect.top - childRect.height;
    break;
  default:
    break;
  }
  // const amountToScroll = (childRect.bottom - (childRect.height / 3)) - parentRect.top;

  // // Calculate the difference between the top of the child and the top of the parent
  // const amountToScroll = (childRect.bottom - (childRect.height / 3)) - parentRect.top - childRect.height;

  // Scroll the parent container by the calculated amount
  parent.scrollBy({
    top: amountToScroll,
    behavior: 'smooth', 
  });

  return true;
}

export default useAutoScroll;