import { Box } from "@mui/material";
import ContentExplorerComponent from "box-ui-elements/es/elements/content-explorer";
import "box-ui-elements/dist/explorer.css";
import { IntlProvider } from "react-intl";
import "./ContentExplorer.scss"

interface ContentExplorerProps {
  token: string;
  rootFolderId?: string;
}

const ContentExplorer: React.FC<ContentExplorerProps> = ({ token, rootFolderId }) => { 
  const handleDownload = (data: any) => {
    const {
      id, name, size, sharedLink, 
    } = data[0];

    const downloadUrl = `https://api.box.com/2.0/files/${id}/content?access_token=${token}`;
    const fileData = {
      id,
      name,
      size,
      sharedLink,
      downloadUrl, 
    };

    // @ts-expect-error window extended feature for webview
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // @ts-expect-error window extended feature for webview
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'download',
        file: fileData,
      }));
    }
  };
  return (
    <Box className="contentExplorerContainer">
      <IntlProvider locale="en">
        <ContentExplorerComponent
          canShare={false}
          canPreview={true}
          contentPreviewProps={{
            contentSidebarProps: {
              detailsSidebarProps: {
                hasProperties: false,
                hasNotices: false,
                hasAccessStats: false,
                hasClassification: false,
                hasRetentionPolicy: false,
              },
              hasActivityFeed: false,
              hasMetadata: false,
              hasSkills: false,
              hasVersions: false,
            },
          }}
          rootFolderId={rootFolderId || 0}
          token={token}
          logoUrl= ''
          onDownload={handleDownload} 
        />
      </IntlProvider>
    </Box>
  );
};

export default ContentExplorer;
