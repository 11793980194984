import { FC, useContext, useState } from 'react'
import styles from './KeyDateList.module.scss'
import KeyDateCard from '../KeyDateCard/KeyDateCard'
import { useGetKeyDatesQuery } from '../../../../types/graphql'
import { EstateContext } from '../../../../providers/EstateProvider'
import MessageCard from '../MessageCard/MessageCard'
import Typography from '../../atoms/Typography/Typography'
import List from '../../atoms/List/List'


interface KeyDateListProps {
  testId?: string
  className?: string
  wrap?: boolean
  gap?: number
  listItemWidth?: number | string
  listItemMaxWidth?: string | number
  flex?: string
  direction?: 'horizontal' | 'vertical'
}

const KeyDateList: FC<KeyDateListProps> = ({
  testId = 'KeyDateList',
  direction = 'horizontal',
  className, 
  wrap=true, 
  gap=20, 
  listItemWidth='auto', 
  listItemMaxWidth='auto', 
  flex,
}) => {
  const { selectedEstateId } = useContext(EstateContext)
  const [keyDates, setKeyDates] = useState<any[]>([])

  useGetKeyDatesQuery(
    {
      variables: {estateId: selectedEstateId},
      onCompleted: (data) => {
        setKeyDates(data.getUserEstate?.keyDates || [])
      },
    },
  )

  return (
    <div data-testid={testId} className={`${styles.keyDateListContainer} ${className}`}>
      { 
        <List 
          testId='keyDateList'
          data={keyDates}
          direction={direction} 
          listItemWidth={listItemWidth}
          listItemMaxWidth={listItemMaxWidth}
          flex={flex}
          gap={gap}
          wrap={wrap}
          renderItem={(item: any, index: number) => (
            <KeyDateCard
              testId={`keyDateCard-${index}`}
              key={index}
              contentId={item.id}
              componentId={item.componentId}
              displayDate={item.displayDate}
              completeByType={item.completeByType}
              title={item.header}
            />
          )}
        />}
    </div>
  )
}

export default KeyDateList
