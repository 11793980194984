import { ComponentSubType } from '../types/graphql';
import colors from './colors';

const componentSubTypeToTintColorMapping: { [key in ComponentSubType]: string } = {
  [ComponentSubType.BankAccount]: colors.accentTints.accent660, // cornflower-60
  [ComponentSubType.BillToCancel]: colors.baseColors.base2, // sand
  [ComponentSubType.Debt]: colors.baseTints.base360, // chocolate-60
  [ComponentSubType.Discovery]: colors.baseColors.base2, // sand
  [ComponentSubType.Distribution]: colors.baseColors.base2, // sand
  [ComponentSubType.Investment]: colors.accentTints.accent360, // coral-60
  [ComponentSubType.LifeInsurance]: colors.accentTints.accent260, // salmon-60
  [ComponentSubType.PersonalProperty]: colors.accentTints.accent860, // mint-60
  [ComponentSubType.Probate]: colors.baseColors.base2, // sand
  [ComponentSubType.RealEstate]: colors.accentTints.accent760, // pine-60
  [ComponentSubType.Retirement]: colors.accentTints.accent560, // lavender-60
  [ComponentSubType.Tax]: colors.baseColors.base2, // sand
  [ComponentSubType.UnclaimedProperty]: colors.baseColors.base2, // sand
  [ComponentSubType.Vehicle]: colors.accentTints.accent160, // olive-60
};

const componentSubTypeToPrimaryColorMapping: { [key in ComponentSubType]: string } = {
  [ComponentSubType.BankAccount]: colors.accentColors.accent6, // cornflower
  [ComponentSubType.BillToCancel]: colors.baseColors.base2, // sand
  [ComponentSubType.Debt]: colors.baseColors.base3, // chocolate
  [ComponentSubType.Discovery]: colors.baseColors.base2, // sand
  [ComponentSubType.Distribution]: colors.baseColors.base2, // sand
  [ComponentSubType.Investment]: colors.accentColors.accent3, // coral
  [ComponentSubType.LifeInsurance]: colors.accentColors.accent2, // salmon
  [ComponentSubType.PersonalProperty]: colors.accentColors.accent8, // mint
  [ComponentSubType.Probate]: colors.baseColors.base2, // sand
  [ComponentSubType.RealEstate]: colors.accentColors.accent7, // pine
  [ComponentSubType.Retirement]: colors.accentColors.accent5, // lavender
  [ComponentSubType.Tax]: colors.baseColors.base2, // sand
  [ComponentSubType.UnclaimedProperty]: colors.baseColors.base2, // sand
  [ComponentSubType.Vehicle]: colors.accentColors.accent1, // olive
};

export { componentSubTypeToPrimaryColorMapping, componentSubTypeToTintColorMapping };
