import { FC, useContext } from 'react'
import './index.scss'
import { UserLayout } from '../../components/Layouts'
import { AccountContext } from '../../providers/Authentication'
import { LayoutContext } from '../../providers/LayoutProvider'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Typography } from '@mui/material'

interface AdminLandingProps {
  testId?: string
}

const AdminLanding: FC<AdminLandingProps> = ({testId}) => {
  const {showAdminTools} = useContext(AccountContext);
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)

  const handleAdminEstateManagementClick = (formComponent: ReactJSXElement) => {
    setModalContents(formComponent)
    setDisplayModal(true)
  }

  return (
    <UserLayout>
      <div data-testid={testId} className='adminLandingContainer'>
        <Typography className='landingMessage'>Select an Estate to Manage</Typography>
      </div>
    </UserLayout>
  )
}

export default AdminLanding