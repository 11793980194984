import { FC, useContext, useState } from 'react'
import Typography from '../../atoms/Typography/Typography'
import ActionButton from '../../atoms/ActionButton/ActionButton'
import NotificationBell from '../../atoms/NotificationBell/NotificationBell';

import styles from './NotificationBellAsPopupMenu.module.scss'
import NotificationsPopupMenu from '../NotificationsPopupMenu/NotificationsPopupMenu';
import { AccountContext } from '../../../../providers/Authentication';
import { EstateContext } from '../../../../providers/EstateProvider';
import { useGetUnseenNotificationsCountQuery } from '../../../../types/graphql';

interface NotificationBellAsPopupMenuProps {
  bellIconClassName?: string;
  className?: string;
  testId?: string;
}


const NotificationBellAsPopupMenu: FC<NotificationBellAsPopupMenuProps> = ({
  testId,
  className,
  bellIconClassName,
}) => {
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
  const [hasUnseenNotifications, setHasUnseenNotifications] = useState(false);
  const { currentEstate } = useContext(EstateContext);
  const { userId, hasAdminAuthorization } = useContext(AccountContext);

  const {refetch} = useGetUnseenNotificationsCountQuery({
    variables: {
      estateId: currentEstate?.getUserEstate?.id,
      userId: userId,
    },
    onCompleted: (data) => {
      setHasUnseenNotifications(data.getUnseenNotificationsCount > 0);
    },
  });

  const refetchNotificationsCount = async () => {
    const response = await refetch()
    setHasUnseenNotifications(response.data.getUnseenNotificationsCount > 0);
  }

  const containerClassName = `${styles.notificationBellAsPopupMenuContainer} ${className || ''}`.trim();

  const clickHandler = () => {
    if (!hasAdminAuthorization) setShowNotificationsPopup(!showNotificationsPopup);
  }

  return (
    <div data-testid={testId} className={containerClassName}>
      {hasUnseenNotifications && <div className={styles.unseenNotificationsMarker}></div>}
      <ActionButton
        ariaLabel="Notifications"
        tabIndex={0}
        variant="solid"
        className={styles.bellButton}
        testId={testId}
        handleClick={clickHandler}
      >
        <NotificationBell className={bellIconClassName}/>
      </ActionButton>
      { showNotificationsPopup && <NotificationsPopupMenu
        showNotificationsPopup={showNotificationsPopup}
        setShowNotificationsPopup={setShowNotificationsPopup}
        refetchNotificationsCount={refetchNotificationsCount}
      />}
    </div>
  )
}

export default NotificationBellAsPopupMenu
