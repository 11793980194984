import { FC, useContext } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useMoveDocumentsMutation, MoveDocumentsInput } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import {Container, CircularProgress } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import Typography from '../../../DesignSystem/atoms/Typography/Typography';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import FolderSelector from '../../formikInputs/FolderSelector/FolderSelector';
import { DocumentContext } from '../../../../providers/DocumentProvider';

  type MoveDocumentsFormProps = {
    testId?: string
    estateId?: string
    documentIds: string[]
    refetch?: () => Promise<any> | undefined
  }

const MoveDocumentsForm: FC<MoveDocumentsFormProps> = ({
  testId,
  documentIds,
  estateId,
  refetch,
}) => {
    
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const { setFilesToMove } = useContext(DocumentContext)
  const [ moveDocumentsMutation, {loading} ] = useMoveDocumentsMutation();

  const handleSubmit = async (
    values: MoveDocumentsInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as MoveDocumentsInput
    moveDocumentsMutation({
      variables: {data: nestedValues},
      onCompleted: async (data) => {
        resetForm();
        refetch && refetch()?.then(() => {
          setFilesToMove(null);
          setDisplayModal(false);
        })
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed moveDocumentsMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
    
    customSubmitAction()
  }

  const initialValues = {
    destinationFolder: "",
    documentIds: documentIds,
    estateId: estateId || "",
  };

  const validationSchema = Yup.object().shape({ 
    destinationFolder: Yup.string().required("destinationFolder is required"), 
    documentIds: Yup.array().of(Yup.string()).required("documentIds is required"),
    estateId: Yup.string().required("estateId is required"),
  });

  const customSubmitAction = () => {
    // Optional: add additional actions to be executed on submit.
  }

  const filePathNames = () => {
    return documentIds.map((documentId) => {
      return documentId.split('/').pop()
    })
  }

  return (
    <>
      <div data-testid={testId} className='moveDocumentsFormContainer'>
        <Typography font='UI1' variant='H4'  className='moveDocumentsFormHeader'>
          Move Documents Form
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors, touched, submitCount, setFieldValue, values, 
          }) => (
            <Form noValidate>
              <Container className="formContainer">
                <Container className="formSection">
                  <div className="selectedFileList">
                    <Typography font='UI1' variant='P1'  className='selectedListHeader'>
                      Selected Document(s)
                    </Typography>
                    <Typography font='UI1' variant='P3'  className='selectedFileNames'>
                      { 
                        filePathNames().map((name, index) => (
                          <div key={index} className='fileToMove'>{name}</div>
                        ))
                      }
                    </Typography>
                  </div>

                  <div className={"inputWrapper destinationFolderFieldWrapper textInputWrapper"} key={"destinationFolder0"} data-testid={testId}>
                    {/* <Field
                      className={"destinationFolderInput fieldInput textInput"}
                      required={true}
                      id={"destinationFolder"}
                      label={"Destination Folder"}
                      name={"destinationFolder"}
                      type={"text"}
                      autoComplete={"destinationFolder"}
                      as={TextField}
                      error={submitCount > 0 && touched.destinationFolder && !!errors.destinationFolder}
                    /> */}
                    <FolderSelector
                      fieldName="destinationFolder"
                      labelText="Choose Folder to move to"
                      initialValue={values.destinationFolder}
                    />
                    <ErrorMessage name={"destinationFolder"} component="div" className="inputErrorMessage"/>
                  </div>
        
                  <div className={"inputWrapper documentIdsFieldWrapper textInputWrapper"} key={"documentIds1"} data-testid={testId}>
                    <Field
                      className={"documentIdsInput fieldInput textInput"}
                      required={true}
                      id={"documentIds"}
                      label={"Document Ids"}
                      name={"documentIds"}
                      type={"hidden"}
                      autoComplete={"documentIds"}
                      // as={TextField}
                      error={submitCount > 0 && touched.documentIds && !!errors.documentIds}
                    />
                    <ErrorMessage name={"documentIds"} component="div" className="inputErrorMessage"/>
                  </div>
        
                  <div className={"inputWrapper estateIdFieldWrapper textInputWrapper"} key={"estateId2"} data-testid={testId}>
                    <Field
                      className={"estateIdInput fieldInput textInput"}
                      required={true}
                      id={"estateId"}
                      label={"Estate Id"}
                      name={"estateId"}
                      type={"hidden"}
                      autoComplete={"estateId"}
                      // as={TextField}
                      error={submitCount > 0 && touched.estateId && !!errors.estateId}
                    />
                    <ErrorMessage name={"estateId"} component="div" className="inputErrorMessage"/>
                  </div>
                </Container>
              </Container>

              <Container className="buttonsContainer">
                <ActionButton
                  ariaLabel="Submit form"
                  variant="outline"
                  className="submitFormButton"
                  handleClick={() => {
                    setDisplayModal(false);
                    setModalContents(null);
                  }}
                >
                  Cancel
                </ActionButton>

                { !loading && 
                  <ActionButton
                    type="submit"
                    ariaLabel="Submit form"
                    variant="solid"
                    className="submitFormButton"
                  >
                  Submit
                  </ActionButton>
                }
                {loading && 
                  <div className='loadingSpinner'>
                    <CircularProgress />
                  </div>
                }
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default MoveDocumentsForm