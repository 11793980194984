import { FC, useContext } from 'react'
import './FilePopupOptions.scss'
import { Typography } from '@mui/material'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/fileTypes/download.svg'
import {ReactComponent as RenameIcon} from '../../../assets/icons/fileTypes/pencil-line.svg'
import ActionButton from '../../DesignSystem/atoms/ActionButton/ActionButton'
import useFileUpload from '../../../hooks/useFileUpload'
import { LayoutContext } from '../../../providers/LayoutProvider'
import RenameDocumentForm from '../../FormikForms/admin/RenameDocumentForm/RenameDocumentForm'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider'

interface FilePopupOptionsProps {
  testId?: string
  file: IFile,
  showUnderText?: boolean,
}

const FilePopupOptions: FC<FilePopupOptionsProps> = ({
  testId,
  file,
  showUnderText = true,
}) => {
  const { 
    setDisplayPopupModal,
    setPopupModalContents,
    setPopupModalTitle,
  } = useContext(LayoutContext)
  const { setSelectedFile } = useContext(DocumentContext)
  const { downloadFile } = useFileUpload()

  const handleRename = () => {
    setPopupModalTitle('')
    setSelectedFile(file )
    setPopupModalContents(<RenameDocumentForm/>)
    setDisplayPopupModal(true)
  }

  const handleDownload = () => {
    downloadFile(file.signedUrl || '')
    setDisplayPopupModal(false)
  }

  return (
    <div data-testid={testId} className='filePopupOptionsContainer'>
      <div className='optionButtonContainer'>
        <ActionButton 
          ariaLabel='Rename File'
          variant='solid'
          className='popupIconBackground'
          handleClick={handleRename}
        >
          <RenameIcon className='RenameIcon'/>
        </ActionButton>
        {showUnderText && <Typography variant='body1'>Rename</Typography>}
      </div>
      <div className='optionButtonContainer'>
        <ActionButton 
          className='popupIconBackground'
          variant='solid'
          ariaLabel='Download File'
          handleClick={handleDownload}
        >
          <DownloadIcon className='DownloadIcon'/>
        </ActionButton>
        {showUnderText && <Typography variant='body1'>Download</Typography>}
      </div>
    </div>
    
  )
}

export default FilePopupOptions