import { FC } from 'react'
import './CareTeamCard.scss'
import { Card, Typography } from '@mui/material'
import avatarImage from '../../assets/images/careteam1-L.png'
import LinkButton from '../DesignSystem/atoms/LinkButton/LinkButton'
import CircleIcon from '../CircleIcon/CircleIcon'
import { ReactComponent as TalkBubbleSvgIcon } from '../../assets/icons/navigation/talk-bubble-icon.svg'

interface CareTeamCardProps {
  testId?: string
  className?: string
}

const CareTeamCard: FC<CareTeamCardProps> = ({testId, className}) => {
  return (
    <div data-testid={testId} className={`careTeamCardContainer ${className}`}>
      <Card className="careTeamCard">
        <img src={avatarImage} alt="Help Avatar" />
        <div className="cardBody">
          <Typography variant="h4">Need Help?</Typography>
          <Typography variant="body1">{"Email us anytime and we'll get back to you ASAP."}</Typography>
          <LinkButton href='mailto:careteam@meetalix.com' ariaLabel={"Send email to care team"} variant={"borderless"} className='connectButton'>
            <CircleIcon 
              containerClass='connectIconContainer' 
              backgroundClass='connectIconBackground' 
              iconComponent={
                <TalkBubbleSvgIcon 
                  className='connectIconSvg'
                />
              } 
              backgroundColorHexCode='#326E5E'/>
            <Typography variant='body1'>
              Get in Touch
            </Typography>
          </LinkButton>
        </div>
      </Card>
    </div>
  )
}

export default CareTeamCard