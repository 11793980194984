import React, { useState, createContext, FC, ReactNode, useEffect } from 'react';
import { ActionCardStatus, ActionCardType, AssigneeType, ComponentSubType, ComponentType, Maybe, Scalars } from '../types/graphql';

export type Action = {
  __typename?: 'ActionCard',
  id: string,
  estateId: string,
  componentId?: string | null,
  estateDetailId?: string | null,
  taskId?: string | null,
  type: ActionCardType,
  status: ActionCardStatus,
  name: string,
  description?: Maybe<string> | null | undefined,
  encryptedReply: boolean,
  assignee?: AssigneeType | null,
  formFieldName?: string | null,
  appointmentTime?: any | null,
  appointmentLocation?: string | null,
  calendarIcs?: string | null,
  calendarLink?: string | null,
  documentDownloadUrl?: string | null,
  documentUploadUrl?: string | null,
  documentSignUrl?: string | null,
  mapUrl?: string | null,
  createdAt: any,
  updatedAt: any,
  completedAt?: any | null,
  locked: boolean;
  component?: {
    __typename?: 'Component',
    id: string,
    name: string,
    subType: ComponentSubType,
    type: ComponentType,
    accountNumber?: string | null,
    descriptor: string
  } | null,
  estateDetail?: {
    __typename?: 'EstateDetail',
    id: string,
    name: string,
    updatedAt: any,
    value: any | null
  } | null
}

export type ActionComponent = {
  __typename?: 'Component',
  id: string,
  name: string,
  subType: ComponentSubType,
  type: ComponentType,
  accountNumber?: string | null,
  descriptor: string
}

interface IActionContext {
  actions: Action[] | undefined;
  actionsLoading: boolean;
  setActionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  completedByCustomerActions: Action[] | undefined;
  setCompletedByCustomerActions: React.Dispatch<React.SetStateAction<Action[] | undefined>>;
  setActions: React.Dispatch<React.SetStateAction<Action[] | undefined>>;
  currentAction: Action | null,
  setCurrentAction: React.Dispatch<React.SetStateAction<Action | null>>
  refetchCurrentAction: () => Promise<any> | undefined;
  setRefetchCurrentAction: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
  refetchActionList: () => Promise<any> | undefined;
  setRefetchActionList: React.Dispatch<React.SetStateAction<() => Promise<any> | undefined>>;
}

const defaultState: IActionContext = {
  actions: [],
  setActions: () => [],
  actionsLoading: false,
  setActionsLoading: () => false,
  completedByCustomerActions: [],
  setCompletedByCustomerActions: () => [],
  currentAction: null,
  setCurrentAction: () => { },
  refetchCurrentAction: () => undefined,
  setRefetchCurrentAction: () => () => { },
  refetchActionList: () => undefined,
  setRefetchActionList: () => () => { },
};

export const ActionContext = createContext(defaultState);

export const ActionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [actions, setActions] = useState<IActionContext['actions']>(defaultState.actions);
  const [actionsLoading, setActionsLoading] = useState<IActionContext['actionsLoading']>(defaultState.actionsLoading);
  const [newActions, setNewActions] = useState<IActionContext['actions']>(defaultState.actions);
  const [currentAction, setCurrentAction] = useState<IActionContext['currentAction']>(defaultState.currentAction);
  const [refetchCurrentAction, setRefetchCurrentAction] = useState<IActionContext['refetchCurrentAction']>(defaultState.refetchCurrentAction);
  const [refetchActionList, setRefetchActionList] = useState<IActionContext['refetchActionList']>(defaultState.refetchActionList);
  const [completedByCustomerActions, setCompletedByCustomerActions] = useState<IActionContext['completedByCustomerActions']>(defaultState.completedByCustomerActions);

  return (
    <ActionContext.Provider
      value={{
        actions,
        setActions,
        actionsLoading,
        setActionsLoading,
        currentAction,
        setCurrentAction,
        refetchCurrentAction,
        setRefetchCurrentAction,
        refetchActionList,
        setRefetchActionList,
        completedByCustomerActions,
        setCompletedByCustomerActions,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};
