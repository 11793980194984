import React, { useState, createContext, FC, ReactNode } from 'react';
import { GetCurrentUserQuery, GetUserQuery } from '../types/graphql';

interface IUserContext {
  currentUser: GetCurrentUserQuery | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<GetCurrentUserQuery | null>>;
  selectedUser: GetUserQuery | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<GetUserQuery | null>>;
  selectedUserId: string | null;
  setSelectedUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

const defaultState: IUserContext = {
  currentUser: null,
  setCurrentUser: () => {},
  selectedUser: null,
  setSelectedUser: () => {},
  selectedUserId: null,
  setSelectedUserId: () => {},
};

export const UserContext = createContext(defaultState);

export const UserProvider:FC<{ children: ReactNode }> = ({children}) => {
  const [selectedUserId, setSelectedUserId] = useState<IUserContext['selectedUserId']>(defaultState.selectedUserId);
  const [currentUser, setCurrentUser] = useState<IUserContext['currentUser']>(defaultState.currentUser);
  const [selectedUser, setSelectedUser] = useState<IUserContext['selectedUser']>(defaultState.selectedUser);


  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        selectedUser,
        setSelectedUser,
        selectedUserId,
        setSelectedUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
