import { FC, useContext } from 'react';

import { Header, SubHeader } from '../Headers/Headers';
import { LayoutContext } from '../../providers/LayoutProvider';
import { AccountContext } from '../../providers/Authentication';
import { EstateContext } from '../../providers/EstateProvider';
import Modal from '../Modal/Modal';
import LoadingOverlay from '../../views/LoadingOverlay';
import { useDecision } from '@optimizely/react-sdk';
import { AdminToolBar } from '../Admin';
import CustomerNav from '../PageNav/CustomerNav';
import { useNoDecedantInfoRedirect } from '../../hooks/useNoDecedantInfoRedirect';

// Styles
import './DesktopLayout.scss'

interface DesktopLayoutProps {
  adminCustomerTools?: boolean;
  adminDeceasedTools?: boolean;
  adminDocumentTools?: boolean;
  altTitleText?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  fixedHeader?: boolean;
  includeHeader?: boolean;
  includeSideNav?: boolean;
  includeSubHeader?: boolean;
  subHeaderText?: string;
}

const DesktopLayout: FC<DesktopLayoutProps> = ({
  adminDeceasedTools=false,
  adminDocumentTools=false,
  adminCustomerTools=false,
  altTitleText,
  children,
  className,
  fixedHeader=false,
  includeHeader=true,
  includeSideNav=true,
  includeSubHeader=true,
  subHeaderText,
}) => {
  useNoDecedantInfoRedirect();

  const {hasAdminAuthorization} = useContext(AccountContext);
  const {selectedEstateId, currentEstate} = useContext(EstateContext);
  const {isLoading} = useContext(LayoutContext);
  const [decedentInfoFlag] = useDecision('deceased_information_form', {autoUpdate: true});

  // Hide Content if there is no deceased information and not admin user
  if ( decedentInfoFlag && !currentEstate?.getUserEstate?.deceased && !hasAdminAuthorization) return <LoadingOverlay />;
  return (
    <div id="desktopPageLayout" className={`${className || ''}`}>
      { hasAdminAuthorization && <AdminToolBar /> }
      {includeHeader && <Header fixed={fixedHeader}/>}
      <div className={`pageContent`}>
        {includeSubHeader && (!hasAdminAuthorization || (hasAdminAuthorization && selectedEstateId) ) && (
          <SubHeader 
            text={subHeaderText} 
            altTitleText={altTitleText}
            adminDeceasedTools={adminDeceasedTools}
            adminDocumentTools={adminDocumentTools}
            adminCustomerTools={adminCustomerTools}
          />
        )}
        <Modal />
        {children}
      
      </div>
      {includeSideNav && <CustomerNav />}
    </div>
  )
}

export default DesktopLayout;