import { FC, useContext, useEffect } from 'react'
import './DeleteFolderListItem.scss'
import { Typography } from '@mui/material'
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton'
import {ReactComponent as ChevronDownIcon} from '../../../assets/icons/navigation/chevron-down.svg'
import {ReactComponent as TrashCanIcon} from '../../../assets/icons/fileTypes/trash-can.svg'
import { useGetUserEstateIdQuery, useTrackSectionOpenedMutation } from '../../../types/graphql'
import { useScreenWidth } from '../../../helpers'
import { DocumentContext } from '../../../providers/DocumentProvider'
import { EstateContext } from '../../../providers/EstateProvider'
import { AccountContext } from '../../../providers/Authentication'
import DeletedFilesList from '../../../components/File/DeletedFilesList/DeletedFilesList'


interface FolderListItemProps {
  testId?: string,
  id: string,
  name: string,
  handleClick: (id: string)=> void,
  expanded: boolean,
}

const DeleteFolderListItem: FC<FolderListItemProps> = ({
  testId, id, name, handleClick, expanded,
}) => {
  const {setDirectoryPath} = useContext(DocumentContext)
  const screenWidth = useScreenWidth()
  const [trackSectionOpenedMutation] = useTrackSectionOpenedMutation();
  const { showAdminTools } = useContext(AccountContext);
  const { selectedEstateId } = useContext(EstateContext);
  const { data:estateIdData } = useGetUserEstateIdQuery({ variables: { estateId: selectedEstateId } });

  const sectionLabel = 'Folder click to view files';

  useEffect(() => {
    if (expanded) {
      setDirectoryPath(name);
    }
  }, [expanded, name]);

  const clickHandler = async () => {
    handleClick(id);
    const estateId = estateIdData?.getUserEstate?.id;

    try {
      if (estateId) {
        await trackSectionOpenedMutation({
          variables: {
            estateId,
            section: sectionLabel,
          },
        });
  
        if (process.env.NODE_ENV === 'development') console.log(`Tracking "${sectionLabel}" Section ${expanded ? 'Open' : 'Close'} on ${window.location.pathname}`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <li 
      data-testid={testId} 
      className={`folderListItemContainer`}
    >
      <ActionButton 
        ariaLabel={sectionLabel}
        handleClick={clickHandler}
        variant='solid'
        className={'folderHeader'}
      >
        <div className='folderHeaderContainer'>
          <TrashCanIcon className='folderIcon'/>
          <Typography variant='body1'>      
            {name}
          </Typography>
        </div>
        <div className="endCap">
          <ChevronDownIcon className={`folderChevronDownIcon ${expanded && 'expanded'}`}/>
        </div>
      </ActionButton>
      <DeletedFilesList tabIndex={expanded ? 0 : -1} directory={name} type={showAdminTools ? 'upload' : 'download'} expanded={expanded} className={"folderFilesDropdown"}/>
    </li>
  )
}

export default DeleteFolderListItem