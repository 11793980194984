import { FC } from 'react'
import './CircleIcon.scss'
import { ReactComponent as TalkBubbleSvgIcon } from '../../assets/icons/navigation/talk-bubble-icon.svg'


interface CircleIconProps {
  testId?: string
  backgroundClass?: string
  containerClass?: string
  iconComponent?: JSX.Element
  backgroundColorHexCode?: string
  dy?: number;
  anchor?: string;
  backgroundColor?: string;
} 

const CircleIcon: FC<CircleIconProps> = ({
  testId,
  backgroundClass='',
  containerClass='',
  iconComponent=<TalkBubbleSvgIcon className='svgIconDefault'/>,
  backgroundColor,
}) => {
  return (
    <div 
      data-testid={testId} 
      className={`circleIconContainer ${containerClass}`}
    >
      <div 
        className={`circleBackground ${backgroundClass}`}
        style={{backgroundColor: backgroundColor}}
      >
        {iconComponent}
      </div>
    </div>
  )
}

export default CircleIcon