import { FC } from 'react'
import './deceasedInformation.scss'
import { Typography } from '@mui/material'
import AuthViewLayout from '../AuthViewLayout'
import CustomerAddDeceasedToEstateForm from '../../../components/FormikForms/customer/CustomerAddDeceasedToEstateForm/CustomerAddDeceasedToEstateForm'

interface DeceasedInformationProps {
  testId?: string
}

const DeceasedInformation: FC<DeceasedInformationProps> = ({testId}) => {

  return (
    <AuthViewLayout>
      <div data-testid={testId} className='deceasedInformationContainer'>
        <div className="welcomeHeaderContainer">
          <Typography variant="h1" className="welcomeTitle light">
            Deceased Information
          </Typography>

          <Typography variant="body1" className="welcomeMessage light">
            {"Please provide the deceased's information to finish creating this estate."}
          </Typography>
        </div>
        <CustomerAddDeceasedToEstateForm />
      </div>
    </AuthViewLayout>
  )
}

export default DeceasedInformation