import { useContext, useEffect, useRef } from 'react';
import { EstateContext } from '../providers/EstateProvider';
import { useTrackScrollMutation, useTrackSectionOpenedMutation } from '../types/graphql';

const useScrollToObserver = (name: string, status: string, estateId: string, elementId:string, parentRef: any) => {
  const {currentEstate, selectedEstateId} = useContext(EstateContext)
  const elementRef = useRef(null);
  const currentParentRef = parentRef ? parentRef.current : null;


  const [trackScrollMutation] = useTrackScrollMutation({
    variables: {
      name: name,
      status: status,
      estateId: estateId,
      elementId: elementId,
    },
    // onCompleted: (data) => {
    //   console.log(`track scroll ${name} ${status} ${estateId} ${elementId}`, data)
    // },
  });


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            trackScrollMutation();
          }
        });
      },
      {
        root: currentParentRef, // observing intersections with the viewport
        threshold: 0.8, // element is considered "in view" when 80% of it is visible
      },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [name]);

  return elementRef;
};

export default useScrollToObserver;