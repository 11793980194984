import { FC, useContext } from 'react';
import styles from './NotificationManagementCard.module.scss'
import Typography from '../../atoms/Typography/Typography'
import Card from '../../atoms/Card/Card'
import { convertTimestampToHumanReadable2 } from '../../../../helpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import DeleteEstateNotificationForm from '../../../FormikForms/admin/DeleteEstateNotificationForm/DeleteEstateNotificationForm';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import LinkButton from '../../atoms/LinkButton/LinkButton';
import { useRoleNavigate } from '../../../../hooks/useRoleNavigate'
import { buildRouteFromBEUrl } from '../../../../helpers/functions'
import { RoutingError } from '../../../../types/graphql'


interface NotificationManagementCardProps {
  testId?: string
  title: string,
  message: string,
  route: string | null | undefined,
  routingError: RoutingError | null | undefined,
  estateId: string | null | undefined,
  estateNotificationId: string,
  users: string[] | undefined,
  createdAt: string,
  refetchNotifications: () => void
}

const NotificationManagementCard: FC<NotificationManagementCardProps> = ({
  testId,
  title,
  message,
  route,
  routingError,
  estateId,
  estateNotificationId,
  users,
  createdAt,
  refetchNotifications,
}) => {
  
  const {setDisplayModal, setModalContents} = useContext(LayoutContext)
  const navigate = useRoleNavigate();
  
  const handleAdminDeleteClick = () => {
    if(estateId) {
      setModalContents(< DeleteEstateNotificationForm refetchNotifications={refetchNotifications} estateId={estateId} estateNotificationId={estateNotificationId} />)
      setDisplayModal(true)
    }
    else {
      alert('Estate ID is undefined for this notification')
    }
  }

  const handleViewClick = () => {
    if (route) navigate(buildRouteFromBEUrl(route));
  }

  return (
    <Card data-testid={testId} className={styles.notificationManagementCardContainer}>
      <div className={styles.cardContent}>
        <Typography className={styles.title} font="UI1" variant='P2' fontWeight='medium'>
          {title}
        </Typography>
        <Typography className={styles.body} font="UI1" variant='P3' fontWeight='regular'>
          {message}
        </Typography>
      </div>
      <div className={styles.userTagContainer}>
        {users?.map((user, index) => (
          <div key={index} className={styles.userTag}>
            <Typography className={styles.user} font="UI1" variant='P3' fontWeight='regular'>
              {user}
            </Typography>
          </div>
        ))}
      </div>
      <Typography className={styles.createdAt} font="UI1" variant='P3' fontWeight='regular'>
        {convertTimestampToHumanReadable2(createdAt)}
      </Typography>

      {routingError && (
        <div className={styles.errorMessageContainer}>
          <Typography className={styles.errorMessage} font="UI1" variant='P3' fontWeight='regular'>
            {`ERROR: ${routingError.message}`}
          </Typography>
        </div>
      )}
      
      <div className={styles.actionButtonContainer}>
        <LinkButton
          ariaLabel={'view linked in-app content'} className={route ? styles.visable : styles.hidden}
          variant='outline' 
          handleClick={()=> handleViewClick()}
        >
          View Linked Content
        </LinkButton>

        <ActionButton 
          ariaLabel={'admin delete action card'} className={styles.deleteButton}
          variant='outline'
          handleClick={handleAdminDeleteClick}
        >
          Delete
        </ActionButton>
      </div>
    </Card>
  )
}

export default NotificationManagementCard
