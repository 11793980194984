import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCreateOneComponentMutation, ComponentCreateInput, ComponentType, ComponentStatus  } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DatePicker } from '@mui/x-date-pickers';

type CreateOneComponentFormProps = {
  testId?: string
}

const SUBTYPE_OPTIONS: { [key: string]: { submitValue: string; displayValue: string;}[] } = {
  "Asset": [
    {
      "submitValue": "BankAccount",
      "displayValue": "Bank Account",
    },
    {
      "submitValue": "Investment",
      "displayValue": "Investment",
    },
    {
      "submitValue": "LifeInsurance",
      "displayValue": "Life Insurance",
    },
    {
      "submitValue": "PersonalProperty",
      "displayValue": "Personal Property",
    },
    {
      "submitValue": "RealEstate",
      "displayValue": "Real Estate",
    },
    {
      "submitValue": "Retirement",
      "displayValue": "Retirement",
    },
    {
      "submitValue": "UnclaimedProperty",
      "displayValue": "Unclaimed Property",
    },
    {
      "submitValue": "Vehicle",
      "displayValue": "Vehicle",
    },
  ],
  "Debt": [
    {
      "submitValue": "Debt",
      "displayValue": "Debt or Loan",
    },
  ],
  "Obligation": [
    {
      "submitValue": "BillToCancel",
      "displayValue": "Bill To Cancel",
    },
  ],
  "Process": [
    {
      "submitValue": "Discovery",
      "displayValue": "Discovery",
    },
    {
      "submitValue": "Distribution",
      "displayValue": "Distribution",
    },

    {
      "submitValue": "Tax",
      "displayValue": "Tax",
    },

    {
      "submitValue": "Probate",
      "displayValue": "Probate",
    },
  ],
}

const CreateOneComponentForm: FC<CreateOneComponentFormProps> = ({testId}) => {
  const {setDisplayModal, setModalContents} = useContext(LayoutContext)
  const {
    selectedEstateId,
    currentEstate,
    refetchCurrentEstate,
  } = useContext(EstateContext);
  const [createOneComponentMutation] = useCreateOneComponentMutation();
  const {refetchEstatePieChart} = useContext(EstateContext);

  const [options, setOptions] = useState<any>({
    "estate": [
      {
        "id": selectedEstateId,
        "displayValue": `Estate of ${currentEstate?.getUserEstate?.deceased?.firstName} ${currentEstate?.getUserEstate?.deceased?.lastName}`,
      },
    ],
    // "createdByUser": [],
    "type": [
      {
        "submitValue": "Asset",
        "displayValue": "Asset",
      },
      {
        "submitValue": "Debt",
        "displayValue": "Debt",
      },
      {
        "submitValue": "Obligation",
        "displayValue": "Obligation",
      },
      {
        "submitValue": "Process",
        "displayValue": "Process",
      },
    ],
    "subType": SUBTYPE_OPTIONS["Asset"],
    "status": [
      {
        "submitValue": "Blocked",
        "displayValue": "Blocked",
      },
      {
        "submitValue": "Completed",
        "displayValue": "Completed",
      },
      {
        "submitValue": "InProgress",
        "displayValue": "InProgress",
      },
      {
        "submitValue": "Pending",
        "displayValue": "Pending",
      },
    ],
  })

  const handleSubmit = async (
    values: ComponentCreateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as ComponentCreateInput

    const isDebt = nestedValues.type === ComponentType.Debt
    if (isDebt && nestedValues.estimatedValue) nestedValues.estimatedValue = (-1 * Math.abs(nestedValues.estimatedValue)).toString()
    if (isDebt && nestedValues.finalValue) nestedValues.estimatedValue = (-1 * Math.abs(nestedValues.estimatedValue)).toString()

    createOneComponentMutation({
      variables: {data: nestedValues},
      onCompleted: (data) => {
        setDisplayModal(false);
        refetchCurrentEstate();
        refetchEstatePieChart();
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed createOneComponentMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    estate: options.estate[0].id,
    // createdByUser: "",
    name: "",
    descriptor: "",
    estimatedValue: null,
    type: ComponentType.Asset,
    subType: SUBTYPE_OPTIONS[ComponentType.Asset][0].submitValue as ComponentCreateInput["subType"],
    inTrust: false,
    status: ComponentStatus.Pending,
    contactName: "",
    email: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    accountHolder: "",
    accountNumber: "",
    appraisedValue: "",
    finalValue: "",
    balance: "",
    billingPeriod: "",
    charge: "",
    contactType: "",
    lastCheckDate: null,
    lastPaymentAmount: "",
    lastPaymentDate: null,
    registrationExpiresAt: null,
    loanAmount: "",
    mortgageBank: "",
    paidOff: false,
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string(),
    subType: Yup.string(),
    type: Yup.string(),
    name: Yup.string().required('name is required'),
    descriptor: Yup.string().required('descriptor is required'),
    estimatedValue: Yup.number().nullable(),
    finalValue: Yup.string().nullable(),
    loanAmount: Yup.string().nullable(),
    mortgageBank: Yup.string(),
    lastPaymentAmount: Yup.string().nullable(),
    lastPaymentDate: Yup.date().nullable(),
    paidOff: Yup.boolean(),
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    country: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    accountHolder: Yup.string(),
    accountNumber: Yup.string(),
    balance: Yup.string().nullable(),
    appraisedValue: Yup.string().nullable(),
    charge: Yup.string().nullable(),
    inTrust: Yup.boolean(),
    lastCheckDate: Yup.date().nullable(),
    // beneficiaries: Yup.string(),
    billingPeriod: Yup.string(),
    registrationExpiresAt: Yup.date().nullable(),
    contactName: Yup.string(),
    contactType: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(), 
  });

  return (
    <div data-testid={testId} className='createOneComponentFormContainer'>
      <Typography variant='h4' className='createOneComponentFormHeader'>
        Create One Component Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Typography variant="h5">Relationships</Typography>
              <Container className="formSection">
  
                <div className={"inputWrapper estateFieldWrapper dropdownInputWrapper"} key={"estate0"} data-testid={testId}>
                  <Autocomplete
                    disabled={true}
                    options={options.estate || []}
                    getOptionLabel={(option: { id: number, displayValue: string }) => option.displayValue}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"estateInput fieldInput dropdownInput"}
                        required={true}
                        id={"estate"}
                        label={"Estate"}
                        name={"estate"}
                        type={"text"}
                        autoComplete={"estate"}
                        as={TextField}
                        error={submitCount > 0 && touched.estate && !!errors.estate}
                      />
                    }
                    defaultValue={options.estate[0]}
                    onChange={(_, value) => {
                      setFieldValue("estate", value?.id)
                    }}
                  />
                  <ErrorMessage name={"estate"} component="div" className="inputErrorMessage" />
                </div>
              </Container>
  
              <hr />
  
              <Typography variant="h5">Required Information</Typography>
              <Container className="formSection">
  
                <div className={"inputWrapper statusFieldWrapper dropdownInputWrapper"} key={"status8"} data-testid={testId}>
                  <Autocomplete
                    options={options.status || []}
                    getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"statusInput fieldInput dropdownInput"}
                        required={false}
                        id={"status"}
                        label={"Status"}
                        name={"status"}
                        type={"text"}
                        autoComplete={"status"}
                        as={TextField}
                        error={submitCount > 0 && touched.status && !!errors.status}
                      />
                    }
                    defaultValue={options.status.find((option: any) => option.submitValue === initialValues.status)}
                    onChange={(_, value) => setFieldValue("status", value?.submitValue)}
                  />
                  <ErrorMessage name={"status"} component="div" className="inputErrorMessage" />
                </div>
                  
                <div className={"inputWrapper nameFieldWrapper textInputWrapper"} key={"name2"} data-testid={testId}>
                  <Field
                    className={"nameInput fieldInput textInput"}
                    required={true}
                    id={"name"}
                    label={"Name"}
                    name={"name"}
                    type={"text"}
                    autoComplete={"name"}
                    as={TextField}
                    error={submitCount > 0 && touched.name && !!errors.name}
                  />
                  <ErrorMessage name={"name"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper descriptorFieldWrapper textInputWrapper"} key={"descriptor3"} data-testid={testId}>
                  <Field
                    className={"descriptorInput fieldInput textInput"}
                    required={true}
                    id={"descriptor"}
                    label={"Descriptor"}
                    name={"descriptor"}
                    type={"text"}
                    autoComplete={"descriptor"}
                    as={TextField}
                    error={submitCount > 0 && touched.descriptor && !!errors.descriptor}
                  />
                  <ErrorMessage name={"descriptor"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper typeFieldWrapper dropdownInputWrapper"} key={"type5"} data-testid={testId}>
                  <Autocomplete
                    options={options.type || []}
                    getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"typeInput fieldInput dropdownInput"}
                        required={true}
                        id={"type"}
                        label={"Type"}
                        name={"type"}
                        type={"text"}
                        autoComplete={"type"}
                        as={TextField}
                        error={submitCount > 0 && touched.type && !!errors.type}
                      />
                    }
                    defaultValue={options.type.find((option: any) => option.submitValue === initialValues.type)}
                    onChange={(_, value) => {
                      // if the value doesn't match what the previous type was then reset the subtype
                      if (values.type !== value?.submitValue && value?.submitValue) {
                        // setFieldValue("subType", SUBTYPE_OPTIONS[value.submitValue][0].submitValue);
                        setFieldValue("subType", null);
                      }
  
                      // Set the new type value
                      setFieldValue("type", value?.submitValue)
  
                      // Set the new options for subtype based on the new type value
                      setOptions((prevState: { [key: string]: any }) => {
                        if (value?.submitValue) return {
                          ...prevState,
                          subType: SUBTYPE_OPTIONS[value?.submitValue], 
                        }
                      })
                    }}
                  />
                  <ErrorMessage name={"type"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper subTypeFieldWrapper dropdownInputWrapper"} key={"subType6"} data-testid={testId}>
                  <Autocomplete
                    options={options.subType || []}
                    getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                    value={options.subType.find((option: any) => option.submitValue === values.subType) || null}
                    renderInput={(params: any) =>
                      <Field
                        {...params}
                        className={"subTypeInput fieldInput dropdownInput"}
                        required={true}
                        id={"subType"}
                        label={"Sub Type"}
                        name={"subType"}
                        type={"text"}
                        autoComplete={"subType"}
                        as={TextField}
                        error={submitCount > 0 && touched.subType && !!errors.subType}
                      />
                    }
                    defaultValue={options.subType.find((option: any) => option.submitValue === initialValues.subType)}
                    onChange={(_, value) => setFieldValue("subType", value?.submitValue)}
                  />
                  <ErrorMessage name={"subType"} component="div" className="inputErrorMessage" />
                </div>
              </Container>
  
              <hr />
  
              <Typography variant="h5">Other Information</Typography>
              <Container className="formSection">
  
                <div className={"inputWrapper estimatedValueFieldWrapper textInputWrapper"} key={"estimatedValue5"} data-testid={testId}>
                  <Field
                    className={"estimatedValueInput fieldInput textInput"}
                    required={false}
                    id={"estimatedValue"}
                    label={"Estimated Value"}
                    name={"estimatedValue"}
                    type={"text"}
                    autoComplete={"estimatedValue"}
                    as={TextField}
                    error={submitCount > 0 && touched.estimatedValue && !!errors.estimatedValue}
                    // Convert null to empty string for TextField
                    value={values.estimatedValue || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('estimatedValue', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"estimatedValue"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper finalValueFieldWrapper textInputWrapper"} key={"finalValue6"} data-testid={testId}>
                  <Field
                    className={"finalValueInput fieldInput textInput"}
                    required={false}
                    id={"finalValue"}
                    label={"Final Value"}
                    name={"finalValue"}
                    type={"text"}
                    autoComplete={"finalValue"}
                    as={TextField}
                    error={submitCount > 0 && touched.finalValue&& !!errors.finalValue}
                    // Convert null to empty string for TextField
                    value={values.finalValue || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('finalValue', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"finalValue"} component="div" className="inputErrorMessage"/>
                </div>
  
                <div className={"inputWrapper inTrustFieldWrapper booleanInputWrapper"} key={"inTrust7"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">In Trust</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field
                    className={"inTrustInput fieldInput booleanInput"}
                    required={false}
                    id={"inTrust"}
                    name={"inTrust"}
                    type={"checkbox"}
                    as={Switch}
                    error={submitCount > 0 && touched.inTrust && !!errors.inTrust}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"inTrust"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper contactNameFieldWrapper textInputWrapper"} key={"contactName9"} data-testid={testId}>
                  <Field
                    className={"contactNameInput fieldInput textInput"}
                    required={false}
                    id={"contactName"}
                    label={"Contact Name"}
                    name={"contactName"}
                    type={"text"}
                    autoComplete={"contactName"}
                    as={TextField}
                    error={submitCount > 0 && touched.contactName && !!errors.contactName}
                  />
                  <ErrorMessage name={"contactName"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper emailFieldWrapper textInputWrapper"} key={"email10"} data-testid={testId}>
                  <Field
                    className={"emailInput fieldInput textInput"}
                    required={false}
                    id={"email"}
                    label={"Email"}
                    name={"email"}
                    type={"text"}
                    autoComplete={"email"}
                    as={TextField}
                    error={submitCount > 0 && touched.email && !!errors.email}
                  />
                  <ErrorMessage name={"email"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper addressFieldWrapper textInputWrapper"} key={"address12"} data-testid={testId}>
                  <Field
                    className={"addressInput fieldInput textInput"}
                    required={false}
                    id={"address"}
                    label={"Address"}
                    name={"address"}
                    type={"text"}
                    autoComplete={"address"}
                    as={TextField}
                    error={submitCount > 0 && touched.address&& !!errors.address}
                    // Convert null to empty string for TextField
                    value={values.address || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('address', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"address"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper cityFieldWrapper textInputWrapper"} key={"city13"} data-testid={testId}>
                  <Field
                    className={"cityInput fieldInput textInput"}
                    required={false}
                    id={"city"}
                    label={"City"}
                    name={"city"}
                    type={"text"}
                    autoComplete={"city"}
                    as={TextField}
                    error={submitCount > 0 && touched.city&& !!errors.city}
                    // Convert null to empty string for TextField
                    value={values.city || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('city', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"city"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper stateFieldWrapper textInputWrapper"} key={"state14"} data-testid={testId}>
                  <Field
                    className={"stateInput fieldInput textInput"}
                    required={false}
                    id={"state"}
                    label={"State"}
                    name={"state"}
                    type={"text"}
                    autoComplete={"state"}
                    as={TextField}
                    error={submitCount > 0 && touched.state&& !!errors.state}
                    // Convert null to empty string for TextField
                    value={values.state || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('state', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"state"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper countryFieldWrapper textInputWrapper"} key={"country15"} data-testid={testId}>
                  <Field
                    className={"countryInput fieldInput textInput"}
                    required={false}
                    id={"country"}
                    label={"Country"}
                    name={"country"}
                    type={"text"}
                    autoComplete={"country"}
                    as={TextField}
                    error={submitCount > 0 && touched.country&& !!errors.country}
                    // Convert null to empty string for TextField
                    value={values.country || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('country', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"country"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper zipFieldWrapper textInputWrapper"} key={"zip16"} data-testid={testId}>
                  <Field
                    className={"zipInput fieldInput textInput"}
                    required={false}
                    id={"zip"}
                    label={"Zip"}
                    name={"zip"}
                    type={"text"}
                    autoComplete={"zip"}
                    as={TextField}
                    error={submitCount > 0 && touched.zip&& !!errors.zip}
                    // Convert null to empty string for TextField
                    value={values.zip || ''}
                    onChange={(event: any )=> {
                      // Convert empty string back to null for form values
                      setFieldValue('zip', event.target.value ? event.target.value : null);
                    }}
                  />
                  <ErrorMessage name={"zip"} component="div" className="inputErrorMessage"/>
                </div>
  
                <div className={"inputWrapper accountHolderFieldWrapper textInputWrapper"} key={"accountHolder16"} data-testid={testId}>
                  <Field
                    className={"accountHolderInput fieldInput textInput"}
                    required={false}
                    id={"accountHolder"}
                    label={"Account Holder"}
                    name={"accountHolder"}
                    type={"text"}
                    autoComplete={"accountHolder"}
                    as={TextField}
                    error={submitCount > 0 && touched.accountHolder && !!errors.accountHolder}
                  />
                  <ErrorMessage name={"accountHolder"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper accountNumberFieldWrapper textInputWrapper"} key={"accountNumber17"} data-testid={testId}>
                  <Field
                    className={"accountNumberInput fieldInput textInput"}
                    required={false}
                    id={"accountNumber"}
                    label={"Account Number"}
                    name={"accountNumber"}
                    type={"text"}
                    autoComplete={"accountNumber"}
                    as={TextField}
                    error={submitCount > 0 && touched.accountNumber && !!errors.accountNumber}
                  />
                  <ErrorMessage name={"accountNumber"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper appraisedValueFieldWrapper textInputWrapper"} key={"appraisedValue18"} data-testid={testId}>
                  <Field
                    className={"appraisedValueInput fieldInput textInput"}
                    required={false}
                    id={"appraisedValue"}
                    label={"Appraised Value"}
                    name={"appraisedValue"}
                    type={"text"}
                    autoComplete={"appraisedValue"}
                    as={TextField}
                    error={submitCount > 0 && touched.appraisedValue && !!errors.appraisedValue}
                  />
                  <ErrorMessage name={"appraisedValue"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper balanceFieldWrapper textInputWrapper"} key={"balance20"} data-testid={testId}>
                  <Field
                    className={"balanceInput fieldInput textInput"}
                    required={false}
                    id={"balance"}
                    label={"Balance"}
                    name={"balance"}
                    type={"text"}
                    autoComplete={"balance"}
                    as={TextField}
                    error={submitCount > 0 && touched.balance && !!errors.balance}
                  />
                  <ErrorMessage name={"balance"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper billingPeriodFieldWrapper textInputWrapper"} key={"billingPeriod21"} data-testid={testId}>
                  <Field
                    className={"billingPeriodInput fieldInput textInput"}
                    required={false}
                    id={"billingPeriod"}
                    label={"Billing Period"}
                    name={"billingPeriod"}
                    type={"text"}
                    autoComplete={"billingPeriod"}
                    as={TextField}
                    error={submitCount > 0 && touched.billingPeriod && !!errors.billingPeriod}
                  />
                  <ErrorMessage name={"billingPeriod"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper chargeFieldWrapper textInputWrapper"} key={"charge22"} data-testid={testId}>
                  <Field
                    className={"chargeInput fieldInput textInput"}
                    required={false}
                    id={"charge"}
                    label={"Charge"}
                    name={"charge"}
                    type={"text"}
                    autoComplete={"charge"}
                    as={TextField}
                    error={submitCount > 0 && touched.charge && !!errors.charge}
                  />
                  <ErrorMessage name={"charge"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper contactTypeFieldWrapper textInputWrapper"} key={"contactType23"} data-testid={testId}>
                  <Field
                    className={"contactTypeInput fieldInput textInput"}
                    required={false}
                    id={"contactType"}
                    label={"Contact Type"}
                    name={"contactType"}
                    type={"text"}
                    autoComplete={"contactType"}
                    as={TextField}
                    error={submitCount > 0 && touched.contactType && !!errors.contactType}
                  />
                  <ErrorMessage name={"contactType"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper lastCheckDateFieldWrapper textInputWrapper"} key={"lastCheckDate24"} data-testid={testId}>
                  <Field
                    className={"lastCheckDateInput fieldInput textInput"}
                    required={false}
                    id={"lastCheckDate"}
                    label={"Last Check Date"}
                    name={"lastCheckDate"}
                    type={"date"}
                    autoComplete={"lastCheckDate"}
                    as={DatePicker}
                    value={values.lastCheckDate}
                    error={submitCount > 0 && touched.lastCheckDate && !!errors.lastCheckDate}
                    onChange={(date: any) => setFieldValue("lastCheckDate", date)}
                  />
                  <ErrorMessage name={"lastCheckDate"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper lastPaymentAmountFieldWrapper textInputWrapper"} key={"lastPaymentAmount25"} data-testid={testId}>
                  <Field
                    className={"lastPaymentAmountInput fieldInput textInput"}
                    required={false}
                    id={"lastPaymentAmount"}
                    label={"Last Payment Amount"}
                    name={"lastPaymentAmount"}
                    type={"text"}
                    autoComplete={"lastPaymentAmount"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastPaymentAmount && !!errors.lastPaymentAmount}
                  />
                  <ErrorMessage name={"lastPaymentAmount"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper lastPaymentDateFieldWrapper textInputWrapper"} key={"lastPaymentDate26"} data-testid={testId}>
                  <Field
                    className={"lastPaymentDateInput fieldInput textInput"}
                    required={false}
                    id={"lastPaymentDate"}
                    label={"Last Payment Date"}
                    name={"lastPaymentDate"}
                    type={"date"}
                    autoComplete={"lastPaymentDate"}
                    as={DatePicker}
                    values={values.lastPaymentDate}
                    error={submitCount > 0 && touched.lastPaymentDate && !!errors.lastPaymentDate}
                    onChange={(date: any) => setFieldValue("lastPaymentDate", date)}
                  />
                  <ErrorMessage name={"lastPaymentDate"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper loanAmountFieldWrapper textInputWrapper"} key={"loanAmount27"} data-testid={testId}>
                  <Field
                    className={"loanAmountInput fieldInput textInput"}
                    required={false}
                    id={"loanAmount"}
                    label={"Loan Amount"}
                    name={"loanAmount"}
                    type={"text"}
                    autoComplete={"loanAmount"}
                    as={TextField}
                    error={submitCount > 0 && touched.loanAmount && !!errors.loanAmount}
                  />
                  <ErrorMessage name={"loanAmount"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper mortgageBankFieldWrapper textInputWrapper"} key={"mortgageBank28"} data-testid={testId}>
                  <Field
                    className={"mortgageBankInput fieldInput textInput"}
                    required={false}
                    id={"mortgageBank"}
                    label={"Mortgage Bank"}
                    name={"mortgageBank"}
                    type={"text"}
                    autoComplete={"mortgageBank"}
                    as={TextField}
                    error={submitCount > 0 && touched.mortgageBank && !!errors.mortgageBank}
                  />
                  <ErrorMessage name={"mortgageBank"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper paidOffFieldWrapper booleanInputWrapper"} key={"paidOff29"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">Paid Off</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field
                    className={"paidOffInput fieldInput booleanInput"}
                    required={false}
                    id={"paidOff"}
                    name={"paidOff"}
                    type={"checkbox"}
                    as={Switch}
                    error={submitCount > 0 && touched.paidOff && !!errors.paidOff}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"paidOff"} component="div" className="inputErrorMessage" />
                </div>
  
                <div className={"inputWrapper phoneFieldWrapper textInputWrapper"} key={"phone30"} data-testid={testId}>
                  <Field
                    className={"phoneInput fieldInput textInput"}
                    required={false}
                    id={"phone"}
                    label={"Phone"}
                    name={"phone"}
                    type={"text"}
                    autoComplete={"phone"}
                    as={TextField}
                    error={submitCount > 0 && touched.phone && !!errors.phone}
                  />
                  <ErrorMessage name={"phone"} component="div" className="inputErrorMessage" />
                </div>
              </Container>
  
            </Container>
  
            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                  Cancel
              </ActionButton>
  
              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                  Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOneComponentForm