import { FC, useContext } from 'react'
import './index.scss'
import { Typography } from '@mui/material'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../../providers/LayoutProvider'
import { useDeleteDocumentMutation, useMoveDocumentsMutation } from '../../../../types/graphql'
import { DocumentContext } from '../../../../providers/DocumentProvider'
import { file } from 'jszip'

interface DeleteOneFileItemFormProps {
  testId?: string,
  fileId: string
  fileName: string,
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>,
}

const DeleteOneFileItemForm: FC<DeleteOneFileItemFormProps> = ({
  testId,
  fileId,
  fileName,
  setDeleting,
}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const {
    refetchDocumentsList, folderRefetch, directoryPath,
  } = useContext(DocumentContext);


  // KEEP FOR POTENTIAL FUTURE USE
  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    variables: {id: fileId || ''},
    onCompleted: () => {
      // window.location.pathname === "/documents" ?
      folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]() 
      //TODO: try adding this back
      // :
      // refetchDocumentsList()
    },
    onError: (error) => {
      console.error(`Error deleting document: ${fileName}`, error)
      alert(`Error deleting ${fileName}: ${error}`)
      setDeleting(false)
    },
  });

  const handleDeleteClick = () => {
    //delete mutation here
    setDeleting(true)
    deleteDocumentMutation()
    setDisplayModal(false)
    setModalContents(<></>)
  }

  const handleCancelClick = () => {
    setDeleting(false)
    setDisplayModal(false)
    setModalContents(<></>)
  }

  return (
    <div data-testid={testId} className='deleteOneFileItemFormContainer'>
      <Typography variant='h2'>
      Are you sure you would like to delete this file? It will be held in the trash folder for 45 days.
      </Typography>
      <div className='actionButtonContainer'>
        <ActionButton ariaLabel={'delete the selected file'} variant='solid' handleClick={handleDeleteClick}>
          Delete
        </ActionButton>
        <ActionButton ariaLabel={'cancel delete'} handleClick={handleCancelClick}>
          Cancel
        </ActionButton>
      </div>
    </div>
  )
}

export default DeleteOneFileItemForm