import { FC } from 'react'
import './StatusIcon.scss'
import useStatus from '../../hooks/useStatus'
import { ActionCardStatus, ComponentStatus, TaskStatus } from '../../types/graphql'

interface StatusIconProps {
  testId?: string
  status: TaskStatus | ActionCardStatus | ComponentStatus | undefined | null,
  className?: string
}

const StatusIcon: FC<StatusIconProps> = ({
  testId,
  status,
  className,
}) => {
  const { statusIcon, statusClass } = useStatus(status)

  return (
    <div data-testid={testId} className={`statusIconContainer ${statusClass} ${className}`}>
      <div className='circleOutline'>
        {statusIcon}
      </div>
    </div>
  )
}

export default StatusIcon