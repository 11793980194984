import { FC, useContext, useEffect } from 'react'
import './Playground.scss'
import { useGetKeyDatesQuery, useGetRecentActivityQuery } from '../../types/graphql'
import { EstateContext } from '../../providers/EstateProvider'
interface PlaygroundProps {
  testId?: string
}

const Playground: FC<PlaygroundProps> = ({testId}) => {
  const { selectedEstateId } = useContext(EstateContext);
  const { data: recentActivityData, loading: loadingRecentActivity } = useGetRecentActivityQuery({ variables: { estateId: selectedEstateId } });
  const { data: keyDatesData, loading: loadingKeyDates } = useGetKeyDatesQuery({ variables: { estateId: selectedEstateId } });

  useEffect(() => {
    if (loadingRecentActivity || loadingKeyDates) return
    console.log({
      recentActivityData,
      keyDatesData,
    })
  }, [recentActivityData, keyDatesData])

  return (
    <div className='playground' data-testid={testId}>
      Congratulations! You have successfully found the hidden Playground page.
    </div>
  )
}

export default Playground
