import { FC } from 'react'
import styles from './Card.module.scss'

interface CardProps {
  children?: React.ReactNode
  className?: string
  clickable?: boolean
  clickableAction?: () => void
  style?: React.CSSProperties
}

const Card: FC<CardProps> = ({
  children, className, clickable, clickableAction, style,
}) => {
  const containerClassNames = [styles.cardContainer, className, "clickable"].filter(str => !!str).join(' ')

  if(clickable) return (
    <button 
      aria-label='Clickable Card'
      onClick={clickableAction} 
      className={containerClassNames}
      style={style}
    >
      {children}
    </button>
  )
  
  return (
    <div className={`${styles.cardContainer} ${className}`} style={style}>
      {children}
    </div>
  )
}

export default Card
